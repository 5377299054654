import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// eslint-disable-next-line
import logo from "../../assets/Logos/logo_header.png";

// eslint-disable-next-line
import "./Main.css";
import Loader from "react-js-loader";
import { colors } from "../utils/index";
import CustomButton from '../utils/CustomButton';
const Login = () => {
  // eslint-disable-next-line
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  // eslint-disable-next-line
  const [loginType, setLoginType] = useState("own");

  const [password, setPassword] = useState("");
  const [showForgotPassModal, setShowForgotPassModal] = useState(false);
  const [popupOpenError, setPopupOpenError] = useState(false);
  const [mensaje, setMensaje] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const widthScreen = window.innerWidth < 790;
  const mincel = window.innerWidth < 900;
  const history = useHistory();

  const handlePopupCloseError = () => {
    //setPayEliminarNo(false)
    setPopupOpenError(false);
    setIsLoading(false);
  };

  const handleCloseModal = () => {
    setShowForgotPassModal(false);
  };

  const handleSubmit = (event) => {
    // Aquí puedes implementar la lógica para enviar un correo con el enlace para restablecer la contraseña.
    // Esto puede implicar una llamada a una API o servicio de correo electrónico.
    setEmail(event.target.value);
    // Después de enviar el correo, puedes cerrar el modal.
    handleCloseModal();
  };
  // eslint-disable-next-line

  const handleLogin = async (
    loginType,
    email,
    password,
    first_name,
    last_name
  ) => {
    try {
      if (email === "" || password === "") {
        return;
      }
      setIsLoading(true);

      if (loginType !== "facebook" && loginType !== "google") {
        loginType = "own";
      }

      const requestBody = {
        first_name: first_name,
        last_name: last_name,
        user: email,
        password: password,
        loginType: loginType,
      };
      //console.log(requestBody)
      const response = await fetch(
        "https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/loginUsersClub",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      const data = await response.json();
      //console.log(data);
      if (data.error) {
        setMensaje("ERROR INICIO DE SESION");
        setPopupOpenError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);

        if (data.first_name !== "") {
			localStorage.setItem("dni", data.user);
			localStorage.setItem("email", data.email);
			localStorage.setItem("username", data.first_name);
			localStorage.setItem('loginType', data.loginType);
        } else {
		    localStorage.setItem("email", data.email);
			localStorage.setItem("username", user.displayName);
			localStorage.setItem('loginType', data.loginType);
		}
		//  console.log(localStorage.dni)
		  if (data.loginType === '1') {
				history.push({
				pathname: "/homeView",
		});
		  } else {
			  	setMensaje("SIN ACCESO");
    			setPopupOpenError(true);
			   	setIsLoading(false);
		  }

      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const buttonStyle = {
    marginBottom: 25,
    height: 50,
    fontSize: "1.3em",
    border: "none",
    width: 200,
    color: "white",
    backgroundColor: colors.DARK_BLUE,
    borderRadius: "20px 0px 50px 20px",
    cursor: "pointer",
  };

  const mobile = window.innerHeight <= 667;
 const back = colors.backGroundImage;
  return (
    <div
      style={{
        backgroundImage: `url(${back})`,
        backgroundSize: 'cover',
        height: mincel ? '100vh' : '100vh',
        width: '100%',
      }}>
      <div>
        <div
          style={{
            fontFamily: 'Roboto-Regular',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          {isLoading && (
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1000,
              }}>
              <Loader type="spinner-circle" bgColor={colors.WHITE} color={colors.BACKG_Color} size={100} />
            </div>
          )}
          {/*<div style={{fontFamily:'Roboto-Bold', display: 'flex', flexDirection: widthScreen ? 'column' : 'column', justifyContent: 'center', alignItems: 'center', marginTop: widthScreen ? heightScreen? '30%':'15%' : '20%' }}>
                <img src={titleLogin} alt="Logo" style={{ width: '80%' }} />
            </div>*/}
          <div style={{textAlign: 'center', marginTop: '20px'}}>
            <img src={logo} alt="Logo" style={{width: mincel ? '50%' : '100%'}} />
          </div>
          <div
            style={{
              zIndex: 1,
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              borderRadius: '15px',
              padding: '20px',
              marginTop: '65px',
              width: '80%',
              maxWidth: '500px',
            }}>
            <div
              style={{
                fontSize: mobile ? '0.8em' : '1em',
                color: '#fff',
                zIndex: 10,
                letterSpacing: 2,
                fontFamily: 'Como-Bold',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '-10px',
              }}>
              <h2
                style={{
                  fontSize: '1.5em',
                  color: '#fff',
                  fontFamily: 'Como-Heavy',
                }}>
                INICIO
              </h2>
              <div
                style={{
                  color: '#fff',
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'left',
                }}>
                <span
                  style={{
                    letterSpacing: 2,
                    fontSize: '1.5em',
                    fontFamily: 'Como-Bold',
                    marginTop: '-20px',
                  }}
                  className="titleDetail">
                  Usuario
                </span>
                <input
                  type="text"
                  placeholder="XXXXXXXXX"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  style={{
                    fontSize: mobile ? '1em' : '1.4em',
                    marginBottom: '3%',
                    height: '40px',
                    width: '250px',
                    borderRadius: 8,
                    border: 'none',
                  }}
                />
              </div>
              <div
                style={{
                  color: '#fff',
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'left',
                  marginTop: '20px',
                }}>
                <span
                  style={{
                    letterSpacing: 2,
                    fontSize: '1.5em',
                    fontFamily: 'Como-Bold',
                  }}
                  className="titleDetail">
                  Contraseña
                </span>
                <input
                  type="password"
                  placeholder="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  style={{
                    fontSize: widthScreen ? '1em' : '1.4em',
                    marginBottom: '3%',
                    height: '40px',
                    width: '250px',
                    borderRadius: 8,
                    border: 'none',
                  }}
                />
              </div>

              <CustomButton
                onPress={() => handleLogin(loginType, email, password, '', '')}
                title="INICIAR SESIÓN"
                color={colors.DORADO} // Color de fondo del botón
                textColor={colors.WHITE} // Color del texto
                fontSize={18} // Ajusta el tamaño de la fuente si es necesario
                buttonWidth={220} // Ancho del botón
                buttonHeight={40} // Altura del botón
                buttonStyle={{
                  fontFamily: 'Como-Bold',
                  borderRadius: 50,
                  marginTop: mobile ? '25px' : '2%',
                  marginBottom: '3px',
                }} // Otros estilos adicionales
              />

              <div
                style={{
                  textAlign: 'right',
                  marginTop: '1%',
                  marginBottom: '1%',
                }}></div>
            </div>
          </div>

          {/* Pie de página */}

          {/* Modal para restablecer la contraseña */}
          {showForgotPassModal && (
            <div
              className="modal"
              style={{
                fontFamily: 'Roboto-Black',
                backgroundColor: '#fff', // Fondo blanco para el modal
                position: 'absolute', // Posición absoluta para superponer el modal
                top: '25%', // Alineado en la parte superior
                width: widthScreen ? '90%' : '50%', // Ancho de pantalla completo
                height: '50%', // Altura de pantalla completa
                display: 'flex',
                borderRadius: 10,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 10,
                zIndex: 999,
              }}>
              <span
                className="close"
                style={{
                  cursor: 'pointer',
                  color: colors.ORANGE,
                  fontSize: '1em',
                  fontWeight: 'bold',
                }}
                onClick={handleCloseModal}>
                X
              </span>
              <h2 style={{color: colors.ORANGE}}>Resetee su contraseña</h2>
              <p>Por favor ingrese su dirección de correo electrónico y le enviaremos un link para resetear su contraseña.</p>
              <input
                type="email"
                placeholder="email@gmail.com"
                value={email}
                onChange={e => setEmail(e.target.value)}
                style={{
                  marginBottom: '10px',
                  padding: '8px',
                  width: 300,
                  borderRadius: 15,
                }}
              />
              <CustomButton
                onPress={handleSubmit}
                title="ENVIAR"
                color={colors.ORANGE} // Color de fondo del botón
                fontSize={18} // Ajusta el tamaño de la fuente si es necesario
                buttonWidth={200} // Ajusta el ancho del botón si es necesario
                buttonStyle={{fontFamily: 'Roboto-Bold'}} // Estilo adicional para la fuente
              />
            </div>
          )}

          {popupOpenError && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                zIndex: 9999,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <div
                style={{
                  width: '80%',
                  backgroundColor: 'red',
                  padding: '20px',
                  borderRadius: '50px 0px 50px 50px',
                  maxWidth: '300px',
                  height: 250,
                }}>
                <p
                  style={{
                    fontFamily: 'Como-Bold',
                    marginTop: '20%',
                    alignSelf: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    color: 'white',
                    fontSize: '1.5em',
                  }}>
                  {mensaje}
                </p>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '20%',
                  }}>
                  <div>
                    <button
                      style={{
                        ...buttonStyle,
                        backgroundColor: colors.BLACK,
                        color: colors.WHITE,
                        fontSize: '1.5em',
                      }}
                      onClick={handlePopupCloseError}>
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
