import React, {useState, useEffect, useMemo, useCallback} from 'react';
import {useTable, useFilters, useGlobalFilter, usePagination} from 'react-table';

import * as XLSX from 'xlsx';
import Loader from 'react-js-loader';
import Header from './Header';
import {colors} from '../utils/index';
import './Main.css';
import CustomButton from '../utils/CustomButton';

const ReportBeneficios = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [popupOpenError, setPopupOpenError] = useState(false);
  const [mensaje, setMensaje] = useState('');
  const [buttonColor, setButtonColor] = useState(colors.RED);
  const [title, setTitle] = useState('CONSULTA BENEFICIOS');
  const titleExcel = 'BENEFICIOS';
  // eslint-disable-next-line
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState([]);

  const defaultColumn = useMemo(
    () => ({
      Filter: ({column}) => {
        const {filterValue, setFilter} = column;
        return <input value={filterValue || ''} onChange={e => setFilter(e.target.value)} placeholder={`Filtrar por ${column.Header}`} />;
      },
      filter: (rows, columnIds, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[columnIds];
          return String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase());
        });
      },
    }),
    [],
  );

  useEffect(() => {
    const loadUserData = async () => {
      try {
        setIsLoading(true);
		  const body = { loginType: localStorage.loginType };
		//  console.log(body)
        const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/consultBenefits', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        });

        const data = await response.json();
        setIsLoading(false);
        if (data.error) {
          setMensaje('Error al cargar los datos del Beneficios');
          setPopupOpenError(true);
        } else {
          setUserData(data.data);
        }
      } catch (error) {
        console.error('Error al cargar los datos del Beneficios:', error);
        setIsLoading(false);
        setMensaje('Error al cargar los datos del Beneficios');
        setPopupOpenError(true);
      }
    };

    loadUserData();
  }, []);

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      var body = [];
      for (const record of selectedRecords) {
        var bodyItems = {};
        bodyItems['id'] = record.id;
        body.push(bodyItems);
      }
      // console.log(body);
      const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/deleteBenefits', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const result = await response.json();
      setIsLoading(false);
      if (result.error) {
        setMensaje('Error al eliminar los registros seleccionados');
        setPopupOpenError(true);
      } else {
        setMensaje('Eliminación exitosa');
        setButtonColor(colors.GREEN);
        setPopupOpenError(true);
        setUserData(prevData => prevData.filter(record => !selectedRecords.some(selected => selected.id === record.id)));
        setSelectedRecords([]);
      }
    } catch (error) {
      console.error('Error al eliminar los registros:', error);
      setIsLoading(false);
      setMensaje('Error al eliminar los registros');
      setPopupOpenError(true);
    }
  };

  const handleSelectRecord = useCallback(record => {
    setSelectedRecords(prevSelected => {
      if (prevSelected.some(selected => selected.id === record.id)) {
        return prevSelected.filter(selected => selected.id !== record.id);
      } else {
        return [...prevSelected, record];
      }
    });
  }, []);

  const handleSelectAll = useCallback(() => {
    const newValue = selectedRecords.length !== userData.length;
    setSelectedRecords(newValue ? userData : []);
  }, [selectedRecords, userData]);

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div style={{marginTop: '-20px'}}>
            {title !== 'CONSULTA BENEFICIOS' && <input type="checkbox" checked={selectedRecords.length === userData.length} onChange={handleSelectAll} />}
            {titleExcel}
          </div>
        ),
        disableFilters: true,
        Cell: ({row}) => title !== 'CONSULTA BENEFICIOS' && <input type="checkbox" checked={selectedRecords.some(selected => selected.id === row.original.id)} onChange={() => handleSelectRecord(row.original)} />,
        accessor: 'checkbox',
      },
      {Header: 'Compañía', accessor: 'company'},
      {Header: 'Categoría', accessor: 'category'},
      {Header: 'ID', accessor: 'id'},
      {Header: 'Beneficio 1', accessor: 'benefit1'},
      {Header: 'Condición 1', accessor: 'condition1'},
      {Header: 'Beneficio 2', accessor: 'benefit2'},
      {Header: 'Condición 2', accessor: 'condition2'},
      {Header: 'PIN', accessor: 'pin'},
      {Header: 'WhatsApp', accessor: 'wa'},
      {
        Header: 'Imagen 1',
        accessor: 'image1',
        Cell: ({value}) => <img src={value} alt="Imagen 1" style={{width: '100%'}} />,
      },
      {
        Header: 'Imagen 2',
        accessor: 'image2',
        Cell: ({value}) => <img src={value} alt="Imagen 2" style={{width: '100%'}} />,
      },
      {
        Header: 'Imagen 3',
        accessor: 'image3',
        Cell: ({value}) => <img src={value} alt="Imagen 3" style={{width: '100%'}} />,
      },
      {
        Header: 'Imagen 4',
        accessor: 'image4',
        Cell: ({value}) => <img src={value} alt="Imagen 4" style={{width: '100%'}} />,
      },
    ],
    [selectedRecords, userData, title, titleExcel, handleSelectAll, handleSelectRecord],
  );

  const tableData = useMemo(() => ({columns, data: userData, defaultColumn}), [userData, columns, defaultColumn]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {pageIndex, pageSize},
  } = useTable(tableData, useFilters, useGlobalFilter, usePagination);

  const handlePopupCloseError = () => {
    setPopupOpenError(false);
    setIsLoading(false);
  };

  // Función para exportar a Excel
  const handleExport = () => {
    if (userData && userData.length > 0) {
      const exportData = userData.map(row => ({
        Compañía: row.company,
        Categoría: row.category,
        ID: row.id,
        Beneficio1: row.benefit1,
        Condición1: row.condition1,
        Beneficio2: row.benefit2,
        Condición2: row.condition2,
        PIN: row.pin,
        WhatsApp: row.wa,
      }));

      const worksheet = XLSX.utils.json_to_sheet(exportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Beneficios');
      XLSX.writeFile(workbook, 'Beneficios.xlsx');
    } else {
      console.log('No hay datos para exportar');
    }
  };

  const back = colors.backGroundImage;

  return (
    <div style={{backgroundImage: `url(${back})`, backgroundSize: '100vw 100vh', height: '100vh', width: '100%'}}>
      <Header pageTitle={title} showBackButton={true} />
      {isLoading && (
        <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000}}>
          <Loader type="spinner-circle" bgColor={colors.WHITE} color={colors.BACKG_Color} size={100} />
        </div>
      )}
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', marginTop: '30px'}}>
        {/* Contenido */}
        <div style={{zIndex: 100, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{bottom: 0, padding: 10, backgroundSize: 'cover', width: '90vw', flex: 1, marginTop: '10px', flexDirection: 'row', zIndex: 990, maxWidth: '90vw', backgroundColor: title === 'ELIMINA BENEFICIOS' ? colors.LIGHT_GREEN : colors.MARRON_OSCURO, borderRadius: '50px'}}>
            {/* Botones de pestaña */}
            <div style={{position: 'absolute', marginTop: '-50px', marginLeft: '50px', display: 'flex'}}>
              <button onClick={() => setTitle('CONSULTA BENEFICIOS')} className="button-pest" style={{backgroundColor: colors.MARRON_OSCURO}}>
                CONSULTA
              </button>
              <button onClick={() => setTitle('ELIMINA BENEFICIOS')} className="button-pest" style={{backgroundColor: colors.LIGHT_GREEN}}>
                ELIMINAR
              </button>
            </div>

            <div style={{marginTop: '20px'}}>
              <div style={{color: '#fff', display: 'grid', alignItems: 'center', backgroundColor: title === 'ELIMINA BENEFICIOS' ? colors.LIGHT_GREEN : colors.MARRON_OSCURO, padding: 20, borderRadius: 20, maxHeight: '70vh', overflow: 'auto'}}>
                <table {...getTableProps()} style={{maxWidth: '100%', marginTop: '2%'}}>
                  <thead>
                    {headerGroups.map(headerGroup => {
                      const {key, ...restHeaderGroupProps} = headerGroup.getHeaderGroupProps();
                      return (
                        <tr key={key} {...restHeaderGroupProps}>
                          {headerGroup.headers.map(column => {
                            const {key, ...restColumn} = column.getHeaderProps();
                            return (
                              <th key={key} {...restColumn}>
                                {column.render('Header')}
                                <div style={{width: '100%'}}>{column.canFilter ? column.render('Filter') : null}</div>
                              </th>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </thead>
                  <tbody {...getTableBodyProps}>
                    {page.map(row => {
                      prepareRow(row);
                      const {key, ...restRowProps} = row.getRowProps();
                      return (
                        <tr key={key} {...restRowProps}>
                          {row.cells.map(cell => {
                            const {key, ...restCellProps} = cell.getCellProps();
                            return (
                              <td key={key} {...restCellProps} style={cell.column.style}>
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="pagination">
                  <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                  </button>
                  <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                  </button>
                  <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                  </button>
                  <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>
                    {'>>'}
                  </button>
                  <span>
                    Página{' '}
                    <strong>
                      {pageIndex + 1} de {pageOptions.length}
                    </strong>
                  </span>
                  <select
                    value={pageSize}
                    onChange={e => {
                      setPageSize(Number(e.target.value));
                    }}>
                    {[10, 20, 30, 40, 50].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Mostrar {pageSize}
                      </option>
                    ))}
                  </select>
                </div>

                <div style={{marginTop: '35px', alignItems: 'center', display: 'flex', justifyContent: 'flex-start', marginBottom: '10px'}}></div>
              </div>
            </div>
          </div>
          <div style={{textAlign: 'center'}}>
            <CustomButton onPress={title === 'CONSULTA BENEFICIOS' ? handleExport : handleDelete} title={title === 'CONSULTA BENEFICIOS' ? 'EXPORTAR A EXCEL' : 'ELIMINAR SELECCIONADOS'} color={colors.MARRON_OSCURO} fontSize={18} buttonWidth={280} buttonStyle={{padding: 0, cursor: 'pointer', border: 'none'}} />
          </div>
        </div>
        {popupOpenError && (
          <div style={{zIndex: 999, borderRadius: 30, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{width: '80%', backgroundColor: buttonColor, padding: '20px', borderRadius: 40, maxWidth: '300px', height: 250}}>
              <p style={{fontFamily: 'Como-Bold', marginTop: '20%', color: 'white', fontSize: '1.5em', textAlign: 'center'}}>{mensaje}</p>
              <div style={{display: 'flex', justifyContent: 'center', marginTop: '20%'}}>
                <button className="button-mensaje" style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em'}} onClick={handlePopupCloseError}>
                  OK
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportBeneficios;
