import { toBeRequired } from "@testing-library/jest-dom/dist/matchers";
import backgorundImage from "../../assets/imgs/FONDOCATCH.png";

export const colors = {
  gray: "#2e2d2c",
  degrade: "linear-gradient(70deg, #000000, #eee)",
  image: toBeRequired,
  backcolor: "#272726",
  backbeige: "#BFA38A",
  CARD: "#BFA38A",
  box: "white",
  textcolor: "#002169",
  DARK_BLUE: "#8990a2",
  YELLOW: "#F7B42D",
  BLACK: "#000",
  BLUE: "#011F26",
  MINICARD: "#D1C2BE",
  GRAY: "#272726",
  WHITE: "#FFFFFF",
  GREEN: "#0A8E30",
  MODE: "cruz",
  BLUE_HI: "#055686",
  MORADO: "#E4414B",
  DORADO: "rgb(189,155,91)",
  BACKG_Color: "#3B4450",
  GRAYL: "#d3d3d3",
  TITLES: "#fff",
  ORANGE: "#FF7D01",
  NAV_Menu: "#595048",
  MARRON_OSCURO: "#8c7570",
  RED: "#E4414B",
  LIGHT_GREEN: "#788199",
  DARKGREEN: "#666F88 ",
  backGroundImage: backgorundImage,
  backGroundColor:"false"
  //   //'#7d9707'
};
