import React from "react";
import { useHistory } from "react-router-dom";
import { colors } from "../utils/index";
import logo from "../../assets/Logos/logo_header.png";
import backArrow from "../../assets/imgs/back.png";

const Header = ({ pageTitle, showBackButton }) => {
  const history = useHistory();

  const handleLogOut = () => {
    history.push("/login");
  };

  const handleGoBack = () => {
    history.goBack();
  };
  const mincel = window.innerWidth < 900
  return (
    <div
      style={{
        backgroundColor: "transparent",
        display: "flex",
		alignItems: "center",
		width: '100vw',
		height: '100px'
      }}
    >
      {/* Logo y Flecha hacia atrás */}
      <div style={{ display: "flex", alignItems: "center" , width:'25vw'}}>
        {/* Logo */}
        <img
          src={logo}
          alt="Logo"
          style={{ width:mincel? "100px": "150px"}}
        />

        {/* Flecha hacia atrás */}
        {showBackButton && (
          <button
            style={{
              cursor: "pointer",
              border: "none",
              backgroundColor: "transparent",
            }}
            onClick={handleGoBack}
          >
            <img src={backArrow} alt="Back" style={{ width:mincel? "30px": "50px" }} />
          </button>
        )}
      </div>

      {/* Title */}
      <div style={{width:'50vw'}}>
        <h1 style={{ color: colors.TITLES, textAlign: "center", margin: 0, fontSize: mincel? '1em':'1.5em' }}>
          {pageTitle}
        </h1>
      </div>

      {/* Log Out */}
      <div style={{width:'25vw'}}>
        <button
          style={{
			cursor: "pointer",
			width:'150px',
            border: "none",
            color: "#fff",
            backgroundColor: "transparent",
          }}
          onClick={handleLogOut}
        >
          LOG OUT
        </button>
      </div>
    </div>
  );
};

export default Header;
