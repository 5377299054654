import React, {useState, useEffect, useMemo, useCallback} from 'react';
import {useTable, useFilters, useGlobalFilter, usePagination} from 'react-table';
import * as XLSX from 'xlsx';
import Loader from 'react-js-loader';
import Header from './Header';
import {colors} from '../utils/index';
import './Main.css';
import CustomButton from '../utils/CustomButton';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {Select, MenuItem} from '@mui/material';
import moment from 'moment';

const CrudNotifications = () => {
  const mincel = window.innerWidth < 900;
  const [isLoading, setIsLoading] = useState(false);
  const [popupOpenError, setPopupOpenError] = useState(false);
  const [popupOpenYesorNo, setPopupOpenYesorNo] = useState(false);
  const [mensaje, setMensaje] = useState('');
  const [selectAllHeader, setSelectAllHeader] = useState(false);
  const [buttonColor, setButtonColor] = useState(colors.RED);
  const [title, setTitle] = useState('CONSULTA NOTIFICACIONES');
  const [titleExcel, setTitleExcel] = useState('TODOS');
  const [responseData, setResponseData] = useState([]);
  // eslint-disable-next-line
  const [selectAll, setSelectAll] = useState(false);
  const [userClubData, setUserClubData] = useState([]); // Ensure it's an array
  const [notificationsToDelete, setNotificationsToDelete] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [sendLoginType, setSendLoginType] = useState('all');
  const [formBackgroundColor, setFormBackgroundColor] = useState(colors.MARRON_OSCURO);
  const [selectedRow, setSelectedRow] = useState({
    description: '',
    dni: '',
    fechaDesde: '',
    fechaHasta: '',
    idBenefitEvent: '',
    loginType: '',
    title: '',
    typeNote: '3', // Iniciar con INFORMACIÓN
  });

  const [searching, setSearching] = useState(false);

  const defaultColumn = useMemo(
    () => ({
      Filter: ({column}) => {
        const {filterValue, setFilter} = column;
        return (
          <input
            value={filterValue || ''}
            onChange={e => setFilter(e.target.value)}
            placeholder={`Filtrar por ${column.Header}`}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                const filterText = e.target.value;
                setFilter(filterText);
              }
            }}
          />
        );
      },
      filter: (rows, columnIds, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[columnIds[0]];
          if (columnIds[0] === 'loginType') {
            if (filterValue.toLowerCase() === 'member') return rowValue === '10';
            if (filterValue.toLowerCase() === 'evolution') return rowValue === '12';
            if (filterValue.toLowerCase() === 'boleteria') return rowValue === '102';
            if (filterValue.toLowerCase() === 'guardia') return rowValue === '103';
            if (filterValue.toLowerCase() === 'barman') return rowValue === '104';
			if (filterValue.toLowerCase() === 'marca') return rowValue === '105';
			if (filterValue.toLowerCase() === 'todos') return rowValue === 'ALL';
            return true; // No aplica filtro si no coincide
          }
          return rowValue.toLowerCase().startsWith(filterValue.toLowerCase());
        });
      },
    }),
    [],
  );

  useEffect(() => {
    setIsLoading(true);
    const fetchOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({dni: 'all', loginType: 'all'}),
    };

    Promise.all([
      fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/consultNotifications', fetchOptions),
      fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/consultUsersClubNames', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({}),
      }),
    ])
      .then(([notificationsResponse, usersResponse]) => {
        if (!notificationsResponse.ok) {
          throw new Error(`Error en la respuesta de notificaciones: ${notificationsResponse.statusText}`);
        }
        if (!usersResponse.ok) {
          throw new Error(`Error en la respuesta de usuarios: ${usersResponse.statusText}`);
        }
        return Promise.all([notificationsResponse.json(), usersResponse.json()]);
      })
      .then(([notificationsData, usersData]) => {
        setIsLoading(false);

        if (notificationsData.error) {
          setMensaje('Error en carga de notificaciones');
          setTitle('REGISTRA NOTIFICACIONES');
          setPopupOpenError(true);
        } else {
          const formattedData = notificationsData.map(notification => ({
            ...notification,
            fechaDesde: notification.fechaDesde ? moment(notification.fechaDesde, 'YYYY/MM/DD').format('YYYY-MM-DD') : '',
            fechaHasta: notification.fechaHasta ? moment(notification.fechaHasta, 'YYYY/MM/DD').format('YYYY-MM-DD') : '',
          }));

          setResponseData(formattedData || []);
          setFilteredRecords(formattedData || []);
        }

        if (usersData.error) {
          console.error('Error al obtener los usuarios del club:', usersData.error);
        } else if (Array.isArray(usersData.data)) {
          setUserClubData(
            usersData.data.map(user => ({
              dni: user.dni,
              firstName: user.firstName,
              lastName: user.lastName,
              loginType: user.loginType,
            })),
          );
        } else {
          console.error('Los datos de los usuarios del club no están en el formato esperado:', usersData);
        }
      })
      .catch(error => {
        console.error('Error al cargar los datos:', error);
        setIsLoading(false);
      });
  }, []);

  const handleSelectAllHeader = useCallback(() => {
    const newValue = !selectAllHeader;
    setSelectAllHeader(newValue);

    if (newValue) {
      setNotificationsToDelete(responseData.map(notification => notification.idDateTime));
    } else {
      setNotificationsToDelete([]);
    }
  }, [selectAllHeader, responseData]);

  const handleSelectNotification = useCallback(
    notificationId => {
      if (notificationsToDelete.includes(notificationId)) {
        setNotificationsToDelete(notificationsToDelete.filter(id => id !== notificationId));
      } else {
        setNotificationsToDelete([...notificationsToDelete, notificationId]);
      }
    },
    [notificationsToDelete],
  );

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div style={{marginTop: '-20px'}}>
            <input type="checkbox" checked={selectAllHeader} onChange={handleSelectAllHeader} />
            {titleExcel}
          </div>
        ),
        disableFilters: true,
        Cell: ({row}) => <input type="checkbox" checked={notificationsToDelete.includes(row.original.idDateTime)} onChange={() => handleSelectNotification(row.original.idDateTime)} />,
        accessor: 'checkbox',
      },
      {
        Header: 'TIPO',
        accessor: 'loginType',
        style: {textAlign: 'left'},
        Cell: ({value}) => (value === '10' ? 'MEMEBER' : value === '12' ? 'EVOLUTION' : value === '102' ? 'BOLETERIA' : value === '103' ? 'GUARDIA' : value === '104' ? 'BARMAN' : value === '104' ? 'TODOS' : 'MARCA'),
      },
      {Header: 'Título', accessor: 'title', style: {textAlign: 'left'}},
      {Header: 'Descripción', accessor: 'description', style: {textAlign: 'left', width: 400}},
      {Header: 'DNI', accessor: 'dni', style: {textAlign: 'center'}},
      {Header: 'Fecha Desde', accessor: 'fechaDesde', style: {textAlign: 'left'}},
      {Header: 'Fecha Hasta', accessor: 'fechaHasta', style: {textAlign: 'left'}},
      {Header: 'Beneficio', accessor: 'idBenefitEvent', style: {textAlign: 'left'}},

      {Header: 'Tipo Nota', accessor: 'typeNote', style: {textAlign: 'left'}},
    ],
    [notificationsToDelete, handleSelectNotification, handleSelectAllHeader, selectAllHeader, titleExcel],
  );

  const tableData = useMemo(
    () => ({
      columns,
      data: Array.isArray(responseData) ? responseData : [],
      defaultColumn,
    }),
    [responseData, columns, defaultColumn],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {pageIndex, pageSize},
  } = useTable(tableData, useFilters, useGlobalFilter, usePagination);

  useEffect(() => {
    if (title === 'ELIMINA NOTIFICACIONES') {
      setFilteredRecords(rows.map(row => row.original).filter(record => notificationsToDelete.includes(record.idDateTime)));
    } else {
      setFilteredRecords(rows.map(row => row.original));
    }
  }, [rows, notificationsToDelete, title, setPageSize]);

  const handleExport = () => {
    if (filteredRecords && filteredRecords.length > 0) {
      exportToExcel(filteredRecords);
    } else {
      console.log('No hay datos para exportar');
    }
  };

  const exportToExcel = filteredRows => {
    const exportData = filteredRows.map(row => ({
      Descripción: row.description,
      DNI: row.dni,
      'Fecha Desde': row.fechaDesde,
      'Fecha Hasta': row.fechaHasta,
      'ID Beneficio/Evento': row.idBenefitEvent,
      'Tipo Login': row.loginType,
      Título: row.title,
      'Tipo Nota': row.typeNote,
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
    XLSX.writeFile(workbook, 'report.xlsx');
  };

  if (localStorage.dni === '') {
    localStorage.clear();
    document.location.href = '/Login';
  }

  const handleDeleteNotification = async () => {
    try {
      setIsLoading(true);
      const body = notificationsToDelete.map(idDateTime => ({idDateTime}));

      const response = await fetch('https://your-api-endpoint/deleteNotifications', {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (data.error) {
        setButtonColor(colors.RED);
        setMensaje('Error en la eliminación, inténtelo de nuevo.');
        setPopupOpenError(true);
      } else {
        setResponseData(prevData => prevData.filter(notification => !notificationsToDelete.includes(notification.idDateTime)));
        setFilteredRecords(prevData => prevData.filter(notification => !notificationsToDelete.includes(notification.idDateTime)));
        setMensaje('Eliminación exitosa');
        handleClear();
        setButtonColor(colors.GREEN);
        setPopupOpenError(true);
      }
      setIsLoading(false);
      setNotificationsToDelete([]);
    } catch (error) {
      console.error('Error al eliminar notificaciones:', error);
      setIsLoading(false);
    }
  };

  const handlePopupCloseError = () => {
    setPopupOpenError(false);
    setPopupOpenYesorNo(false);
  };

  const handlePopupYesorNo = async () => {
    if (title === 'ELIMINA NOTIFICACIONES') {
      handleDeleteNotification();
    } else {
      handleExport();
    }
  };

  const handleSubmit = async () => {
    try {
      const isValid = await validateData();
      if (!isValid) {
        return;
      }

      const existingNotification = responseData.find(notification => notification.idDateTime === selectedRow.idDateTime);

      if (existingNotification) {
        if (title === 'ELIMINAR NOTIFICACIONES') {
          await handleDeleteNotification();
        }
      } else {
        await handleRegister();
      }
    } catch (error) {
      console.error('Error al procesar el formulario:', error.message);
    }
  };

  const handleAtualizapup = async () => {
    setFormBackgroundColor(colors.MARRON_OSCURO);
    setTitle('CONSULTA NOTIFICACIONES');
    setTitleExcel('');
    setSelectedRow({
      description: '',
      dni: '',
      fechaDesde: '',
      fechaHasta: '',
      idBenefitEvent: '',
      loginType: 'all',
      title: '',
      typeNote: '3',
    });
  };

  const handleElimina = async () => {
    setFormBackgroundColor(colors.LIGHT_GREEN);
    setTitle('ELIMINA NOTIFICACIONES');
    setTitleExcel('TODOS');
    setSelectedRow({
      description: '',
      dni: '',
      fechaDesde: '',
      fechaHasta: '',
      idBenefitEvent: '',
      loginType: 'all',
      title: '',
      typeNote: '3',
    });
  };

  const handleClear = () => {
    setSelectedRow({
      description: '',
      dni: '',
      fechaDesde: '',
      fechaHasta: '',
      idBenefitEvent: '',
      loginType: 'all',
      title: '',
      typeNote: '3',
    });
  };
  const validateData = useCallback(() => {
    const {title, description, fechaDesde, fechaHasta} = selectedRow;

    if (!title || title.trim() === '') {
      setMensaje('El título no puede estar vacío');
      setPopupOpenError(true);
      return false;
    }

    if (!description || description.trim() === '') {
      setMensaje('La descripción no puede estar vacía');
      setPopupOpenError(true);
      return false;
    }

    if (!fechaDesde || !fechaHasta) {
      setMensaje('Las fechas no pueden estar vacías');
      setPopupOpenError(true);
      return false;
    }

    if (new Date(fechaDesde) > new Date(fechaHasta)) {
      setMensaje('La fecha "Desde" debe ser anterior a la fecha "Hasta"');
      setPopupOpenError(true);
      return false;
    }

    return true;
  }, [selectedRow]); // Depend on selectedRow as it is used inside validateData

  const handleRowClick = row => {
    const selectedUser = userClubData.find(user => user.dni === row.dni);

    setSelectedRow({
      idDateTime: row.idDateTime || '',
      description: row.description || '',
      dni: row.dni || '',
      fechaDesde: moment(row.fechaDesde).format('YYYY-MM-DD') || '',
      fechaHasta: moment(row.fechaHasta).format('YYYY-MM-DD') || '',
      idBenefitEvent: row.idBenefitEvent || '',
      firstName: selectedUser ? selectedUser.firstName : '',
      lastName: selectedUser ? selectedUser.lastName : '',
      loginType: row.loginType || '',
      title: row.title || '',
      typeNote: row.typeNote || '',
    });
  };

const handleRegister = useCallback(async () => {
  try {
    const isValid = await validateData();
    if (!isValid) {
      return;
    }

    setIsLoading(true);

    let loginType = '';
    let dni = selectedRow.dni;

    if (sendLoginType === 'all') {
      loginType = 'all';
      dni = 'all';
    } else if (sendLoginType === '10') {
      loginType = '10';
      dni = 'all';
    } else if (sendLoginType === '12') {
      loginType = '12';
      dni = 'all';
    } else if (searching && selectedRow.dni) {
      const selectedUser = userClubData.find(user => user.dni === selectedRow.dni);
      loginType = selectedUser ? selectedUser.loginType : '';
    } else {
      loginType = sendLoginType;
      dni = 'all';
    }

    const body = {
      description: selectedRow.description,
      dni: dni,
      fechaDesde: moment(selectedRow.fechaDesde).format('YYYY/MM/DD'),
      fechaHasta: moment(selectedRow.fechaHasta).format('YYYY/MM/DD'),
      idBenefitEvent: selectedRow.idBenefitEvent,
      loginType: loginType,
      title: selectedRow.title,
      typeNote: selectedRow.typeNote,
    };

    const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/registerNotificationsQR', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(body),
    });

    const data = await response.json();
    setIsLoading(false);

    if (data.error) {
      setMensaje(data.error);
      setButtonColor(colors.RED);
      setPopupOpenError(true);
    } else if (data.inserted_notifications) {
      setResponseData(prevData => [...prevData, ...data.inserted_notifications]);
      setFilteredRecords(prevData => [...prevData, ...data.inserted_notifications]);
      setMensaje('ALTA EXITOSA');
      setButtonColor(colors.GREEN);
      setPopupOpenError(true);
      handleClear();
    } else {
      setMensaje('Error de Servidor, Intente Nuevamente');
      setButtonColor(colors.RED);
      setPopupOpenError(true);
    }
  } catch (error) {
    console.error('Error al registrar notificación:', error.message);
    setIsLoading(false);
  }
}, [selectedRow, validateData, sendLoginType, searching, userClubData]);




  const handleCheckboxToggle = () => {
    setSearching(!searching);
  };

  const handleUserChange = (e, selectedOption) => {
    if (!selectedOption) {
      setSelectedRow(prevState => ({
        ...prevState,
        dni: '',
        firstName: '',
        lastName: '',
        loginType: '',
      }));
      return;
    }

    const selectedUser = userClubData.find(user => user.dni === selectedOption.value);

    if (selectedUser) {
      setSelectedRow(prevState => ({
        ...prevState, // Conservar los demás campos de `selectedRow`
        firstName: selectedUser.firstName,
        lastName: selectedUser.lastName,
        dni: selectedUser.dni,
        loginType: selectedUser.loginType,
      }));
    }
  };

  const handleTypeNoteChange = event => {
    setSelectedRow(prevRow => ({
      ...prevRow,
      typeNote: event.target.value,
    }));
  };

  const back = colors.backGroundImage;

  return (
    <div style={{backgroundImage: `url(${back})`, backgroundSize: '100vw 100vh', height: '100%', width: '100%'}}>
      <Header pageTitle={title} showBackButton={true} />
      <h1 style={{color: '#fff', textAlign: 'center', justifyContent: 'center', marginTop: -30}}>NOTIFICACIONES</h1>
      {isLoading && (
        <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000}}>
          <Loader type="spinner-circle" bgColor={colors.WHITE} color={colors.BACKG_Color} size={100} />
        </div>
      )}
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', marginTop: '30px'}}>
        <div style={{zIndex: 100, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{bottom: 0, padding: 10, backgroundSize: 'cover', width: '90vw', flex: 1, marginTop: '10px', flexDirection: 'row', zIndex: 990, maxWidth: '90vw', backgroundColor: formBackgroundColor, borderRadius: '50px'}}>
            <div style={{position: 'absolute', marginTop: '-50px', marginLeft: '50px', display: 'flex'}}>
              <button onClick={handleAtualizapup} className="button-pest" style={{backgroundColor: colors.MARRON_OSCURO}}>
                CONSULTA
              </button>
              <button onClick={handleElimina} className="button-pest" style={{backgroundColor: colors.LIGHT_GREEN}}>
                ELIMINAR
              </button>
              <button onClick={handleClear} className="button-pest" style={{backgroundColor: colors.RED}}>
                LIMPIAR
              </button>
            </div>

            {selectedRow && (
              <div style={{marginTop: '20px', padding: '20px', backgroundColor: colors.MINICARD, borderRadius: '20px', boxShadow: '0 0 10px rgba(0,0,0,0.2)', width: '90%', margin: '0 auto'}}>
                <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '10px'}}>
                  <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                    <label className="textTerm">TÍTULO</label>
                    <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="text" required id="title" placeholder="Título" value={selectedRow.title || ''} onChange={e => setSelectedRow({...selectedRow, title: e.target.value})} />
                  </div>
                  <div style={{display: 'flex', alignItems: 'center', marginTop: 20}}>
                    <label style={{color: '#fff', marginRight: 10}}>ALL</label>
                    <input type="checkbox" checked={!searching} onChange={handleCheckboxToggle} />
                  </div>
                </div>

                <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px'}}>
                  <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
                    <p style={{marginTop: '10px'}} className="textTerm">
                      Fecha Desde
                    </p>
                    <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="date" required id="fechaDesde" placeholder="yyyy/mm/dd" onChange={e => setSelectedRow({...selectedRow, fechaDesde: e.target.value})} value={selectedRow.fechaDesde || ''} />
                  </div>
                  <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
                    <p style={{marginTop: '10px'}} className="textTerm">
                      Fecha Hasta
                    </p>
                    <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="date" required id="fechaHasta" placeholder="yyyy/mm/dd" onChange={e => setSelectedRow({...selectedRow, fechaHasta: e.target.value})} value={selectedRow.fechaHasta || ''} />
                  </div>
                </div>
                <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: '1fr', columnGap: '10px'}}>
                  <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                    <label className="textTerm">DESCRIPCIÓN</label>
                    <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="text" required id="description" placeholder="Descripción" value={selectedRow.description || ''} onChange={e => setSelectedRow({...selectedRow, description: e.target.value})} />
                  </div>
                </div>
                {/* <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: '1fr', columnGap: '10px'}}>
                  <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                    <label className="textTerm">ID BENEFICIO/EVENTO</label>
                    <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="text" required id="idBenefitEvent" placeholder="ID Beneficio/Evento" value={selectedRow.idBenefitEvent || ''} onChange={e => setSelectedRow({...selectedRow, idBenefitEvent: e.target.value})} />
                  </div>
                </div> */}
                <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '10px'}}>
                  <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                    <label className="textTerm">TIPO DE NOTA</label>
                    <Select value={selectedRow.typeNote} onChange={handleTypeNoteChange} displayEmpty style={{width: '100%', marginTop: 10}}>
                      <MenuItem value="" disabled>
                        Seleccione el tipo de nota
                      </MenuItem>
                      <MenuItem value="1">BENEFICIO</MenuItem>
                      <MenuItem value="2">GIFTS</MenuItem>
                      <MenuItem value="3">INFORMACIÓN</MenuItem>
                      <MenuItem value="voucher">VOUCHER</MenuItem>
                    </Select>
                  </div>
                </div>
                <div style={{marginTop: '5px', display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
                  <input
                    type="radio"
                    style={{width: '20px', height: '20px'}}
                    checked={searching === false && sendLoginType === 'all'}
                    onChange={() => {
                      setSearching(false);
                      setSendLoginType('all');
                    }}
                  />
                  <p style={{color: 'white', fontSize: mincel ? '1em' : '1em', marginLeft: '10px'}}>TODOS</p>

                  <input
                    type="radio"
                    style={{width: '20px', height: '20px', marginLeft: '20px'}}
                    checked={searching === false && sendLoginType === '10'}
                    onChange={() => {
                      setSearching(false);
                      setSendLoginType('10');
                    }}
                  />
                  <p style={{color: 'white', fontSize: mincel ? '1em' : '1em', marginLeft: '10px'}}>MEMBER</p>

                  <input
                    type="radio"
                    style={{width: '20px', height: '20px', marginLeft: '20px'}}
                    checked={searching === false && sendLoginType === '12'}
                    onChange={() => {
                      setSearching(false);
                      setSendLoginType('12');
                    }}
                  />
                  <p style={{color: 'white', fontSize: mincel ? '1em' : '1em', marginLeft: '10px'}}>EVOLUTION</p>
                  <input
                    type="radio"
                    style={{width: '20px', height: '20px', marginLeft: '20px'}}
                    checked={searching === true && sendLoginType === ''}
                    onChange={() => {
                      setSearching(true);
                      setSendLoginType('');
                    }}
                  />
                  <p style={{color: 'white', fontSize: mincel ? '1em' : '1em', marginLeft: '10px'}}>INDIVIDUAL</p>
                </div>
                <div style={{flexDirection: 'column', marginTop: '10px', display: 'grid', gridTemplateColumns: searching ? '1fr 1fr' : '1fr', columnGap: '10px', opacity: searching ? 1 : 0.5, pointerEvents: searching ? 'auto' : 'none'}}>
                  <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                    <label className="textTerm">NOMBRE</label>
                    <Autocomplete
                      sx={{width: mincel ? '90vw' : '30vw', marginTop: '10px', backgroundColor: '#fff', border: '2px solid #000'}}
                      value={userClubData.find(user => user.firstName === selectedRow.firstName) || null}
                      onChange={(e, selectedOption) => handleUserChange(e, {value: selectedOption?.dni})}
                      isOptionEqualToValue={(option, value) => option.dni === value.dni}
                      options={userClubData}
                      getOptionLabel={option => option?.firstName || ''}
                      renderOption={(props, option) => (
                        <li {...props} key={option.dni}>
                          {option.firstName}
                        </li>
                      )}
                      renderInput={params => <TextField {...params} label="Seleccione NOMBRE" variant="outlined" />}
                    />
                  </div>
                  <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                    <label className="textTerm">APELLIDO</label>
                    <Autocomplete
                      sx={{width: mincel ? '90vw' : '30vw', marginTop: '10px', backgroundColor: '#fff', border: '2px solid #000'}}
                      value={userClubData.find(user => user.lastName === selectedRow.lastName) || null}
                      onChange={(e, selectedOption) => handleUserChange(e, {value: selectedOption?.dni})}
                      isOptionEqualToValue={(option, value) => option.dni === value.dni}
                      options={userClubData}
                      getOptionLabel={option => option?.lastName || ''}
                      renderOption={(props, option) => (
                        <li {...props} key={option.dni}>
                          {option.lastName}
                        </li>
                      )}
                      renderInput={params => <TextField {...params} label="Seleccione APELLIDO" variant="outlined" />}
                    />
                  </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '10px'}}>
                  {title === 'ELIMINA NOTIFICACIONES' ? (
                    <></>
                  ) : (
                    <CustomButton
                      onPress={handleSubmit}
                      title="AGREGAR NOTIFICACIÓN"
                      color={colors.MARRON_OSCURO} // Ajusta el color según sea necesario
                      fontSize={18} // Ajusta el tamaño de la fuente si es necesario
                      buttonWidth={200} // Ajusta el ancho del botón si es necesario
                    />
                  )}
                </div>
              </div>
            )}
            <div style={{marginTop: '20px'}}>
              <div style={{display: 'grid', alignItems: 'center', backgroundColor: formBackgroundColor, padding: 20, borderRadius: 20, maxHeight: '30vh', overflow: 'auto'}}>
                <table {...getTableProps()} style={{maxWidth: '100%', marginTop: '2%'}}>
                  <thead>
                    {headerGroups.map(headerGroup => {
                      const {key, ...restHeaderGroupProps} = headerGroup.getHeaderGroupProps();
                      return (
                        <tr key={key} {...restHeaderGroupProps}>
                          {headerGroup.headers.map(column => {
                            const {key, ...restColumn} = column.getHeaderProps();
                            return (
                              <th key={key} {...restColumn}>
                                {column.render('Header')}
                                <div style={{width: '100%'}}>{column.canFilter ? column.render('Filter') : null}</div>
                              </th>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </thead>
                  <tbody {...getTableBodyProps}>
                    {page.map(row => {
                      prepareRow(row);
                      const {key, ...restRowProps} = row.getRowProps();
                      return (
                        <tr key={key} {...restRowProps} onClick={() => handleRowClick(row.original)}>
                          {row.cells.map(cell => {
                            const {key, ...restCellProps} = cell.getCellProps();
                            return (
                              <td key={key} {...restCellProps} style={cell.column.style}>
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="pagination">
                  <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                  </button>
                  <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                  </button>
                  <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                  </button>
                  <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>
                    {'>>'}
                  </button>
                  <span>
                    Página{' '}
                    <strong>
                      {pageIndex + 1} de {pageOptions.length}
                    </strong>
                  </span>
                  <select value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
                    {[10, 20, 30, 40, 50].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Mostrar {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{marginTop: '35px', alignItems: 'center', display: 'flex', justifyContent: 'flex-start', marginBottom: '10px'}}>
                  <div style={{textAlign: 'center'}}>
                    <CustomButton
                      onPress={title === 'CONSULTA NOTIFICACIONES' ? handleExport : handleDeleteNotification}
                      title={title === 'CONSULTA NOTIFICACIONES' ? 'EXPORTAR XLS' : 'ELIMINAR'}
                      color={colors.MARRON_OSCURO} // Ajusta el color según sea necesario
                      fontSize={18} // Ajusta el tamaño de la fuente si es necesario
                      buttonWidth={200} // Ajusta el ancho del botón si es necesario
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {popupOpenError && (
          <div style={{zIndex: 999, position: 'fixed', borderRadius: 30, top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{width: '80%', backgroundColor: buttonColor, padding: '20px', borderRadius: 40, maxWidth: '300px', height: 250}}>
              <p style={{fontFamily: 'Como-Bold', marginTop: '20%', color: 'white', fontSize: '1.5em', textAlign: 'center'}}>{mensaje}</p>
              <div style={{display: 'flex', justifyContent: 'center', marginTop: '20%'}}>
                <button className="button-mensaje" style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em'}} onClick={handlePopupCloseError}>
                  OK
                </button>
              </div>
            </div>
          </div>
        )}
        {popupOpenYesorNo && (
          <div style={{zIndex: 999, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 30}}>
            <div style={{backgroundColor: buttonColor, padding: '20px', borderRadius: 40, width: '300px', height: '250px'}}>
              <p style={{fontFamily: 'Como-Bold', marginTop: '20%', color: 'white', fontSize: '1.5em', textAlign: 'center'}}>{mensaje}</p>
              <div style={{display: 'flex', justifyContent: 'center', marginTop: '20%'}}>
                <button
                  className="button-mensaje"
                  style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em'}}
                  onClick={() => {
                    handlePopupYesorNo();
                    handlePopupCloseError();
                  }}>
                  SI
                </button>
                <button className="button-mensaje" style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em', marginLeft: '10px'}} onClick={handlePopupCloseError}>
                  NO
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CrudNotifications;
