import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { colors } from "../utils/index";
import moment from "moment";
import "./Main.css";
// eslint-disable-next-line
import Loader from "react-js-loader";
// eslint-disable-next-line
import logo from "../../assets/Logos/logo_header.png"
const RegisterEvolutions = () => {
	// eslint-disable-next-line
	const history = useHistory();
	// eslint-disable-next-line
	const [value, onChange] = useState(new Date());
	  // eslint-disable-next-line
	const [isLoading, setIsLoading] = useState(false);
		// eslint-disable-next-line
	const [errors, setErrors] = useState({ send: false });
	const mincel = window.innerWidth < 900;
			// eslint-disable-next-line
	const [rrppOptions, setRRPPOptions] = useState([]);
	const [popupOpenError, setPopupOpenError] = useState(false);
	const [popupOpenYesorNo, setPopupOpenYesorNo] = useState(false);
	const [mensaje, setMensaje] = useState("");
	const [buttonColor, setButtonColor] = useState(colors.RED);
  // eslint-disable-next-line
	const [searchEnabled, setSearchEnabled] = useState(false);
	// eslint-disable-next-line
	const [searching, setSearching] = useState(false);


  // Estados para almacenar los datos del usuario y los errores de validación
  const [userData, setUserData] = useState({
    firstName: "",
    dni: "",
    lastName: "",
    address: "",
    email: "",
    email2: "",
    pr: "",
    birthDate: new Date(),
    sexo: "",
    zona: "",
    phone: "",
    loginType: "",
	password: "",
	  salt: "",
	ig:"",
    confirmPassword: "",
    avatar: "",
  });


const handlePopupCloseError = () => {
	//setPayEliminarNo(false)
	if (mensaje === 'ALTA EXITOSA') {
	    if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: "CLOSE_WEBVIEW" })
        );
      } else {
        window.close();
      }
	} else {
		setPopupOpenError(false);
		setPopupOpenYesorNo(false);
		setIsLoading(false);
	}

    };



const handleChange = async (e, selectedOption) => {
  let id, value;
  if (selectedOption) {
    // Si se seleccionó una opción en Autocomplete
    id = selectedOption.id;
    value = selectedOption.value;
  } else {
    // Si se trata de un cambio en otro campo del formulario
    id = e.target.id;
    value = e.target.value;
  }

  // Actualizar el estado de userData según el id del input
  setUserData((prevState) => ({
    ...prevState,
    [id]: value,
  }));

  if (id === "birthDate") {
    // Validar la fecha de nacimiento
    const date = new Date(value);
    if (isNaN(date.getTime())) {
      setMensaje(
        "La fecha de nacimiento no es válida. Por favor, ingrese una fecha válida."
      );
      setPopupOpenError(true);
      // Evitar que se almacene la fecha no válida
      return;
    }
  }
};


const handlePopupYesorNo = async () => {
    setPopupOpenYesorNo(true);
  };


const validateData = async () => {
		if (
			userData.firstName === "" ||
			userData.lastName === "" ||
			userData.email === "" ||
			userData.email2 === "" ||
			userData.dni === "" ||
			userData.phone === "" ||
			userData.address === ""
		) {
			setMensaje("Complete los datos");
			setPopupOpenError(true);
			return false;
		}
		const emailRegex =
			/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

		// Verificar si algún correo electrónico no cumple con la expresión regular
		if (!emailRegex.test(userData.email) || !emailRegex.test(userData.email2)) {
			setMensaje("Email inválido");
			setPopupOpenError(true);
			return false;
		}

		// Si los correos electrónicos cumplen con el formato, verificar si son iguales
		if (userData.email !== userData.email2) {
			setMensaje("Los correos electrónicos no coinciden");
			setPopupOpenError(true);
			return false;
		}

    return true;
  };

  // Función para manejar el envío del formulario de registro
	const handleSubmit = async (e) => {

	const isValid = await validateData();
	if (!isValid) {
		return; // No ejecutar el resto de la lógica si la validación falla
	}


		setIsLoading(true);
		const birthdayFormat = moment(userData.birthDate).format('YYYY/MM/DD')

		const body = {
				first_name: userData.firstName,
				last_name: userData.lastName,
				dni: userData.dni,
				address: userData.address,
				phone: userData.phone,
				loginType: '12',
				email: userData.email,
				zona: userData.zona,
				sexo: userData.sexo,
				birthDate: birthdayFormat,
			    pr: 'catch',
				ig: "",
			    dataTableName: "USERSCLUB",

				};

		// eslint-disable-next-line
const response = await fetch(
			"https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/registerUsersClub",
			{
				method: "POST",
				headers: {
					"content-type": "application/json",
				},
				body: JSON.stringify(body),
			}
		);
		const data = await response.json();

		setIsLoading(false);
		if (data.error) {
			setMensaje(data.error);
			setButtonColor(colors.RED)
			setPopupOpenError(true);


		} else {
			setMensaje("ALTA EXITOSA");
			setButtonColor(colors.GREEN);
			setPopupOpenError(true);

		}

  };
	const formatedDate = moment(userData.birthDate).format('YYYY-MM-DD')
	const back = colors.backGroundImage;

return (
  <div style={{backgroundImage: `url(${back})`, backgroundSize: '100vw 100vh', height: mincel ? '100%' : '100vh', width: '100%', overflow: 'auto'}}>
    {/* Encabezado */}
    {isLoading && (
      <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000}}>
        <Loader type="spinner-circle" bgColor={colors.WHITE} color={colors.BACKG_Color} size={100} />
      </div>
    )}
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      {/* Contenido */}
      <div style={{zIndex: 100, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{bottom: 0, padding: 10, backgroundSize: 'cover', width: mincel ? '100vw' : '90vw', flex: 1, marginTop: mincel ? 0 : '10px', flexDirection: 'row', zIndex: 990, maxWidth: '90vw', borderRadius: '50px'}}>
          {/* Botones de pestaña */}

          <div style={{marginTop: 30, fontSize: '2.2em', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
            <img src={logo} alt="Logo" style={{width: mincel ? '120px' : '150px'}} />
          </div>
          <div style={{}}>
            <div style={{marginTop: '20px', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
              <label className="textTermEv">DNI</label>
              <p className="textFormError">{userData.dni === '' ? 'Campo requerido' : ''}</p>
              <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="number" required id="dni" placeholder="DNI" onChange={handleChange} value={userData.dni} />
            </div>
            <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px', flexDirection: 'column'}}>
              <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                <label className="textTermEv">NOMBRE</label>
                <p className="textFormError">{userData.firstName === '' ? 'Campo requerido' : ''}</p>
                <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="text" required id="firstName" placeholder="Nombre" onChange={handleChange} onKeyDown={handleChange} value={userData.firstName} />
              </div>
              <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                <label className="textTermEv">APELLIDO</label>
                <p className="textFormError">{userData.lastName === '' ? 'Campo requerido' : ''}</p>
                <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="text" required id="lastName" placeholder="Apellido" onChange={handleChange} value={userData.lastName} />
              </div>
            </div>
          </div>

          <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px', flexDirection: 'column'}}>
            <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
              <p className="textTermEv">MAIL</p>
              <p className="textFormError">{userData.email === '' ? 'Campo requerido' : ''}</p>
              <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="email" required id="email" placeholder="E-mail" onChange={handleChange} value={userData.email} />
            </div>
            <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
              <p className="textTermEv">CONFIRMACIÓN DE MAIL</p>
              <p className="textFormError">{userData.email2 === '' ? 'Campo requerido' : ''}</p>
              <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="email" required id="email2" placeholder={'ej.: ejemplo@gmail.com'} className="no-outline" onChange={handleChange} value={userData.email2} />
            </div>
          </div>
          <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px'}}>
            <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
              <p className="textTermEv">CELULAR CON CODIGO DE ÁREA</p>
              <p className="textFormError">{userData.phone === '' ? 'Campo requerido' : ''}</p>
              <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="number" required id="phone" placeholder={'ej.: 011xxxxxxx'} className="no-outline" onChange={handleChange} value={userData.phone} />
            </div>
          </div>

          <div style={{marginTop: 5}}>
            <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
              <p className="textTermEv">DIRECCIÓN</p>
              <p className="textFormError">{userData.address === '' ? 'Campo requerido' : ''}</p>
              <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="text" required id="address" placeholder={'ej.: 0xxxxxxx'} className="no-outline" onChange={handleChange} value={userData.address} />
            </div>
          </div>
          <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px'}}>
            <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
              <p className="textTermEv">ZONA</p>

              <div style={{marginLeft: '20px'}}>
                <input className="radio_common" type="radio" id="zona" name="zona" value="1" onChange={handleChange} checked={userData.zona === '1'} />
                <label className="textTermEv" htmlFor="norte">
                  Norte
                </label>
              </div>
              <div>
                <input className="radio_common" type="radio" id="zona" name="zona" value="2" onChange={handleChange} checked={userData.zona === '2'} />
                <label className="textTermEv" htmlFor="sur">
                  Sur
                </label>
              </div>
              <div>
                <input className="radio_common" type="radio" id="zona" name="zona" value="3" onChange={handleChange} checked={userData.zona === '3'} />
                <label className="textTermEv" htmlFor="centro">
                  Centro
                </label>
              </div>
              <div>
                <input className="radio_common" type="radio" id="zona" name="zona" value="4" onChange={handleChange} checked={userData.zona === '4'} />
                <label className="textTermEv" htmlFor="otro">
                  Otro
                </label>
              </div>
            </div>
            <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
              <p className="textTermEv">SEXO</p>
              <div style={{marginLeft: '20px'}}>
                <input className="radio_common" type="radio" id="sexo" name="sexo" value="1" onChange={handleChange} checked={userData.sexo === '1'} />
                <label className="textTermEv" htmlFor="F">
                  F
                </label>
              </div>
              <div>
                <input className="radio_common" type="radio" id="sexo" name="sexo" value="2" onChange={handleChange} checked={userData.sexo === '2'} />
                <label className="textTermEv" htmlFor="M">
                  M
                </label>
              </div>
              <div>
                <input className="radio_common" type="radio" id="sexo" name="sexo" value="3" onChange={handleChange} checked={userData.sexo === '3'} />
                <label className="textTermEv" htmlFor="O">
                  Otro
                </label>
              </div>
            </div>
          </div>

          <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px'}}>
            <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
              <p style={{marginTop: '10px'}} className="textTermEv">
                Fecha de Nacimiento
              </p>
              <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none', fontSize: 20}} type="date" required id="birthDate" placeholder="dd-mm-yyyy" onChange={handleChange} value={formatedDate} />
            </div>
          </div>
        </div>
      </div>

      <div style={{marginTop: '35px', alignItems: 'center', display: 'flex', justifyContent: 'center', marginBottom: '10px'}}>
        <div style={{textAlign: 'center'}}>
          <button className="button-common" onClick={handleSubmit} type="submit" style={{padding: 0, cursor: 'pointer', border: 'none'}}>
            REGISTRAR
          </button>
        </div>
      </div>

      {popupOpenError && (
        <div style={{zIndex: 999, borderRadius: 30, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <div style={{width: '80%', backgroundColor: '#fff', padding: '20px', borderRadius: '25', maxWidth: '300px', height: 350, justifyContent: 'center', alignContent: 'center', alignItems: 'center', textAlign: 'center'}}>
            {mensaje === 'ALTA EXITOSA' ? (
              <>
                <p style={{fonstSize: 24, padding: 10, color: colors.DORADO, alignContent: 'center', textAlign: 'center', fontSize: 24, fontWeight: 'bold'}}>BIENVENIDO A CATCH BAR EVOLUTION!</p>
                <p style={{padding: 10, color: colors.BLACK, alignContent: 'center', textAlign: 'center', fontSize: 20}}>Ingresa con tu DNI y contraseña provisoria: 123.</p>
                <p style={{padding: 10, color: colors.BLACK, alignContent: 'center', textAlign: 'center', fontSize: 20}}>La puedes cambiar en PERFIL</p>
              </>
            ) : (
              <>
                <p style={{padding: 10, color: 'black', alignContent: 'center', textAlign: 'center', fontSize: 20}}>{mensaje}</p>
              </>
            )}
            <div style={{display: 'flex', justifyContent: 'center', marginTop: mensaje === 'ALTA EXITOSA' ? '10' : '30%'}}>
              <button className="button-mensaje" style={{width: 200, height: 40, backgroundColor: colors.DORADO, color: colors.WHITE, fontSize: '1.5em'}} onClick={handlePopupCloseError}>
                OK
              </button>
            </div>
          </div>
        </div>
      )}

      {popupOpenYesorNo && (
        <div style={{zIndex: 999, borderRadius: 30, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <div style={{width: '80%', backgroundColor: buttonColor, padding: '20px',borderRadius: 40, maxWidth: '300px', height: 250}}>
            <p style={{fontFamily: 'Como-Bold', marginTop: '20%', color: 'white', fontSize: '1.5em', textAlign: 'center'}}>{mensaje}</p>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '20%'}}>
              <button
                className="button-mensaje"
                style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em'}}
                onClick={() => {
                  handlePopupYesorNo();
                  handlePopupCloseError();
                }}>
                SI
              </button>
              <button
                className="button-mensaje"
                style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em', marginLeft: '10px'}}
                onClick={() => {
                  handlePopupCloseError();
                }}>
                NO
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
);
};

export default RegisterEvolutions;
