import React, { useState , useEffect} from "react";
import { useHistory } from "react-router-dom";
import { colors } from "../utils/index";
import Header from "./Header";
import "./Main.css";
import moment from 'moment'
// eslint-disable-next-line
import Loader from "react-js-loader";



const RegisterEvents = () => {
	// eslint-disable-next-line
	const history = useHistory();
	// eslint-disable-next-line
	const [value, onChange] = useState(new Date());
	  // eslint-disable-next-line
	const [isLoading, setIsLoading] = useState(false);
		// eslint-disable-next-line
	const [errors, setErrors] = useState({ send: false });
	const mincel = window.innerWidth < 900;
			// eslint-disable-next-line
	const [rrppOptions, setRRPPOptions] = useState([]);
	const [popupOpenError, setPopupOpenError] = useState(false);
	const [popupOpenYesorNo, setPopupOpenYesorNo] = useState(false);
	const [mensaje, setMensaje] = useState("");
	const [buttonColor, setButtonColor] = useState(colors.RED);
	const [title, setTitle] = useState("REGISTRA EVENTO");
	// eslint-disable-next-line
    const [allUsers, setAllUsers] = useState([]);
	const [formBackgroundColor, setFormBackgroundColor] = useState(colors.CARD);
  // eslint-disable-next-line
	const [searchEnabled, setSearchEnabled] = useState(false);
	// eslint-disable-next-line
	const [searching, setSearching] = useState(false);

	const fechaActual = new Date()
	fechaActual.setDate(fechaActual.getDate() + 1);
// eslint-disable-next-line
	function convertirFormatoFecha(fecha) {
	const partes = fecha.split('/');
	const fechaISO = `${partes[0]}-${partes[1]}-${partes[2]}`;
	return fechaISO;
	}




  // Estados para almacenar los datos del evento y los errores de validación
  const [userData, setUserData] = useState({
    nameEvent: "",
    subTitle: "",
    id: new Date(),
    idEvento : new Date(),
    fechaFinEvento: fechaActual,
    fechaInicioVenta: new Date(),
    fechaFinVenta: new Date(),
    horaInicioEvento: "10:00",
    horaFinEvento: "07:00",
    horaInicioVenta: "10:00",
    horaFinVenta: "22:00",
    description: "",
    price: "",
    image1: "",
    logo: "",
    ticketType: "10",
  });



// eslint-disable-next-line
	useEffect(() => {
    // eslint-disable-next-line
    loadAllUsers(); // Carga todos los eventos al inicio
  }, []);

 const loadAllUsers = async () => {
	 // Lógica para cargar todos los eventos
	 try {

		  setIsLoading(true);
	    const body = { tipoConsulta :'all'};
     // Realiza la llamada a la API para obtener todos los eventos
		 const response = await fetch(
       "https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/consultEventos",
       {
         method: "POST",
         headers: {
           "Content-Type": "application/json",
         },
         // Puedes incluir un cuerpo de solicitud si es necesario
         body: JSON.stringify(body),
       }
		 );
		 const data = await response.json();
		 setIsLoading(false);
		 if (data.error) {
			 if (data.error === "-PROXIMAMENTE") {
				 setMensaje("No hay Eventos");
				 setTitle("REGISTRA EVENTO");
				 setPopupOpenError(true);
			 } else if (data.error !== "-PROXIMAMENTE") {
				 setMensaje("Error en carga de Eventos");
				 setTitle("REGISTRA EVENTO");
				 setPopupOpenError(true);
			 }
		 } else {

			 await setAllUsers(data);
		}


   } catch (error) {
     console.error("Error al cargar los eventos:", error);
   }
 };

const handleDeleteButtonClick = async () => {
	setFormBackgroundColor(colors.LIGHT_GREEN);
	setTitle("ELIMINA EVENTO")
	if (allUsers.length > 0) {
		setSearchEnabled(true);
	}
  // Aquí debes escribir la lógica para eliminar eventos
};
const handleChangeButtonClick = async () => {
	setFormBackgroundColor(colors.MARRON_OSCURO);
	setTitle("MODIFICA EVENTO");
	if (allUsers.length > 0 ){
		setSearchEnabled(true);
	}

  // Aquí debes escribir la lógica para eliminar eventos
};

const handleRegisterButtonClick = async () => {
	setFormBackgroundColor(colors.CARD);
	setTitle("REGISTRA EVENTO");
  setSearchEnabled(false);
  // Aquí debes escribir la lógica para eliminar eventos
};

const handlePopupCloseError = () => {
      //setPayEliminarNo(false)
			setPopupOpenError(false);
			setPopupOpenYesorNo(false)
      		setIsLoading(false);
    };

	const cleanForm = async () => {

    await setUserData({
      nameEvent: "",
      subTitle: "",
      description: "",
      price: "",
      ticketType: "",
      id: new Date(),
      idEvento : new Date(),
      fechaFinEvento: fechaActual,
      fechaInicioVenta: new Date(),
      fechaFinVenta: new Date(),
      horaInicioEvento: "10:00",
      horaFinEvento: "07:00",
      horaInicioVenta: "10:00",
	  horaFinVenta: "22:00",
	  imgae1: "",
	  logo: ""

    });
	document.getElementById("nameEvent").value = "";
	document.getElementById("subTitle").value = "";
	document.getElementById("description").value = "";
	//document.getElementById("codigoAl").value = "";
	document.getElementById("price").value = "";
	// document.getElementById("logo").value = "";
	document.getElementById("ticketType").value = "";
	// if (title === 'REGISTRA EVENTO') {
	// 	document.getElementById("idEvento ").value = new Date();
	// }
  };

const handleChange = async (e) => {
  const { id, value } = e.target;
//console.log(id, value)
	if (title !== "REGISTRA EVENTO" && (id === "id" || id === "nameEvent")) {

     const user = allUsers.find((user) => user[id] === value);
     if (user) {
       var idEvento = (user.idEvento );
       var fechaInicioVenta = (user.fechaInicioVenta);
       var fechaFinVenta = (user.fechaFinVenta);
       var fechaFinEvento = (user.fechaFinEvento);
       // console.log(user.email, user.address, user.sexo, user.zona, user.dni, user.pr, user.phone, user.loginType, user.avatar);
		 await setUserData({
			 	nameEvent:user.nameEvent,
				idEvento : idEvento ,
				subTitle: user.subTitle,
				description: user.description,
				fechaInicioVenta: fechaInicioVenta,
				fechaFinVenta: fechaFinVenta,
				fechaFinEvento: fechaFinEvento,
				horaInicioEvento: user.horaInicioEvento,
			 	horaFinEvento: user.horaFinEvento,
				horaInicioVenta:user.horaInicioVenta,
				price: user.price,
				image1: "",
				horaFinVenta: user.horaFinVenta,
				logo: "",
				ticketType: user.ticketType,
				});


     }
	} else {

     await setUserData((prevUserData) => ({
       ...prevUserData,
       [id]: value,
	 }));

   }

	if (id === "idEvento" && title === "REGISTRA EVENTO") {
    // Convertir la fecha de string a objeto Date
    var fechaFinVentaVar = new Date(value);

    // Sumar un día a la fecha de idEvento
    var fechaFinEventoVar = new Date(value);
    fechaFinEventoVar.setDate(fechaFinEventoVar.getDate() + 1);

    // Formatear las fechas en el formato requerido (por ejemplo, "YYYY-MM-DD")
    var fechaFinVentaFormatted = fechaFinVentaVar.toISOString().split("T")[0];
    var fechaFinEventoFormatted = fechaFinEventoVar.toISOString().split("T")[0];

    await setUserData((prevUserData) => ({
      ...prevUserData,
      fechaFinVenta: fechaFinVentaFormatted,
      fechaFinEvento: fechaFinEventoFormatted,
    }));
  }


};


const handlePopupYesorNo = async () => {
	if (title === "ELIMINA EVENTO") {
		handleDeleteUser()
	}else{
	 	handleUpdateUser()
	}
    setPopupOpenYesorNo(true);
  };

const handleDeleteUser = async () => {
	try {

			setIsLoading(true);
		// Aquí puedes obtener el dni de donde lo tengas
		const body = {
			idEvento: moment(userData.idEvento).format("YYYY/MM/DD"),
			horaInicioEvento: userData.horaInicioEvento,
			}; ;

		const response = await fetch(
				"https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/deleteEventoClub",
				{
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(body), // Agrega el dni al cuerpo de la solicitud
				}
			);
			setIsLoading(false);
			if (!response.ok) {
				setMensaje(response.error)
				setButtonColor(colors.RED);
				setPopupOpenError(true);
				cleanForm()
			} else {
				setMensaje("Evento eliminado correctamente");
				setButtonColor(colors.GREEN);
				setPopupOpenError(true);
				loadAllUsers();
				cleanForm()
     		 }

	} catch (error) {
		console.error("Error al eliminar evento:", error.message);
	}
};

const handleUpdateUser = async () => {
	try {

	 const isValid = await validateData();
		if (!isValid) {
			return; // No ejecutar el resto de la lógica si la validación falla
		}
		setIsLoading(true);

		const body = {
				idEvento : moment(userData.idEvento ).format("YYYY/MM/DD"),
				horaInicioEvento: userData.horaInicioEvento,
				nameEvent: userData.nameEvent,
				subTitle: userData.subTitle,
				//codigoAl: userData.codigoAl,
				description: userData.description,
				fechaFinEvento: moment(userData.fechaFinEvento).format("YYYY/MM/DD"),
				fechaInicioVenta: moment(userData.fechaInicioVenta).format(
					"YYYY/MM/DD"
				),
				fechaFinVenta: moment(userData.fechaFinVenta).format("YYYY/MM/DD"),
			    horaFinEvento: userData.horaFinEvento,
				horaInicioVenta: userData.horaInicioVenta,
				horaFinVenta: userData.horaFinVenta,
				image1: userData.image1,
				logo: userData.logo,
				price: userData.price,
      			ticketType: userData.ticketType,
    };

	const response = await fetch(
			"https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/updateEventClub",
			{
				method: "POST",
				headers: {
				"Content-Type": "application/json",
				},
				body: JSON.stringify(body), // Agrega el dni al cuerpo de la solicitud
			}
			);
				setIsLoading(false);
			if (!response.ok) {
				setMensaje(response.error);
				setButtonColor(colors.RED)
				setPopupOpenError(true);
			} else {

				setMensaje("Evento modificado correctamente");
				setButtonColor(colors.GREEN);
				setPopupOpenError(true);
				loadAllUsers();
			}

	} catch (error) {
		console.error("Error al modificar evento:", error.message);
	}
	}



	const validateData = async () => {
    if ( (title !== 'ELIMINA EVENTO') && (
      userData.nameEvent === "" ||
      userData.description === "" ||
      userData.price === "")
    ) {
      setMensaje("Complete los datos");
      setPopupOpenError(true);
      return false;
	}
		// console.log('ACA FECHAS?', userData.idEvento , userData.fechaFinEvento, userData.fechaInicioVenta);
		if (title !== 'ELIMINA EVENTO') {
			// Verificar si idEvento es menor o igual a fechaFinEvento
			if (userData.idEvento >= userData.fechaFinEvento) {
				setMensaje(
					"La fecha del evento debe ser anterior o igual a la fecha de finalización del evento"
				);
				setPopupOpenError(true);
				return false;
			}

			// Verificar si fechaInicioVenta es menor que idEvento
			if (userData.fechaInicioVenta >= userData.idEvento) {
				setMensaje(
					"La fecha de inicio de venta debe ser anterior a la fecha del evento"
				);
				setPopupOpenError(true);
				return false;
			}

			// Verificar si fechaFinVenta está entre idEvento y fechaInicioVenta
			if (
				userData.fechaFinVenta <= userData.idEvento &&
				userData.fechaFinVenta < userData.fechaInicioVenta
			) {
				setMensaje(
					"La fecha de fin de venta debe estar entre la fecha del evento y la fecha de inicio de venta"
				);
				setPopupOpenError(true);
				return false;
			}
		}
    return true;
  };

  // Función para manejar el envío del formulario de registro
const handleSubmit = async (e) => {
try{
	const isValid = await validateData();
	if (!isValid) {
		return; // No ejecutar el resto de la lógica si la validación falla
	}
	if (title === "ELIMINA EVENTO") {
		setMensaje(
				"ELIMINA EVENTO: " + userData.nameEvent + " " + userData.subTitle + "?"
			);
		setPopupOpenYesorNo(true)
	} else if (title === "MODIFICA EVENTO") {
		setMensaje(
      			"MODIFICA EVENTO: " + userData.nameEvent + " " + userData.subTitle + "?"
		);
		setPopupOpenYesorNo(true);
	} else {
		setIsLoading(true);
			const body = {
					idEvento : moment(userData.idEvento ).format("YYYY/MM/DD"),
					horaInicioEvento: userData.horaInicioEvento,
					nameEvent: userData.nameEvent,
					subTitle: userData.subTitle,
					codigoAl: "CT",
					description: userData.description,
					fechaFinEvento: moment(userData.fechaFinEvento).format("YYYY/MM/DD"),
					fechaInicioVenta: moment(userData.fechaInicioVenta).format("YYYY/MM/DD"),
					fechaFinVenta: moment(userData.fechaFinVenta).format("YYYY/MM/DD"),
				    horaFinEvento: userData.horaFinEvento,
					horaInicioVenta: userData.horaInicioVenta,
					horaFinVenta: userData.horaFinVenta,
					image1: userData.image1,
					logo: userData.logo,
					price: userData.price,
					ticketType: userData.ticketType,
					};

		// eslint-disable-next-line
const response = await fetch(
  "https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/registerEventosClub",
  {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  }
);
		const data = await response.json();

		setIsLoading(false);
		if (data.error) {
			setMensaje(data.error);
			setButtonColor(colors.RED)
			setPopupOpenError(true);
			cleanForm();

		} else if (data.data) {
			setMensaje("ALTA EXITOSA");
			setButtonColor(colors.GREEN);
			setPopupOpenError(true);
			loadAllUsers();
			cleanForm()
		} else {
			setMensaje("Error de Servidor, Intente Nuevamente");
			setButtonColor(colors.RED);
			setPopupOpenError(true);
		}
	}
	} catch (error) {
		console.error("Error al modificar evento:", error.message);
	}
	};


        let formatted_fechaFinVenta = moment(userData.fechaFinVenta).format("YYYY-MM-DD");
		let formatted_Id = moment(userData.idEvento ).format("YYYY-MM-DD");
		let formatted_fechaFinEvento = moment(userData.fechaFinEvento).format('YYYY-MM-DD')
		let formatted_fechaInicioVenta = moment(userData.fechaInicioVenta).format('YYYY-MM-DD')

	//console.log(userData)


// console.log(
//   formatted_fechaFinVenta,
//   formatted_Id,
//   formatted_fechaFinEvento,
//   formatted_fechaInicioVenta
	// );
	const back = colors.backGroundImage;

return (
  <div style={{backgroundImage: `url(${back})`, backgroundSize: '100vw 100vh', height: mincel ? '100%' : '100vh', width: '100%'}}>
    {/* Encabezado */}
    <Header pageTitle={title} showBackButton={true} />
    {isLoading && (
      <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000}}>
        <Loader type="spinner-circle" bgColor={colors.WHITE} color={colors.BACKG_Color} size={100} />
      </div>
    )}
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', marginTop: '30px'}}>
      {/* Contenido */}
      <div style={{zIndex: 100, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{bottom: 0, padding: 10, backgroundSize: 'cover', width: mincel ? '100vw' : '90vw', flex: 1, marginTop: mincel ? 0 : '10px', flexDirection: 'row', zIndex: 990, maxWidth: '90vw', backgroundColor: formBackgroundColor, borderRadius: '50px'}}>
          {/* Botones de pestaña */}
          <div style={{position: 'absolute', marginTop: '-50px', marginLeft: '50px', display: 'flex'}}>
            <button onClick={handleRegisterButtonClick} className="button-pest" style={{backgroundColor: colors.CARD}}>
              AGREGAR
            </button>
            <button onClick={handleDeleteButtonClick} className="button-pest" style={{backgroundColor: colors.LIGHT_GREEN}}>
              ELIMINAR
            </button>
            <button onClick={handleChangeButtonClick} className="button-pest" style={{backgroundColor: colors.MARRON_OSCURO}}>
              MODIFICAR
            </button>
          </div>
          <div style={{marginTop: '20px', fontSize: '3em'}}>
            <p style={{color: 'white', fontSize: mincel ? '1.1em' : '0.8em', fontFamily: 'HelveticaNeue-Bold'}}>{title}</p>
          </div>

          <div style={{marginTop: '-20px', display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px'}}>
            <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
              <p className="textTerm">EVENTO</p>

              {searchEnabled ? (
                <>
                  <select style={{height: 40, width: mincel ? '90vw' : '30vw', marginTop: '10px'}} value={userData.id} onChange={handleChange} id="id">
                    <option value="">Seleccionar Evento</option>
                    {allUsers.map(user => (
                      <option key={user.id} value={user.id}>
                        {user.id}
                      </option>
                    ))}
                  </select>
                </>
              ) : (
                <>
                  <p className="textFormError">{userData.idEvento === '' ? 'Campo requerido' : ''}</p>
                  <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="date" required id="idEvento" placeholder="yyyy/mm/dd" onChange={handleChange} value={formatted_Id} />
                </>
              )}
            </div>
            <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
              <p className="textTerm">HORA INICIO EVENTO</p>
              {searchEnabled ? (
                <>
                  <p style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="text" required id="horaInicioEvento">
                    {userData.horaInicioEvento}{' '}
                  </p>
                </>
              ) : (
                <>
                  <p className="textFormError">{userData.horaInicioEvento === '' ? 'Campo requerido' : ''}</p>
                  <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="time" required id="horaInicioEvento" placeholder="00:00" onChange={handleChange} value={userData.horaInicioEvento} />
                </>
              )}
            </div>
          </div>
          <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px'}}>
            <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
              <p className="textTerm">Nombre del Evento</p>
              <p className="textFormError">{userData.nameEvent === '' ? 'Campo requerido' : ''}</p>
              {searchEnabled ? (
                <>
                  <select style={{height: 40, width: mincel ? '90vw' : '30vw', marginTop: '10px'}} value={userData.nameEvent} onChange={handleChange} required id="nameEvent">
                    <option value="">Seleccionar por Nombre</option>
                    {allUsers.map(user => (
                      <option key={user.id} value={user.nameEvent}>
                        {user.nameEvent}
                      </option>
                    ))}
                  </select>
                </>
              ) : (
                <>
                  <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="text" required id="nameEvent" placeholder="xxxxx" onChange={handleChange} value={userData.nameEvent} />
                </>
              )}
            </div>
            <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
              <p className="textTerm">Sub Título</p>
              <p className="textFormError">{userData.subTitle === '' ? 'Campo requerido' : ''}</p>
              <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="text" required id="subTitle" placeholder="xxxxx" onChange={handleChange} value={userData.subTitle} />
            </div>
          </div>
          <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
            <p className="textTerm">Descripción</p>
            <p className="textFormError">{userData.description === '' ? 'Campo requerido' : ''}</p>
            <input style={{height: 50, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="textArea" required id="description" placeholder="xxxxx" onChange={handleChange} value={userData.description} />
          </div>
          <div style={{backgroundColor: colors.MINICARD, padding: 30, borderRadius: '20px', marginTop: '20px', marginBottom: '20px'}}>
            <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px'}}>
              <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
                <p style={{marginTop: '10px'}} className="textTerm">
                  Fecha FIn de Evento
                </p>
                <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="date" required id="fechaFinEvento" placeholder="yyyy/mm/dd" onChange={handleChange} value={formatted_fechaFinEvento} />
              </div>
              <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
                <p style={{marginTop: '10px'}} className="textTerm">
                  Hora Fin de Evento
                </p>
                <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="time" required id="horaFinEvento" placeholder="00:00" onChange={handleChange} value={userData.horaFinEvento} />
              </div>
            </div>

            <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px'}}>
              <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
                <p style={{marginTop: '10px'}} className="textTerm">
                  Fecha Inicio de Ventas
                </p>
                <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="date" required id="fechaInicioVenta" placeholder="yyyy/mm/dd" onChange={handleChange} value={formatted_fechaInicioVenta} />
              </div>
              <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
                <p style={{marginTop: '10px'}} className="textTerm">
                  Hora Inicio de Ventas
                </p>
                <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="time" required id="horaInicioVenta" placeholder="00:00" onChange={handleChange} value={userData.horaInicioVenta} />
              </div>
            </div>

            <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px'}}>
              <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
                <p style={{marginTop: '10px'}} className="textTerm">
                  Fecha Fin de Ventas
                </p>
                <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="date" required id="fechaFinVenta" placeholder="yyyy/mm/dd" onChange={handleChange} value={formatted_fechaFinVenta} />
              </div>
              <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
                <p style={{marginTop: '10px'}} className="textTerm">
                  Hora Fin de Ventas
                </p>
                <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="time" required id="horaFinVenta" placeholder="00:00" onChange={handleChange} value={userData.horaFinVenta} />
              </div>
            </div>
          </div>

          <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px'}}>
            <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
              <p style={{marginTop: '10px'}} className="textTerm">
                Imagen Evento
              </p>
              <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="file" required id="image1" placeholder="xxxxx" onChange={handleChange} value={userData.image1} />
            </div>
            <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
              <p style={{marginTop: '10px'}} className="textTerm">
                Logo Evento
              </p>
              <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="file" required id="logo" placeholder="xxxxx" onChange={handleChange} value={userData.logo} />
            </div>
          </div>

          <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px'}}>
            <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
              <p style={{marginTop: '10px'}} className="textTerm">
                Tipo de Evento
              </p>
              <div style={{marginLeft: '20px'}}>
                <input className="radio_common" type="radio" id="ticketType" name="ticketType" value="10" onChange={handleChange} checked={userData.ticketType === '10'} />
                <label className="textTerm" htmlFor="10">
                  VIP
                </label>
              </div>
              <div style={{marginLeft: '20px'}}>
                <input className="radio_common" type="radio" id="ticketType" name="ticketType" value="6" onChange={handleChange} checked={userData.ticketType === '6'} />
                <label className="textTerm" htmlFor="6">
                  Eventos
                </label>
              </div>
            </div>
            <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
              <p style={{marginTop: '10px'}} className="textTerm">
                Precio
              </p>
              <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="number" required id="price" placeholder="xxxxx" onChange={handleChange} value={userData.price} />
            </div>
          </div>
        </div>
      </div>

      <div style={{marginTop: '50px', alignItems: 'center', display: 'flex', justifyContent: 'center', marginBottom: '30px'}}>
        <div style={{textAlign: 'center'}}>
          <button className="button-common" onClick={handleSubmit} type="submit" style={{padding: 0, cursor: 'pointer', border: 'none'}}>
            {title === 'REGISTRA EVENTO' ? 'REGISTRAR' : title === 'ELIMINA EVENTO' ? 'ELIMINAR' : 'MODIFICAR'}
          </button>
        </div>
      </div>

      {popupOpenError && (
        <div style={{zIndex: 999, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <div style={{width: '80%', backgroundColor: buttonColor, padding: '20px',borderRadius: 40, maxWidth: '300px', height: 250}}>
            <p style={{fontFamily: 'Como-Bold', marginTop: '20%', color: 'white', fontSize: '1.5em', textAlign: 'center'}}>{mensaje}</p>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '20%'}}>
              <button className="button-mensaje" style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em'}} onClick={handlePopupCloseError}>
                OK
              </button>
            </div>
          </div>
        </div>
      )}

      {popupOpenYesorNo && (
        <div style={{zIndex: 999, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <div style={{width: '80%', backgroundColor: buttonColor, padding: '20px',borderRadius: 40, maxWidth: '300px', height: 250}}>
            <p style={{fontFamily: 'Como-Bold', marginTop: '20%', color: 'white', fontSize: '1.5em', textAlign: 'center'}}>{mensaje}</p>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '20%'}}>
              <button
                className="button-mensaje"
                style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em'}}
                onClick={() => {
                  handlePopupYesorNo();
                  handlePopupCloseError();
                }}>
                SI
              </button>
              <button
                className="button-mensaje"
                style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em', marginLeft: '10px'}}
                onClick={() => {
                  handlePopupCloseError();
                }}>
                NO
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
);
};

export default RegisterEvents;
