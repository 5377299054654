import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import backgroundImage from '../../assets/imgs/FONDOCATCH.png';
import {colors} from '../utils/index';
import Header from './Header';
const HomeView = ({pageTitle}) => {
  const [selectedMenu, setSelectedMenu] = useState(null);

  const handleMenuClick = menu => {
    setSelectedMenu(menu === selectedMenu ? null : menu);
  };
  const mincel = window.innerWidth < 500;
  const renderSubMenu = subMenuItems => (
    <ul
      style={{
        listStyleType: 'none',
        textAlign: 'center',
        padding: 0,
        margin: 0,
      }}>
      {subMenuItems.map((item, index) => (
        <li key={index} style={{marginBottom: '10px'}}>
          <Link to={item.link}>
            <button
              style={{
                cursor: 'pointer',
                border: 'none',
                height: '40px',
                width: '200px',
                borderRadius: '50px',
                color: colors.WHITE,
                backgroundColor: selectedMenu === item.label ? colors.backbeige : colors.MARRON_OSCURO,
                textAlign: 'center', // Centra el texto dentro del botón
              }}>
              {item.label}
            </button>
          </Link>
        </li>
      ))}
    </ul>
  );

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: '100vw 100vh',
        height: '100vh',
        width: '100vw',
      }}>
      {/* Encabezado */}
      <Header pageTitle={'HOME'} />

      {/* Mobile Menu */}
      <div style={{textAlign: 'center', marginTop: '50px'}}>
        <ul
          style={{
            listStyleType: 'none',
            textAlign: 'center',
            padding: 0,
            margin: 0,
          }}>
          {/* Menu items */}
          {menuItems.map((menuItem, index) => (
            <li key={index}>
              <button
                onClick={() => handleMenuClick(menuItem.label)}
                style={{
                  backgroundColor: selectedMenu === menuItem.label ? colors.MARRON_OSCURO : colors.backbeige,
                  border: 'none',
                  color: colors.WHITE,
                  cursor: 'pointer',
                  width: '250px',
                  height: mincel ? '40px' : '50px',
                  borderRadius: '50px',
                  fontSize: mincel ? '1em' : '1.5em',
                  marginBottom: '10px',
                  textAlign: 'center', // Centra el texto dentro del botón
                }}>
                {menuItem.label}
              </button>
              {/* Submenu */}
              {selectedMenu === menuItem.label && renderSubMenu(menuItem.subMenu)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default HomeView;

// Definir los elementos del menú y submenú
const menuItems = [
  {
    label: 'REGISTROS',
    subMenu: [
      {label: 'USERS', link: '/crudUsers'},
     // {label: 'EVENTOS', link: '/crudEvents'},
     // {label: 'NOTIFICACIONES', link: '/CrudNotifications'},
      {label: 'CATEGORIAS VOUCHERS', link: '/crudVouchersCat'},
      {label: 'VOUCHERS', link: '/registerVouchers'},
      {label: 'CATEGORIAS BENEFICIOS', link: '/crudCategories'},
      {label: 'BENEFICIOS', link: '/registerBenefits'},
    ],
  },
  {
    label: 'REPORTES',
    subMenu: [
      {label: 'REPORTE USERS', link: '/reportUsers'},
      {label: 'REPORTE BENEFICIOS', link: '/reportBeneficios'},
      {label: 'REPORTE EVOLUTION', link: '/reportEvolution'},
      {label: 'REPORTE REFERIDOS', link: '/reportReferidos'},
      {label: 'REPORTE VOUCHERS', link: '/reportVouchersUser'},
      {label: 'REPORTE MOVIMIENTOS', link: '/reportMovimientos'},
      {label: 'REPORTE MOVIMIENTOS POR USUARIO', link: '/reportUserMovements'},
    ],
  },
  {
    label: 'CHARTS',
    subMenu: [
      {label: 'MOVIMIENTOS', link: '#'},
      {label: 'BENEFICIOS', link: '#'},
    ],
  },
];
