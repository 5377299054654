import React, {useState, useEffect, useMemo, useCallback} from 'react';
import {useTable, useFilters, useGlobalFilter, usePagination} from 'react-table';
import * as XLSX from 'xlsx';
import Loader from 'react-js-loader';
import Header from './Header';
import {colors} from '../utils/index';
import './Main.css';
import moment from 'moment';
import CustomButton from '../utils/CustomButton';
const CrudEvents = () => {
  const [isLoading, setIsLoading] = useState(false);
  const mincel = window.innerWidth < 900;
  const [popupOpenError, setPopupOpenError] = useState(false);
  const [popupOpenYesorNo, setPopupOpenYesorNo] = useState(false);
  const [mensaje, setMensaje] = useState('');
  const [selectAllHeader, setSelectAllHeader] = useState(false);
  const [buttonColor, setButtonColor] = useState(colors.RED);
  const [title, setTitle] = useState('CONSULTA EVENTOS');
  const [titleExcel, setTitleExcel] = useState('');
  const [responseData, setResponseData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [eventsToDelete, setEventsToDelete] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [formBackgroundColor, setFormBackgroundColor] = useState(colors.MARRON_OSCURO);
  const [selectedRow, setSelectedRow] = useState({
    nameEvent: '',
    subTitle: '',
    description: '',
    price: '',
    ticketType: '10',
    idEvento: '',
    fechaFinEvento: '',
    fechaInicioVenta: '',
    fechaFinVenta: '',
    horaInicioEvento: '10:00',
    horaFinEvento: '07:00',
    horaInicioVenta: '10:00',
    horaFinVenta: '22:00',
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState(null);

  const defaultColumn = useMemo(
    () => ({
      Filter: ({column}) => {
        const {filterValue, setFilter} = column;
        return (
          <input
            value={filterValue || ''}
            onChange={e => setFilter(e.target.value)}
            placeholder={`Filtrar por ${column.Header}`}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                const filterText = e.target.value;
                setFilter(filterText);
              }
            }}
          />
        );
      },
      filter: (rows, idEvento, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[idEvento];
          return rowValue.toLowerCase().startsWith(filterValue.toLowerCase());
        });
      },
    }),
    [],
  );

  useEffect(() => {
    const loadAllEvents = async () => {
      try {
        setIsLoading(true);

        const body = {tipoConsulta: 'all'};
        const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/consultEventos', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        });
        const data = await response.json();

		  setIsLoading(false);

		  if (data.error) {
		  setResponseData( []);
          setFilteredRecords( []);
          setMensaje('SIN EVENTOS');
          setTitle('REGISTRA EVENTOS');
          setPopupOpenError(true);
        } else {
          setResponseData(data || []);
          setFilteredRecords(data || []);
        }
      } catch (error) {
        console.error('Error al cargar los eventos:', error);
      }
    };

    loadAllEvents();
  }, []);

  const handleSelectEvent = useCallback(
    eventId => {
      if (eventsToDelete.includes(eventId)) {
        setEventsToDelete(eventsToDelete.filter(idEvento => idEvento !== eventId));
      } else {
        setEventsToDelete([...eventsToDelete, eventId]);
      }
    },
    [eventsToDelete],
  );

  const handleSelectAll = useCallback(() => {
    const newValue = !selectAllHeader;
    setSelectAllHeader(newValue);
    setSelectAll(newValue);
    const allEventIds = responseData.map(event => event.idEvento);
    setEventsToDelete(newValue ? allEventIds : []);
  }, [selectAllHeader, responseData]);

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div style={{marginTop: '-20px'}}>
            <input type="checkbox" checked={selectAll} onChange={() => handleSelectAll()} />
            {titleExcel}
          </div>
        ),
        disableFilters: true,
        Cell: ({row}) => <input type="checkbox" checked={selectAllHeader || selectAll || eventsToDelete.includes(row.original.idEvento)} onChange={() => handleSelectEvent(row.original.idEvento)} />,
        accessor: 'checkbox',
      },
      {Header: 'NOMBRE EVENTO', accessor: 'nameEvent', style: {textAlign: 'left'}},
      {Header: 'SUBTITULO', accessor: 'subTitle', style: {textAlign: 'left'}},
      {Header: 'DESCRIPCIÓN', accessor: 'description', style: {textAlign: 'left'}},
      {Header: 'PRECIO', accessor: 'price', style: {textAlign: 'left'}},
      {Header: 'TIPO TICKET', accessor: 'ticketType', style: {textAlign: 'left'}},
      {Header: 'FECHA EVENTO', accessor: 'idEvento', style: {textAlign: 'center'}},
      {Header: 'FECHA FIN EVENTO', accessor: 'fechaFinEvento', style: {textAlign: 'center'}},
      {Header: 'FECHA INICIO VENTA', accessor: 'fechaInicioVenta', style: {textAlign: 'center'}},
      {Header: 'FECHA FIN VENTA', accessor: 'fechaFinVenta', style: {textAlign: 'center'}},
      {Header: 'HORA INICIO EVENTO', accessor: 'horaInicioEvento', style: {textAlign: 'center'}},
      {Header: 'HORA FIN EVENTO', accessor: 'horaFinEvento', style: {textAlign: 'center'}},
      {Header: 'HORA INICIO VENTA', accessor: 'horaInicioVenta', style: {textAlign: 'center'}},
      {Header: 'HORA FIN VENTA', accessor: 'horaFinVenta', style: {textAlign: 'center'}},
    ],
    [eventsToDelete, handleSelectEvent, handleSelectAll, selectAll, selectAllHeader, titleExcel],
  );

  const tableData = useMemo(() => {
    return {
      columns,
      data: Array.isArray(responseData) ? responseData : [],
      defaultColumn,
    };
  }, [responseData, columns, defaultColumn]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {pageIndex, pageSize},
  } = useTable(tableData, useFilters, useGlobalFilter, usePagination);

  useEffect(() => {
    if (title === 'ELIMINA EVENTOS') {
      setFilteredRecords(rows.map(row => row.original).filter(record => eventsToDelete.includes(record.idEvento)));
    } else {
      setFilteredRecords(rows.map(row => row.original));
    }
  }, [rows, eventsToDelete, title, setPageSize]);

  const handleExport = () => {
    if (filteredRecords !== null && filteredRecords !== 'SIN MOVIMIENTOS') {
      const filteredData = filteredRecords.length > 0 ? filteredRecords : rows.map(row => row.original);
      if (filteredData.length > 0) {
        exportToExcel(filteredData);
      } else {
        console.log('No hay datos para exportar');
      }
    }
  };

  const exportToExcel = filteredRows => {
    const exportData = filteredRows.map(row => ({
      FECHA_EVENTO: row.idEvento,
      NOMBRE_EVENTO: row.nameEvent,
      SUBTITULO: row.subTitle,
      DESCRIPCIÓN: row.description,
      PRECIO: row.price,
      TIPO_TICKET: row.ticketType,
      FECHA_FIN_EVENTO: row.fechaFinEvento,
      FECHA_INICIO_VENTA: row.fechaInicioVenta,
      FECHA_FIN_VENTA: row.fechaFinVenta,
      HORA_INICIO_EVENTO: row.horaInicioEvento,
      HORA_FIN_EVENTO: row.horaFinEvento,
      HORA_INICIO_VENTA: row.horaInicioVenta,
      HORA_FIN_VENTA: row.horaFinVenta,
      IMAGEN: row.image1,
      LOGO: row.logo,
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
    XLSX.writeFile(workbook, 'report.xlsx');
  };

  if (localStorage.dni === '') {
    localStorage.clear();
    document.location.href = '/Login';
  }

  const handleDeleteEvent = async () => {
    try {
      setIsLoading(true);
      var body = [];
      for (const record of filteredRecords) {
        var bodyItems = {};
        bodyItems['idEvento'] = record.idEvento;
        bodyItems['horaInicioEvento'] = record.horaInicioEvento;
        body.push(bodyItems);
      }

      const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/deleteEventoClub', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (Array.isArray(data) && data.length > 0) {
        const messageObject = data[0];

        if (messageObject.status === 'error') {
          setButtonColor(colors.RED);
          setMensaje('Error en la eliminación, inténtelo de nuevo.');
          setPopupOpenError(true);
        } else {
          setResponseData(prevData => {
            const newData = prevData.filter(evento => !eventsToDelete.includes(evento.idEvento));
            return newData;
          });
          setFilteredRecords(prevData => prevData.filter(evento => !eventsToDelete.includes(evento.idEvento)));
          setMensaje('Eliminación exitosa');
          handleClear();
          setButtonColor(colors.GREEN);
          setPopupOpenError(true);
        }
      }
      setIsLoading(false);
      setEventsToDelete([]);
    } catch (error) {
      console.error('Error al cargar los eventos:', error);
      setIsLoading(false);
    }
  };

  const handlePopupCloseError = () => {
    setPopupOpenError(false);
    setPopupOpenYesorNo(false);
  };

  const handlePopupYesorNo = async () => {
    if (title === 'ELIMINA EVENTOS') {
      handleDeleteEvent();
    } else {
      handleExport();
    }
  };

  const handleSubmit = async () => {
	  try {
		  if (title === 'ELIMINA EVENTOS') {
			setPopupOpenYesorNo(true)
		}
      const isValid = await validateData();
      if (!isValid) {
        return; // No ejecutar el resto de la lógica si la validación falla
      }

      const existingEvent = responseData.find(event => event.idEvento === selectedRow.idEvento);

      if (existingEvent) {
        // Update existing event
        await handleUpdateEvent();
      } else {
        // Register new event
        await handleRegister();
      }
    } catch (error) {
      console.error('Error al procesar el formulario:', error.message);
    }
  };

  const handleAtualizapup = async () => {
    setFormBackgroundColor(colors.MARRON_OSCURO);
    setTitle('CONSULTA EVENTOS');
    setTitleExcel('');
    setSelectedRow({
      nameEvent: '',
      subTitle: '',
      description: '',
      price: '',
      ticketType: '',
      idEvento: '',
      fechaFinEvento: '',
      fechaInicioVenta: '',
      fechaFinVenta: '',
      horaInicioEvento: '10:00',
      horaFinEvento: '07:00',
      horaInicioVenta: '10:00',
      horaFinVenta: '22:00',
    });
    setSelectedImage(null);
    setSelectedLogo(null);
  };

  const handleElimina = async () => {
    setFormBackgroundColor(colors.LIGHT_GREEN);
    setTitle('ELIMINA EVENTOS');
    setTitleExcel('TODOS');
    setSelectedRow({
      nameEvent: '',
      subTitle: '',
      description: '',
      price: '',
      ticketType: '',
      idEvento: '',
      fechaFinEvento: '',
      fechaInicioVenta: '',
      fechaFinVenta: '',
      horaInicioEvento: '10:00',
      horaFinEvento: '07:00',
      horaInicioVenta: '10:00',
      horaFinVenta: '22:00',
    });
    setSelectedImage(null);
    setSelectedLogo(null);
  };

  const handleClear = () => {
    setSelectedRow({
      nameEvent: '',
      subTitle: '',
      description: '',
      price: '',
      ticketType: '',
      idEvento: '',
      fechaFinEvento: '',
      fechaInicioVenta: '',
      fechaFinVenta: '',
      horaInicioEvento: '10:00',
      horaFinEvento: '07:00',
      horaInicioVenta: '10:00',
      horaFinVenta: '22:00',
    });
    setSelectedImage(null);
    setSelectedLogo(null);
  };

  const handleRowClick = row => {
    setSelectedRow({
      nameEvent: row.nameEvent || '',
      subTitle: row.subTitle || '',
      description: row.description || '',
      price: row.price || '',
      ticketType: row.ticketType || '',
      idEvento: row.idEvento ? moment(row.idEvento).format('YYYY-MM-DD') : '',
      fechaFinEvento: row.fechaFinEvento ? moment(row.fechaFinEvento).format('YYYY-MM-DD') : '',
      fechaInicioVenta: row.fechaInicioVenta ? moment(row.fechaInicioVenta).format('YYYY-MM-DD') : '',
      fechaFinVenta: row.fechaFinVenta ? moment(row.fechaFinVenta).format('YYYY-MM-DD') : '',
      horaInicioEvento: row.horaInicioEvento || '10:00',
      horaFinEvento: row.horaFinEvento || '07:00',
      horaInicioVenta: row.horaInicioVenta || '10:00',
      horaFinVenta: row.horaFinVenta || '22:00',
    });
    setSelectedImage(row.image1 || null);
    setSelectedLogo(row.logo || null);
  };
  // eslint-disable-next-line
  const handleImageChange = e => {
    setSelectedImage(e.target.files[0]);
  };
  // eslint-disable-next-line
  const handleLogoChange = e => {
    setSelectedLogo(e.target.files[0]);
  };

  const handleRegister = async e => {
    try {
      const isValid = await validateData();
      if (!isValid) {
        return; // No ejecutar el resto de la lógica si la validación falla
      }

      setIsLoading(true);
      const body = {
        idEvento: moment(selectedRow.idEvento).format('YYYY/MM/DD'),
        horaInicioEvento: selectedRow.horaInicioEvento,
        nameEvent: selectedRow.nameEvent,
        subTitle: selectedRow.subTitle,
        codigoAl: 'CT',
        description: selectedRow.description,
        fechaFinEvento: moment(selectedRow.fechaFinEvento).format('YYYY/MM/DD'),
        fechaInicioVenta: moment(selectedRow.fechaInicioVenta).format('YYYY/MM/DD'),
        fechaFinVenta: moment(selectedRow.fechaFinVenta).format('YYYY/MM/DD'),
        horaFinEvento: selectedRow.horaFinEvento,
        horaInicioVenta: selectedRow.horaInicioVenta,
        horaFinVenta: selectedRow.horaFinVenta,
        image1: selectedImage,
        logo: selectedLogo,
        price: selectedRow.price,
        ticketType: selectedRow.ticketType,
      };

      const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/registerEventosClub', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();

      setIsLoading(false);
      if (data.error) {
        setMensaje(data.error);
        setButtonColor(colors.RED);
        setPopupOpenError(true);
      } else if (data.data) {
        setResponseData(prevData => [...prevData, selectedRow]);
        setFilteredRecords(prevData => [...prevData, selectedRow]);
        setMensaje('ALTA EXITOSA');
        setButtonColor(colors.GREEN);
        setPopupOpenError(true);
        handleClear();
      } else {
        setMensaje('Error de Servidor, Intente Nuevamente');
        setButtonColor(colors.RED);
        setPopupOpenError(true);
      }
    } catch (error) {
      console.error('Error al modificar evento:', error.message);
    }
  };

  const handleUpdateEvent = async () => {
    try {
      const isValid = await validateData();
      if (!isValid) {
        return; // No ejecutar el resto de la lógica si la validación falla
      }
      setIsLoading(true);

      const body = {
        idEvento: moment(selectedRow.idEvento).format('YYYY/MM/DD'),
        horaInicioEvento: selectedRow.horaInicioEvento,
        nameEvent: selectedRow.nameEvent,
        subTitle: selectedRow.subTitle,
        codigoAl: 'CT',
        description: selectedRow.description,
        fechaFinEvento: moment(selectedRow.fechaFinEvento).format('YYYY/MM/DD'),
        fechaInicioVenta: moment(selectedRow.fechaInicioVenta).format('YYYY/MM/DD'),
        fechaFinVenta: moment(selectedRow.fechaFinVenta).format('YYYY/MM/DD'),
        horaFinEvento: selectedRow.horaFinEvento,
        horaInicioVenta: selectedRow.horaInicioVenta,
        horaFinVenta: selectedRow.horaFinVenta,
        image1: selectedImage,
        logo: selectedLogo,
        price: selectedRow.price,
        ticketType: selectedRow.ticketType,
      };

      const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/updateEventClub', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      setIsLoading(false);

      if (!response.ok) {
        const errorData = await response.json();
        setMensaje(errorData.error);
        setButtonColor(colors.RED);
        setPopupOpenError(true);
      } else {
        setResponseData(prevData => prevData.map(event => (event.idEvento === selectedRow.idEvento ? selectedRow : event)));
        setFilteredRecords(prevData => prevData.map(event => (event.idEvento === selectedRow.idEvento ? selectedRow : event)));
        setMensaje('Evento modificado correctamente');
        handleClear();
        setButtonColor(colors.GREEN);
        setPopupOpenError(true);
      }
    } catch (error) {
      console.error('Error al modificar evento:', error.message);
      setIsLoading(false);
    }
  };

	const validateData = async () => {
		if (title === 'ELIMINA EVENTOS') {
		  return
	  }
    if (selectedRow.nameEvent === '' || selectedRow.subTitle === '' || selectedRow.description === '' || selectedRow.price === '') {
      setMensaje('Complete los datos');
      setPopupOpenError(true);
      return false;
    }
    return true;
  };

  const handleDateChange = e => {
    const newDate = moment(e.target.value);
    setSelectedRow({
      ...selectedRow,
      idEvento: newDate.format('YYYY-MM-DD'),
      fechaFinEvento: newDate.add(1, 'days').format('YYYY-MM-DD'),
      fechaInicioVenta: moment().format('YYYY-MM-DD'),
      fechaFinVenta: newDate.format('YYYY-MM-DD'),
    });
  };

  const back = colors.backGroundImage;

  return (
    <div style={{backgroundImage: `url(${back})`, backgroundSize: '100vw 100vh', height: mincel ? '100%' : '100vh', width: '100%'}}>
      <Header pageTitle={title} showBackButton={true} />
      {isLoading && (
        <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000}}>
          <Loader type="spinner-circle" bgColor={colors.WHITE} color={colors.BACKG_Color} size={100} />
        </div>
      )}
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', marginTop: '30px'}}>
        <div style={{zIndex: 100, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{bottom: 0, padding: 10, backgroundSize: 'cover', width: mincel ? '100vw' : '90vw', flex: 1, marginTop: mincel ? 0 : '10px', flexDirection: 'row', zIndex: 990, maxWidth: '90vw', backgroundColor: formBackgroundColor, borderRadius: '50px'}}>
            <div style={{position: 'absolute', marginTop: '-50px', marginLeft: '50px', display: 'flex'}}>
              <button onClick={handleAtualizapup} className="button-pest" style={{backgroundColor: colors.MARRON_OSCURO}}>
                AGREGAR
              </button>
              <button onClick={handleElimina} className="button-pest" style={{backgroundColor: colors.LIGHT_GREEN}}>
                ELIMINAR
              </button>
              <button onClick={handleClear} className="button-pest" style={{backgroundColor: colors.RED}}>
                LIMPIAR
              </button>
            </div>
            {selectedRow && title !== 'ELIMINA EVENTOS' && (
              <div style={{marginTop: '20px', padding: '20px', backgroundColor: colors.MINICARD, borderRadius: '20px', boxShadow: '0 0 10px rgba(0,0,0,0.2)', width: '90%', margin: '0 auto'}}>
                <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px'}}>
                  <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
                    <p style={{marginTop: '10px'}} className="textTerm">
                      Fecha Evento
                    </p>
                    <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="date" required id="idEvento" placeholder="yyyy/mm/dd" onChange={handleDateChange} value={selectedRow.idEvento || ''} />
                  </div>
                  <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
                    <p style={{marginTop: '10px'}} className="textTerm">
                      Hora Inicio Evento
                    </p>
                    <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="time" required id="horaInicioEvento" placeholder="00:00" onChange={e => setSelectedRow({...selectedRow, horaInicioEvento: e.target.value})} value={selectedRow.horaInicioEvento || ''} />
                  </div>
                </div>
                <div style={{marginTop: '10px', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column', width: '50%'}}>
                  <label className="textTerm">NOMBRE EVENTO</label>
                  <p className="textFormError">{selectedRow.nameEvent === '' ? 'Campo requerido' : ''}</p>
                  <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="text" required id="nameEvent" placeholder="Nombre Evento" value={selectedRow.nameEvent || ''} onChange={e => setSelectedRow({...selectedRow, nameEvent: e.target.value})} />
                </div>
                <div style={{marginTop: '5px', display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px'}}>
                  <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                    <label className="textTerm">SUBTITULO</label>
                    <p className="textFormError">{selectedRow.subTitle === '' ? 'Campo requerido' : ''}</p>
                    <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="text" required id="subTitle" placeholder="Subtitulo" value={selectedRow.subTitle || ''} onChange={e => setSelectedRow({...selectedRow, subTitle: e.target.value})} />
                  </div>
                  <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column', width: mincel ? '90vw' : '30vw'}}>
                    <label className="textTerm">DESCRIPCIÓN</label>
                    <p className="textFormError">{selectedRow.description === '' ? 'Campo requerido' : ''}</p>
                    <input style={{height: 50, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="textarea" required id="description" placeholder="Descripción" value={selectedRow.description || ''} onChange={e => setSelectedRow({...selectedRow, description: e.target.value})} />
                  </div>
                </div>
                <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px', flexDirection: mincel ? 'column' : 'row'}}>
                  <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                    <p className="textTerm">PRECIO</p>
                    <p className="textFormError">{selectedRow.price === '' ? 'Campo requerido' : ''}</p>
                    <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="number" required id="price" placeholder="Precio" value={selectedRow.price || ''} onChange={e => setSelectedRow({...selectedRow, price: e.target.value})} />
                  </div>
                  <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                    <p className="textTerm">TIPO DE TICKET</p>
                    <p className="textFormError">{selectedRow.ticketType === '' ? 'Campo requerido' : ''}</p>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      <label>
                        <input type="radio" name="ticketType" value="10" checked={selectedRow.ticketType === '10'} onChange={e => setSelectedRow({...selectedRow, ticketType: e.target.value})} />
                        VIP
                      </label>
                      <label>
                        <input type="radio" name="ticketType" value="6" checked={selectedRow.ticketType === '6'} onChange={e => setSelectedRow({...selectedRow, ticketType: e.target.value})} />
                        Evento
                      </label>
                    </div>
                  </div>
                </div>
                <div style={{backgroundColor: colors.MINICARD, padding: 30, borderRadius: '20px', marginTop: '20px', marginBottom: '20px'}}>
                  <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px'}}>
                    <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
                      <p style={{marginTop: '10px'}} className="textTerm">
                        Fecha Fin de Evento
                      </p>
                      <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="date" required id="fechaFinEvento" placeholder="yyyy/mm/dd" onChange={e => setSelectedRow({...selectedRow, fechaFinEvento: e.target.value})} value={selectedRow.fechaFinEvento || ''} />
                    </div>
                    <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
                      <p style={{marginTop: '10px'}} className="textTerm">
                        Hora Fin de Evento
                      </p>
                      <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="time" required id="horaFinEvento" placeholder="00:00" onChange={e => setSelectedRow({...selectedRow, horaFinEvento: e.target.value})} value={selectedRow.horaFinEvento || ''} />
                    </div>
                  </div>
                  <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px'}}>
                    <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
                      <p style={{marginTop: '10px'}} className="textTerm">
                        Fecha Inicio de Ventas
                      </p>
                      <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="date" required id="fechaInicioVenta" placeholder="yyyy/mm/dd" onChange={e => setSelectedRow({...selectedRow, fechaInicioVenta: e.target.value})} value={selectedRow.fechaInicioVenta || ''} />
                    </div>
                    <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
                      <p style={{marginTop: '10px'}} className="textTerm">
                        Hora Inicio de Ventas
                      </p>
                      <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="time" required id="horaInicioVenta" placeholder="00:00" onChange={e => setSelectedRow({...selectedRow, horaInicioVenta: e.target.value})} value={selectedRow.horaInicioVenta || ''} />
                    </div>
                  </div>
                  <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px'}}>
                    <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
                      <p style={{marginTop: '10px'}} className="textTerm">
                        Fecha Fin de Ventas
                      </p>
                      <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="date" required id="fechaFinVenta" placeholder="yyyy/mm/dd" onChange={e => setSelectedRow({...selectedRow, fechaFinVenta: e.target.value})} value={selectedRow.fechaFinVenta || ''} />
                    </div>
                    <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
                      <p style={{marginTop: '10px'}} className="textTerm">
                        Hora Fin de Ventas
                      </p>
                      <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="time" required id="horaFinVenta" placeholder="00:00" onChange={e => setSelectedRow({...selectedRow, horaFinVenta: e.target.value})} value={selectedRow.horaFinVenta || ''} />
                    </div>
                  </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '10px'}}>{title === 'ELIMINA EVENTOS' ? <></> : <CustomButton onPress={handleSubmit} title="AGREGAR EVENTO" color={colors.MARRON_OSCURO} fontSize={18} buttonWidth={200} />}</div>
              </div>
            )}
            <div style={{marginTop: '20px'}}>
              <div style={{color: '#fff', display: 'grid', alignItems: 'center', backgroundColor: formBackgroundColor, padding: 20, borderRadius: 20, maxHeight: '50vh', overflow: 'auto'}}>
                <table {...getTableProps()} style={{maxWidth: '100%', marginTop: '2%'}}>
                  <thead>
                    {headerGroups.map(headerGroup => {
                      const {key, ...restHeaderGroupProps} = headerGroup.getHeaderGroupProps();
                      return (
                        <tr key={key} {...restHeaderGroupProps}>
                          {headerGroup.headers.map(column => {
                            const {key, ...restColumn} = column.getHeaderProps();
                            return (
                              <th key={key} {...restColumn}>
                                {column.render('Header')}
                                <div style={{width: '100%'}}>{column.canFilter ? column.render('Filter') : null}</div>
                              </th>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </thead>
                  <tbody {...getTableBodyProps}>
                    {page.map(row => {
                      prepareRow(row);
                      const {key, ...restRowProps} = row.getRowProps();
                      return (
                        <tr key={key} {...restRowProps} onClick={() => handleRowClick(row.original)}>
                          {row.cells.map(cell => {
                            const {key, ...restCellProps} = cell.getCellProps();
                            return (
                              <td key={key} {...restCellProps} style={cell.column.style}>
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="pagination">
                  <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                  </button>
                  <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                  </button>
                  <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                  </button>
                  <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>
                    {'>>'}
                  </button>
                  <span>
                    Página{' '}
                    <strong>
                      {pageIndex + 1} de {pageOptions.length}
                    </strong>
                  </span>
                  <select
                    value={pageSize}
                    onChange={e => {
                      setPageSize(Number(e.target.value));
                    }}>
                    {[10, 20, 30, 40, 50].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Mostrar {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{marginTop: '35px', alignItems: 'center', display: 'flex', justifyContent: 'flex-start', marginBottom: '10px'}}>
                  <div style={{textAlign: 'center'}}>
                    <CustomButton
                      onPress={() => {
                        if (title === 'CONSULTA EVENTOS') {
                          handleExport();
                        } else if (title === 'ELIMINA EVENTOS') {
                          setPopupOpenYesorNo(true);
                          setMensaje('¿ELIMINAR REGISTRO?');
                        }
                      }}
                      title={title === 'CONSULTA EVENTOS' || title === 'REGISTRA EVENTOS' ? 'EXPORTAR XLS' : 'ELIMINAR'}
                      color={colors.MARRON_OSCURO} // Ajusta el color según sea necesario
                      fontSize={18} // Ajusta el tamaño de la fuente si es necesario
                      buttonWidth={200} // Ajusta el ancho del botón si es necesario
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {popupOpenError && (
          <div style={{zIndex: 999, borderRadius: 30, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{width: '80%', backgroundColor: buttonColor, padding: '20px', borderRadius: 40, maxWidth: '300px', height: 250}}>
              <p style={{fontFamily: 'Como-Bold', marginTop: '20%', color: 'white', fontSize: '1.5em', textAlign: 'center'}}>{mensaje}</p>
              <div style={{display: 'flex', justifyContent: 'center', marginTop: '20%'}}>
                <button className="button-mensaje" style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em'}} onClick={handlePopupCloseError}>
                  OK
                </button>
              </div>
            </div>
          </div>
        )}
        {popupOpenYesorNo && (
          <div style={{zIndex: 999, borderRadius: 30, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{backgroundColor: buttonColor, padding: '20px', borderRadius: 40, width: '300px', height: '250px'}}>
              <p style={{fontFamily: 'Como-Bold', marginTop: '20%', color: 'white', fontSize: '1.5em', textAlign: 'center'}}>{mensaje}</p>
              <div style={{display: 'flex', justifyContent: 'center', marginTop: '20%'}}>
                <button
                  className="button-mensaje"
                  style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em'}}
                  onClick={() => {
                    handlePopupYesorNo();
                    handlePopupCloseError();
                  }}>
                  SI
                </button>
                <button className="button-mensaje" style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em', marginLeft: '10px'}} onClick={handlePopupCloseError}>
                  NO
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CrudEvents;
