// src/App.js
import React from "react";
import { BrowserRouter as Router, Switch, Route,Redirect } from "react-router-dom";
// eslint-disable-next-line
import HomeView from "./components/Pages/HomeView";
import Login from "./components/Pages/Login";
import RegisterEvents from './components/Pages/RegisterEvents';
import RegisterVouchers from "./components/Pages/RegisterVouchers";
import ReportVouchersUser from "./components/Pages/ReportVouchersUser";
import RegisterBenefits from "./components/Pages/RegisterBenefits";
import ReportReferidos from "./components/Pages/ReportReferidos";
import ReportUsers from "./components/Pages/ReportUsers";
import ReportBeneficios from './components/Pages/ReportBeneficios';
import ReportEvolution from "./components/Pages/ReportEvolution";
import RegisterEvolutions from "./components/Pages/RegisterEvolutions";
import useUnloadCleanup from "./GlobalEventHandler";
import ProtectedRoute from "./ProtectedRoute";
import CrudUsers from './components/Pages/CrudUsers';
import CrudEvents from './components/Pages/CrudEvents';
import CrudCategories from './components/Pages/CrudCategories';
import CrudVouchersCat from './components/Pages/CrudVouchersCat';
import ReportMovimientos from './components/Pages/ReportMovimientos';
import CrudNotifications from './components/Pages/CrudNotifications';
import ReportUserMovements from './components/Pages/ReportUserMovements';

function App() {
	  useUnloadCleanup();
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        <ProtectedRoute exact path="/" component={HomeView} />
        <ProtectedRoute path="/homeView" component={HomeView} />
        <ProtectedRoute path="/crudEvents" component={CrudEvents} />
        <ProtectedRoute path="/registerEvents" component={RegisterEvents} />
        <ProtectedRoute path="/registerEvolutions" component={RegisterEvolutions} />
        <ProtectedRoute path="/registerVouchers" component={RegisterVouchers} />
        <ProtectedRoute path="/reportVouchersUser" component={ReportVouchersUser} />
        <ProtectedRoute path="/registerBenefits" component={RegisterBenefits} />
        <ProtectedRoute path="/reportBeneficios" component={ReportBeneficios} />
        <ProtectedRoute path="/reportReferidos" component={ReportReferidos} />
        <ProtectedRoute path="/reportUsers" component={ReportUsers} />
        <ProtectedRoute path="/reportEvolution" component={ReportEvolution} />
        <ProtectedRoute path="/reportMovimientos" component={ReportMovimientos} />
        <ProtectedRoute path="/reportUserMovements" component={ReportUserMovements} />
        <ProtectedRoute path="/crudUsers" component={CrudUsers} />
        <ProtectedRoute path="/crudCategories" component={CrudCategories} />
        <ProtectedRoute path="/crudVouchersCat" component={CrudVouchersCat} />
        <ProtectedRoute path="/crudNotifications" component={CrudNotifications} />
        <Redirect to="/login" />
      </Switch>
    </Router>
  );
}

export default App;
