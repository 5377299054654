import React, { useEffect, useState } from "react";
import { colors } from "../utils/index";
import Header from "./Header";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import "./Main.css";
import AWS from 'aws-sdk';
import Loader from "react-js-loader";
import CustomButton from '../utils/CustomButton';
const RegisterBenefits = () => {
  const [isLoading, setIsLoading] = useState(false);
  const mincel = window.innerWidth < 900;
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [allBenefits, setAllBenefits] = useState([]);
  const [popupOpenError, setPopupOpenError] = useState(false);
  const [popupOpenYesorNo, setPopupOpenYesorNo] = useState(false);
  const [mensaje, setMensaje] = useState('');
  const [buttonColor, setButtonColor] = useState(colors.RED);
  const [title, setTitle] = useState('REGISTRA BENEFICIO');
  const [formBackgroundColor, setFormBackgroundColor] = useState(colors.CARD);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [resetPhoto, setResetPhoto] = useState(false);
  // eslint-disable-next-line
  const [send, setSend] = useState(false);
  const [addresses, setAddresses] = useState([{value: '', googleMapLink: ''}]);

  const handleResetPasswordChange = event => {
    setResetPassword(event.target.checked);
  };

  const handleResetFotoChange = event => {
    setResetPhoto(event.target.checked);
  };

  const [userData, setUserData] = useState({
    category: '',
    nameCategory: '',
    id: '',
    benefit1: '',
    benefit2: '',
    company: '',
    email: '',
    email2: '',
    condition1: '',
    condition2: '',
    description: '',
    web: '',
    ig: '',
    wa: '',
    image1: '',
    image2: '',
    image3: '',
    image4: '',
    pin: '',
  });

  const [files, setFiles] = useState({
    image1: null,
    image2: null,
    image3: null,
    image4: null,
  });

  useEffect(() => {
    const fetchOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    };

    Promise.all([fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/consultBenefitCat', fetchOptions), fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/consultBenefits', fetchOptions)])
      .then(([categoriesResponse, usersClubResponse]) => Promise.all([categoriesResponse.json(), usersClubResponse.json()]))
      .then(([categoryData, benefitsData]) => {
        if (categoryData.error) {
          console.error('Error al obtener las opciones de Category:', categoryData.error);
        } else {
          const categoryOptions = categoryData.data.map(item => ({
            id: item.id,
            name: `${item.name}`,
          }));
          setCategoryOptions(categoryOptions);
        }

        if (benefitsData.error) {
          console.error('Error al obtener los beneficios del club:', benefitsData.error);
        } else {
          setAllBenefits(benefitsData.data);
        }
      })
      .catch(error => {
        console.error('Error al obtener las opciones de Category y los beneficios del club:', error);
      });
  }, []);

  const handleDeleteButtonClick = async () => {
    setFormBackgroundColor(colors.LIGHT_GREEN);
    setTitle('ELIMINA BENEFICIO');
    setSearchEnabled(true);
  };

  const handleChangeButtonClick = async () => {
    setFormBackgroundColor(colors.DARKGREEN);
    setTitle('MODIFICA BENEFICIO');
    setSearchEnabled(true);
  };

  const handleRegisterButtonClick = async () => {
    setSearchEnabled(false);
    setFormBackgroundColor(colors.CARD);
    setTitle('REGISTRA BENEFICIO');
  };

  const handlePopupCloseError = () => {
    setPopupOpenError(false);
    setPopupOpenYesorNo(false);
    setIsLoading(false);
  };

  const cleanForm = async () => {
    setUserData({
      category: '',
      id: '',
      benefit1: '',
      benefit2: '',
      company: '',
      nameCompany: '',
      email: '',
      email2: '',
      condition1: '',
      condition2: '',
      description: '',
      web: '',
      ig: '',
      wa: '',
      image1: '',
      image2: '',
      image3: '',
      image4: '',
      pin: '',
    });
    setResetPassword(false);
    setResetPhoto(false);
    setFiles({
      image1: null,
      image2: null,
      image3: null,
      image4: null,
    });
  };

const handleCategoryChange = (e, selectedOption) => {
  if (selectedOption) {
    setUserData(prevUserData => ({
      ...prevUserData,
      category: selectedOption.id,
      nameCategory: selectedOption.label,
    }));
  } else {
    setUserData(prevUserData => ({
      ...prevUserData,
      category: '',
      nameCategory: '',
    }));
  }
};


const handleChange = async (e, selectedOption) => {
  let id, value;
  if (selectedOption) {
    id = selectedOption.id;
    value = selectedOption.label; // Asumiendo que 'label' es lo que quieres mostrar
  } else {
    id = e.target.id;
    value = e.target.value;
  }

  if (title !== 'REGISTRA BENEFICIO' && id === 'company') {
    const user = allBenefits.find(user => user.id === value);
    const selectedCategory = categoryOptions.find(category => category.id === userData.category);
    if (user) {
      setUserData({
        ...userData,
        company: user.company,
        category: user.category,
        nameCategory: selectedCategory ? selectedCategory.name : '',
        email: user.email,
        email2: user.email2,
        id: user.id,
        benefit1: user.benefit1,
        benefit2: user.benefit2,
        condition1: user.condition1,
        condition2: user.condition2,
        description: user.description,
        pin: user.pin,
        web: user.web,
        ig: user.ig,
        wa: user.wa,
        image1: user.image1,
        image2: user.image2,
        image3: user.image3,
        image4: user.image4,
      });
    }
  } else {
    setUserData(prevUserData => ({
      ...prevUserData,
      [id]: value,
    }));
  }
};



  const handlePopupYesorNo = async () => {
    if (title === 'ELIMINA BENEFICIO') {
      handleDeleteUser();
    } else {
      handleUpdateUser();
    }
    setPopupOpenYesorNo(true);
  };

  const handleDeleteUser = async () => {
    try {
      setIsLoading(true);
      const id = [{id: userData.id}];

      const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/deleteBenefits', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({id}),
      });
      setIsLoading(false);
      if (!response.ok) {
        setMensaje(response.error);
        setButtonColor(colors.RED);
        setPopupOpenError(true);
        cleanForm();
      } else {
        setMensaje('Beneficio eliminado correctamente');
        setButtonColor(colors.GREEN);
        setPopupOpenError(true);
        cleanForm();
      }
    } catch (error) {
      console.error('Error al eliminar beneficio:', error.message);
    }
  };

  const handleUpdateUser = async () => {
    try {
      const isValid = await validateData();
      if (!isValid) {
        return;
      }
      setIsLoading(true);

      const body = {
        company: userData.company,
        category: userData.category,
        email: userData.email,
        id: userData.id,
        benefit1: userData.benefit1,
        benefit2: userData.benefit2,
        condition1: userData.condition1,
        condition2: userData.condition2,
        description: userData.description,
        pin: userData.pin,
        web: userData.web,
        ig: userData.ig,
        wa: userData.wa,
        image1: userData.image1,
        image2: userData.image2,
        image3: userData.image3,
        image4: userData.image4,
        addresses: JSON.stringify(addresses),
        typeAbm: '2',
      };

      const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/updateUsersClub', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      setIsLoading(false);
      setResetPassword(false);
      setResetPhoto(false);
      if (!response.ok) {
        setMensaje(response.error);
        setButtonColor(colors.RED);
        setPopupOpenError(true);
      } else {
        setMensaje('Beneficio modificado correctamente');
        setButtonColor(colors.GREEN);
        setPopupOpenError(true);
      }
    } catch (error) {
      console.error('Error al modificar beneficio:', error.message);
    }
  };

  const validateData = async () => {
    if (userData.pin === '') {
      setMensaje('Complete los datos');
      setPopupOpenError(true);
      return false;
    }

    // const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

    // if (!emailRegex.test(userData.email) || !emailRegex.test(userData.email2)) {
    //   setMensaje('Email inválido');
    //   setPopupOpenError(true);
    //   return false;
    // }

    // if (userData.email !== userData.email2) {
    //   setMensaje('Los correos electrónicos no coinciden');
    //   setPopupOpenError(true);
    //   return false;
    // }

    return true;
  };

  const handleSubmit = async e => {
    e.preventDefault(); // Prevent default form submission
    const isValid = await validateData();
    if (!isValid) {
      return; // No ejecutar el resto de la lógica si la validación falla
    }

    if (title === 'ELIMINA BENEFICIO') {
      setMensaje('ELIMINA BENEFICIO: ' + userData.company + '?');
      setPopupOpenYesorNo(true);
    } else if (title === 'MODIFICA BENEFICIO') {
      setMensaje('MODIFICA BENEFICIO: ' + userData.company + '?');
      setPopupOpenYesorNo(true);
    } else {
      setIsLoading(true);
      const uploadedFiles = await Promise.all(
        Object.keys(files).map(async key => {
          if (files[key]) {
            return await uploadToS3(files[key]);
          }
          return null;
        }),
      );

      const newUserData = {
        ...userData,
        image1: uploadedFiles[0],
        // image2: uploadedFiles[1],
        // image3: uploadedFiles[2],
        // image4: uploadedFiles[3],
      };

      setUserData(newUserData);

      const body = {
        company: newUserData.company,
        category: newUserData.category,
        email: '',
        id: '',
        benefit1: newUserData.benefit1,
        benefit2: newUserData.benefit2,
        condition1: newUserData.condition1,
        condition2: newUserData.condition2,
        description: newUserData.description,
        pin: newUserData.pin,
        web: '',
        ig: '',
        wa: '',
        image1: newUserData.image1,
        image2: newUserData.image1,
        image3: newUserData.image1,
        image4: newUserData.image1,
        addresses: '',
        typeAbm: '1',
      };

      try {
        const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/registerBenefits', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(body),
        });

        const data = await response.json();
        setIsLoading(false);

        if (data.error) {
          setMensaje(data.error);
          setButtonColor(colors.RED);
          setPopupOpenError(true);
          cleanForm();
        } else {
          setMensaje('ALTA EXITOSA');
          setButtonColor(colors.GREEN);
          setPopupOpenError(true);
          cleanForm();
        }
      } catch (error) {
        console.error('Error registrando beneficio:', error);
        setIsLoading(false);
      }
    }
  };

  const uploadToS3 = async file => {
    const s3 = new AWS.S3({
      bucket: 'buckettree/catch/beneficios', // Ex. aboutreact
      region: 'us-east-1', // Ex. ap-south-1
      accessKeyId: 'AKIAUJDBTKTO623KUEMP',
      secretAccessKey: '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',
      successActionStatus: 201,
    });

    const params = {
      Bucket: 'buckettree/catch/beneficios',
      Key: file.name,
      Body: file,
      ACL: 'public-read',
    };

    try {
      const data = await s3.upload(params).promise();
      return data.Location;
    } catch (error) {
      console.error(error);

      return null;
    }
  };

  const handleFileUpload = (event, inputName) => {
    const file = event.target.files[0];
    setFiles(prevFiles => ({
      ...prevFiles,
      [inputName]: file,
    }));
  };
  // eslint-disable-next-line
  const addAddress = () => {
    setAddresses([...addresses, {value: '', googleMapLink: ''}]);
  };
  // eslint-disable-next-line
  const handleAddressChange = (event, index) => {
    const newAddresses = [...addresses];
    newAddresses[index].value = event.target.value;
    setAddresses(newAddresses);
  };
  // eslint-disable-next-line
  const handleGoogleMapLinkChange = (event, index) => {
    const newAddresses = [...addresses];
    newAddresses[index].googleMapLink = event.target.value;
    setAddresses(newAddresses);
  };

  const back = colors.backGroundImage;

  return (
    <div style={{overflowY: 'auto', backgroundImage: `url(${back})`, backgroundSize: '100vw 100vh', height: mincel ? '100%' : '100vh', width: '100%'}}>
      <Header pageTitle={title} showBackButton={true} />
      {isLoading && (
        <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000}}>
          <Loader type="spinner-circle" bgColor={colors.WHITE} color={colors.BACKG_Color} size={100} />
        </div>
      )}
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', marginTop: '30px'}}>
        <div style={{zIndex: 100, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{bottom: 0, padding: 10, backgroundSize: 'cover', width: mincel ? '100vw' : '90vw', flex: 1, marginTop: mincel ? 0 : '10px', flexDirection: 'row', zIndex: 990, maxWidth: '90vw', backgroundColor: formBackgroundColor, borderRadius: '40px'}}>
            <div style={{position: 'absolute', marginTop: '-50px', marginLeft: '50px', display: 'flex'}}>
              <button onClick={handleRegisterButtonClick} className="button-pest" style={{backgroundColor: colors.CARD}}>
                AGREGAR
              </button>
              <button onClick={handleDeleteButtonClick} className="button-pest" style={{backgroundColor: colors.LIGHT_GREEN}}>
                ELIMINAR
              </button>
              <button onClick={handleChangeButtonClick} className="button-pest" style={{backgroundColor: colors.DARKGREEN}}>
                MODIFICAR
              </button>
            </div>
            <div style={{marginTop: '-20px', fontSize: '2em', marginLeft: 20}}>
              <p style={{color: 'white', fontSize: mincel ? '1.1em' : '0.8em', fontFamily: 'HelveticaNeue-Bold'}}>{title}</p>
            </div>
            <div style={{overflowY: 'auto', backgroundColor: colors.MINICARD, padding: 5, borderRadius: 20, marginTop: '20px', marginBottom: '20px'}}>
              <div style={{marginTop: '5px', display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px'}}>
                <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                  <label className="textTerm">BENEFICIO</label>
                  <p className="textFormError">{userData.company === '' ? 'Campo requerido' : ''}</p>
                  {searchEnabled ? (
                    <>
                      <Autocomplete
                        style={{width: mincel ? '90vw' : '30vw', marginTop: '10px', backgroundColor: '#fff', border: '2px solid #000'}}
                        value={userData.company}
                        onChange={(e, selectedOption) => handleChange(e, selectedOption)} // Pasar selectedOption como segundo argumento
                        name="BENEFICIO"
                        id="company"
                        isOptionEqualToValue={(option, value) => option.name === value.name || value === ''}
                        options={allBenefits.map(user => ({label: user.company, value: user.id}))}
                        renderInput={params => <TextField {...params} label="Seleccione BENEFICIO" variant="outlined" />}
                      />
                    </>
                  ) : (
                    <>
                      <input style={{textTransform:'uppercase', height: 40, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none',}} type="text" required id="company" placeholder="Nombre de BENEFICIO" onChange={handleChange} value={userData.company || ''} />
                    </>
                  )}
                </div>
                {/* <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                  <p className="textTerm">CUIT</p>
                  <p className="textFormError">{userData.id === '' ? 'Campo requerido' : ''}</p>
                  <input style={{height: 40, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="text" required id="id" placeholder={'ej.: XX-XXXXXXXX-X'} className="no-outline" onChange={handleChange} value={userData.id || ''} />
                </div> */}
              </div>
              <div style={{marginTop: '5px', display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px'}}>
                <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                  <label className="textTerm">CATEGORÍA</label>
                  <p className="textFormError">{userData.nameCategory === '' ? 'Campo requerido' : ''}</p>
                  <Autocomplete
                    style={{width: mincel ? '90vw' : '30vw', marginTop: '10px', backgroundColor: '#fff', border: '2px solid #000'}}
                    value={categoryOptions.find(option => option.id === userData.category) || null}
                    onChange={(e, selectedOption) => handleCategoryChange(e, selectedOption)}
                    id="category"
                    options={categoryOptions}
                    getOptionLabel={option => option.name} // Asegúrate de que se use 'name' para la etiqueta
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={params => <TextField {...params} label="Seleccione CATEGORÍA" variant="outlined" />}
                  />
                </div>

                {/* <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                  <p className="textTerm">CELULAR Whatsapp con CODIGO DE ÁREA</p>
                  <p className="textFormError">{userData.wa === '' ? 'Campo requerido' : ''}</p>
                  <input style={{height: 40, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} maxLength={10} type="text" required id="wa" placeholder={'ej.: 011xxxxxxx'} className="no-outline" onChange={handleChange} value={userData.wa || ''} />
                </div> */}
              </div>
            </div>
            {/* <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px', flexDirection: mincel ? 'column' : 'row'}}>
              <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                <p className="textTerm">MAIL</p>
                <p className="textFormError">{userData.email === '' ? 'Campo requerido' : ''}</p>
                <input style={{height: 40, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} maxLength={30} type="email" required id="email" placeholder="E-mail" onChange={handleChange} value={userData.email || ''} />
              </div>
              <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                <p className="textTerm">CONFIRMACIÓN DE MAIL</p>
                <p className="textFormError">{userData.email2 === '' ? 'Campo requerido' : ''}</p>
                <input style={{height: 40, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} maxLength={30} type="email" required id="email2" placeholder={'ej.: ejemplo@gmail.com'} className="no-outline" onChange={handleChange} value={userData.email2 || ''} />
              </div>
            </div> */}
            {/* <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px', flexDirection: mincel ? 'column' : 'row'}}>
              <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                <p className="textTerm">WEB</p>
                <p className="textFormError">{userData.web === '' ? 'Campo requerido' : ''}</p>
                <input style={{height: 40, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} maxLength={50} type="text" required id="web" placeholder="www.xxxxx.xxx" onChange={handleChange} value={userData.web || ''} />
              </div>
              <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                <p className="textTerm">INSTAGRAM</p>
                <p className="textFormError">{userData.ig === '' ? 'Campo requerido' : ''}</p>
                <input style={{height: 40, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} maxLength={30} type="text" required id="ig" placeholder={'ej.: xxxxxxxx'} className="no-outline" onChange={handleChange} value={userData.ig || ''} />
              </div>
            </div> */}
            <div style={{marginTop: 5}}>
              <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                <p className="textTerm">DESCRIPCIÓN</p>
                <p className="textFormError">{userData.description === '' ? 'Campo requerido' : ''}</p>
                <input style={{height: 40, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} maxLength={140} type="text" required id="description" placeholder="Descripción" onChange={handleChange} value={userData.description || ''} />
              </div>
            </div>
            <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px', flexDirection: mincel ? 'column' : 'row'}}>
              <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                <p className="textTerm">BENEFICIO 1</p>
                <p className="textFormError">{userData.benefit1 === '' ? 'Campo requerido' : ''}</p>
                <input style={{height: 40, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} maxLength={80} type="text" required id="benefit1" placeholder="Beneficio 1" onChange={handleChange} value={userData.benefit1 || ''} />
              </div>
              <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                <p className="textTerm">BENEFICIO 2</p>
                <p className="textFormError">{userData.benefit2 === '' ? 'Campo requerido' : ''}</p>
                <input style={{height: 40, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} maxLength={80} type="text" required id="benefit2" placeholder="Beneficio 2" onChange={handleChange} value={userData.benefit2 || ''} />
              </div>
            </div>

            <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px', flexDirection: mincel ? 'column' : 'row'}}>
              <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                <p className="textTerm">CONDICIÓN 1</p>
                <p className="textFormError">{userData.condition1 === '' ? 'Campo requerido' : ''}</p>
                <input style={{height: 40, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} maxLength={80} type="text" required id="condition1" placeholder="Condición 1" onChange={handleChange} value={userData.condition1 || ''} />
              </div>

              <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                <p className="textTerm">CONDICIÓN 2</p>
                <p className="textFormError">{userData.condition2 === '' ? 'Campo requerido' : ''}</p>
                <input style={{height: 40, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} maxLength={80} type="text" required id="condition2" placeholder="Condición 2" onChange={handleChange} value={userData.condition2 || ''} />
              </div>
            </div>

            <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px', flexDirection: mincel ? 'column' : 'row'}}>
              <p className="textTerm">PIN</p>
              <p className="textFormError">{userData.pin === '' ? 'Campo requerido' : ''}</p>
              <input style={{textTransform: 'uppercase',height: 40, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} maxLength={6} type="text" required id="pin" placeholder={'ej.: xxxx'} className="no-outline" onChange={handleChange} value={userData.pin} />
            </div>
            <div>
              {/* <CustomButton
                onPress={addAddress}
                title="AGREGAR DIRECCIÓN"
                color={colors.MARRON_OSCURO} // Ajusta el color según sea necesario
                fontSize={18} // Ajusta el tamaño de la fuente si es necesario
                buttonWidth={200} // Ajusta el ancho del botón si es necesario
                buttonStyle={{alignContent: 'flex-start'}} // Aplica estilos adicionales si es necesario
              />

              {addresses.map((address, index) => (
                <div key={index} style={{marginTop: 20}}>
                  <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px', flexDirection: mincel ? 'column' : 'row'}}>
                    <div style={{alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                      <p className="textForm">Dirección</p>
                      <p className="textFormError">{address.value === '' && send ? 'Campo requerido' : ''}</p>
                      <input
                        style={{
                          height: 30,
                          border: '1px solid ' + colors.gray,
                          backgroundColor: '#CDCDCD',
                          width: '100%',
                        }}
                        type="text"
                        required
                        value={address.value}
                        className="no-outline"
                        onChange={event => handleAddressChange(event, index)}
                      />
                    </div>
                    <div style={{alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                      <p className="textForm">Enlace de Google Maps</p>
                      <p className="textFormError">{address.value === '' && send ? 'Campo requerido' : ''}</p>
                      <input
                        style={{
                          height: 30,
                          border: '1px solid ' + colors.gray,
                          backgroundColor: '#CDCDCD',
                          width: '100%',
                        }}
                        type="text"
                        required
                        value={address.googleMapLink}
                        className="no-outline"
                        onChange={event => handleGoogleMapLinkChange(event, index)}
                      />
                    </div>
                  </div>
                </div>
              ))} */}
            </div>
            <div style={{backgroundColor: colors.MINICARD, padding: 5, borderRadius: 20, marginTop: '20px', marginBottom: '20px'}}>
              <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px', flexDirection: mincel ? 'column' : 'row'}}>
                <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                  <p className="textTerm">IMAGEN 1</p>
                  <p style={{color: '#000'}} className="textFormError">
                    {userData.image1 === '' ? '500px x 300px y menor a 400kb' : ''}
                  </p>
                  <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="file" required id="image1" placeholder="500px x 300px y menor a 400kb" className="no-outline" onChange={event => handleFileUpload(event, 'image1')} />
                </div>
                {/* <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                  <p className="textTerm">IMAGEN 2</p>
                  <p style={{color: '#000'}} className="textFormError">
                    {userData.image2 === '' ? '500px x 300px y menor a 400kb' : ''}
                  </p>
                  <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="file" required id="image2" placeholder="500px x 300px y menor a 400kb" className="no-outline" onChange={event => handleFileUpload(event, 'image2')} />
                </div> */}
              </div>
              {/* <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px', flexDirection: mincel ? 'column' : 'row'}}>
                <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                  <p className="textTerm">IMAGEN 3</p>
                  <p style={{color: '#000'}} className="textFormError">
                    {userData.image3 === '' ? '500px x 300px y menor a 400kb' : ''}
                  </p>
                  <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="file" required id="image3" placeholder="500px x 300px y menor a 400kb" className="no-outline" onChange={event => handleFileUpload(event, 'image3')} />
                </div>
                <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                  <p className="textTerm">IMAGEN 4</p>
                  <p style={{color: '#000'}} className="textFormError">
                    {userData.image4 === '' ? '500px x 300px y menor a 400kb' : ''}
                  </p>
                  <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="file" required id="image4" placeholder="500px x 300px y menor a 400kb" className="no-outline" onChange={event => handleFileUpload(event, 'image4')} />
                </div>
              </div> */}
            </div>
            {title === 'MODIFICA BENEFICIO' && (
              <div style={{marginTop: '10px', padding: 1, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
                <label>
                  <input id="password" type="checkbox" checked={resetPassword} onChange={handleResetPasswordChange} /> Reset Password
                </label>
                <br />
                <label>
                  <input id="photo" type="checkbox" checked={resetPhoto} onChange={handleResetFotoChange} /> Reset Foto
                </label>
              </div>
            )}
          </div>
        </div>
        <div style={{marginTop: '35px', alignItems: 'center', display: 'flex', justifyContent: 'center', marginBottom: '10px'}}>
          <div style={{textAlign: 'center'}}>
            <CustomButton
              onPress={handleSubmit}
              title={title === 'REGISTRA BENEFICIO' ? 'REGISTRAR' : title === 'ELIMINA BENEFICIO' ? 'ELIMINAR' : 'MODIFICAR'}
              color={colors.MARRON_OSCURO} // Ajusta el color según sea necesario
              fontSize={18} // Ajusta el tamaño de la fuente si es necesario
              buttonWidth={200} // Ajusta el ancho del botón si es necesario
            />
          </div>
        </div>

        {popupOpenError && (
          <div style={{zIndex: 999, borderRadius: 30, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{width: '80%', backgroundColor: buttonColor, padding: '20px', borderRadius: 40, maxWidth: '300px', height: 250}}>
              <p style={{fontFamily: 'Como-Bold', marginTop: '20%', color: 'white', fontSize: '1.5em', textAlign: 'center'}}>{mensaje}</p>
              <div style={{display: 'flex', justifyContent: 'center', marginTop: '20%'}}>
                <button className="button-mensaje" style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em'}} onClick={handlePopupCloseError}>
                  OK
                </button>
              </div>
            </div>
          </div>
        )}

        {popupOpenYesorNo && (
          <div style={{zIndex: 999, borderRadius: 30, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{width: '80%', backgroundColor: buttonColor, padding: '20px', borderRadius: 40, maxWidth: '300px', height: 250}}>
              <p style={{fontFamily: 'Como-Bold', marginTop: '20%', color: 'white', fontSize: '1.5em', textAlign: 'center'}}>{mensaje}</p>
              <div style={{display: 'flex', justifyContent: 'center', marginTop: '20%'}}>
                <button
                  className="button-mensaje"
                  style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em'}}
                  onClick={() => {
                    handlePopupYesorNo();
                    handlePopupCloseError();
                  }}>
                  SI
                </button>
                <button
                  className="button-mensaje"
                  style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em', marginLeft: '10px'}}
                  onClick={() => {
                    handlePopupCloseError();
                  }}>
                  NO
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RegisterBenefits;
