import React, {useState, useMemo, useCallback, useEffect} from 'react'; // Asegúrate de importar useEffect
import {useTable, useFilters, useGlobalFilter, usePagination} from 'react-table';
import * as XLSX from 'xlsx';
import {useHistory} from 'react-router-dom';
import Loader from 'react-js-loader';
import Header from './Header';
import {colors} from '../utils/index';
import './Main.css';
import CustomButton from '../utils/CustomButton';
import moment from 'moment';

const ReportVouchersUser = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const mincel = window.innerWidth < 900;
  const [popupOpenError, setPopupOpenError] = useState(false);
  const [popupOpenYesorNo, setPopupOpenYesorNo] = useState(false);
  const [mensaje, setMensaje] = useState('');
  const [selectAllHeader, setSelectAllHeader] = useState(false);
  const [buttonColor, setButtonColor] = useState(colors.RED);
  const [title, setTitle] = useState('CONSULTA VOUCHERS');
  const [titleExcel, setTitleExcel] = useState('');
  const [responseData, setResponseData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [voucherIdsToDelete, setVoucherIdsToDelete] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [formBackgroundColor, setFormBackgroundColor] = useState(colors.MARRON_OSCURO);
  // eslint-disable-next-line
  const [voucherId, setVoucherId] = useState(null);
  // Estados para los filtros
  const [fechaDesde, setFechaDesde] = useState('');
  const [fechaHasta, setFechaHasta] = useState('');


  const defaultColumn = useMemo(
    () => ({
      Filter: ({column}) => {
        const {filterValue, setFilter} = column;
        return (
          <input
            value={filterValue || ''}
            onChange={e => setFilter(e.target.value)}
            placeholder={`Filtrar por ${column.Header}`}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                const filterText = e.target.value;
                setFilter(filterText);
              }
            }}
          />
        );
      },
      filter: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue.toLowerCase().startsWith(filterValue.toLowerCase());
        });
      },
    }),
    [],
  );

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const body = {
        fechaDesde: moment(fechaDesde).format('YYYY/MM/DD'),
        fechaHasta: moment(fechaHasta).format('YYYY/MM/DD'),
        // Añadir paginación si es necesario
      };
		
      const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/consultVouchersMoves', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      setIsLoading(false);
      if (data.error) {
        setMensaje(data.error === 'No se encontraron Vouchers' ? 'No hay Vouchers' : 'Error en carga de Vouchers');
        setTitle('CONSULTA VOUCHER');
        setPopupOpenError(true);
      } else {
        setResponseData(data.data);
        setFilteredRecords(data.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error al cargar los vouchers:', error);
    }
  };

  const handleSelectVoucher = useCallback(
    voucherId => {
      if (voucherIdsToDelete.includes(voucherId)) {
        setVoucherIdsToDelete(voucherIdsToDelete.filter(id => id !== voucherId));
      } else {
        setVoucherIdsToDelete([...voucherIdsToDelete, voucherId]);
      }
    },
    [voucherIdsToDelete],
  );

  const handleSelectAll = useCallback(() => {
    const newValue = !selectAllHeader;
    setSelectAllHeader(newValue);
    setSelectAll(newValue);
    const allVoucherIds = responseData.map(voucher => voucher.id);
    setVoucherIdsToDelete(newValue ? allVoucherIds : []);
  }, [selectAllHeader, responseData]);

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div style={{marginTop: '-20px'}}>
            {title !== 'CONSULTA VOUCHERS' && <input type="checkbox" checked={selectAll} onChange={() => handleSelectAll()} />}
            {titleExcel}
          </div>
        ),
        disableFilters: true,
        Cell: ({row}) => title !== 'CONSULTA VOUCHERS' && <input type="checkbox" checked={selectAllHeader || selectAll || voucherIdsToDelete.includes(row.original.id)} onChange={() => handleSelectVoucher(row.original.id)} />,
        accessor: 'checkbox',
      },
      {Header: 'DNI', accessor: 'dni', style: {textAlign: 'left'}},
      {Header: 'NOMBRE', accessor: 'nombre', style: {textAlign: 'left'}},
      {Header: 'APELLIDO', accessor: 'apellido', style: {textAlign: 'left'}},
      {
        Header: 'VOUCHER',
        accessor: 'nameVoucher',
        style: {textAlign: 'left'},
      },
      {
        Header: 'FECHA VTO',
        accessor: 'fechaVto',
        style: {textAlign: 'center'},
      },
      {
        Header: 'HORA VTO',
        accessor: 'horaVto',
        style: {textAlign: 'center'},
      },
      {
        Header: 'UTILIZADO',
        accessor: 'used',
        style: {textAlign: 'center'},
      },
      {
        Header: 'CATEGORIA',
        accessor: 'category',
        style: {textAlign: 'left'},
      },
      {
        Header: 'EMITIDO POR',
        accessor: 'rrpp',
        style: {textAlign: 'center'},
      },
    ],
    [voucherIdsToDelete, handleSelectVoucher, handleSelectAll, selectAll, selectAllHeader, title, titleExcel],
  );

  const tableData = useMemo(
    () => ({
      columns,
      data: Array.isArray(responseData) ? responseData : [],
      defaultColumn,
    }),
    [responseData, columns, defaultColumn],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {pageIndex, pageSize},
  } = useTable(tableData, useFilters, useGlobalFilter, usePagination);

  useEffect(() => {
    setFilteredRecords(rows.map(row => row.original));
  }, [rows]);

  const handleExport = () => {
    if (filteredRecords !== null && filteredRecords !== 'SIN MOVIMIENTOS') {
      const filteredData = filteredRecords.length > 0 ? filteredRecords : rows.map(row => row.original);
      if (filteredData.length > 0) {
        exportToExcel(filteredData);
      } else {
        console.log('No hay datos para exportar');
      }
    }
  };

  const exportToExcel = filteredRows => {
    const exportData = filteredRows.map(row => ({
      DNI: row.dni,
      NOMBRE: row.nombre.toUpperCase(),
      APELLIDO: row.apellido.toUpperCase(),
      VOUCHER: row.nameVoucher.toUpperCase(),
      'FECHA VTO.': row.fechaVto,
      'HORA VTO.': row.horaVto,
      CATEGORIA: row.category.toUpperCase(),
      UTILIZADO: row.used
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
    XLSX.writeFile(workbook, 'report.xlsx');
  };

  const handleDeleteVoucher = async () => {
    try {
      setIsLoading(true);
      var body = [];

      if (filteredRecords.length >= 1) {
        for (const record of filteredRecords) {
          const bodyItems = {};
          const partes = record.id.split('*');
          let idVou = partes.slice(0, -1).join('*');
          let dni = partes[partes.length - 1];
          bodyItems['id'] = idVou;
          bodyItems['dni'] = dni;
          body.push(bodyItems);
        }
      }

      const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/deleteVouchersMoves', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();

      if (data.error) {
        setButtonColor(colors.RED);
        setMensaje('Error Eliminación, Inténtelo de nuevo.');
        setPopupOpenError(true);
      } else {
        setResponseData(prevData => prevData.filter(voucher => !voucherIdsToDelete.includes(voucher.id)));
        setMensaje('Eliminación exitosa');
        setButtonColor(colors.GREEN);
        setPopupOpenError(true);
      }
      setIsLoading(false);
      setVoucherIdsToDelete([]);
    } catch (error) {
      console.error('Error al cargar los vouchers:', error);
      setIsLoading(false);
    }
  };

  const handlePopupCloseError = () => {
    setPopupOpenError(false);
    setPopupOpenYesorNo(false);
  };

  const handlePopupYesorNo = async () => {
    if (title === 'ELIMINA VOUCHERS') {
      handleDeleteVoucher();
    } else {
      handleExport();
    }
  };

  const handleSubmit = () => {
    // Create an array to store selected dnis
    const selectedReferidoIds = [];

    // Iterate over the rows to find selected rows and push their dnis to the array
    rows.forEach(row => {
      if (row.isSelected) {
        selectedReferidoIds.push(row.original.idKey); // Assuming 'idKey' is the field containing dni
      }
    });

    // If no rows are selected, show error message
    if (filteredRecords.length === 0) {
      if (title === 'ELIMINA VOUCHERS') {
        setMensaje('No se han seleccionado Registro para Eliminar.');
      } else {
        setMensaje('No se han seleccionado Registro para Exportar.');
      }

      setButtonColor(colors.RED);
      setPopupOpenError(true);
      return;
    }

    // If only one row is selected, set the dni for deletion
    if (filteredRecords.length === 1) {
      if (title === 'CONSULTA VOUCHERS') {
        setMensaje('CONSULTA VOUCHERS?');
      } else {
        setMensaje('ELIMINA VOUCHERS?');
      }
      setVoucherId(selectedReferidoIds[0]);
    } else {
      if (title === 'CONSULTA VOUCHERS') {
        setMensaje('CONSULTA ' + filteredRecords.length + ' VOUCHERS?');
      } else {
        setMensaje('ELIMINA ' + filteredRecords.length + ' VOUCHERS?');
      }
    }

    setButtonColor(colors.RED);
    setPopupOpenYesorNo(true);
  };

  const handleAtualizapup = async () => {
    setFormBackgroundColor(colors.MARRON_OSCURO);
    setTitle('CONSULTA VOUCHERS');
    setTitleExcel('');
    fetchData(); // Llama a la función para obtener los datos filtrados
  };

  const handleElimina = async () => {
    setFormBackgroundColor(colors.LIGHT_GREEN);
    setTitle('ELIMINA VOUCHERS');
    setTitleExcel('TODOS');
  };

  const back = colors.backGroundImage;
  if (localStorage.dni === 'undefined') {
    history.push({
      pathname: '/login',
    });
  }
  return (
    <div style={{backgroundImage: `url(${back})`, backgroundSize: '100vw 100vh', height: mincel ? '100%' : '100vh', width: '100%'}}>
      <Header pageTitle={title} showBackButton={true} />
      {isLoading && (
        <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000}}>
          <Loader type="spinner-circle" bgColor={colors.WHITE} color={colors.BACKG_Color} size={100} />
        </div>
      )}
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', marginTop: '30px'}}>
        <div style={{zIndex: 100, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{bottom: 0, padding: 10, backgroundSize: 'cover', width: mincel ? '100vw' : '90vw', flex: 1, marginTop: mincel ? 0 : '10px', flexDirection: 'row', zIndex: 990, maxWidth: '90vw', backgroundColor: formBackgroundColor, borderRadius: '50px'}}>
            {/* Botones de pestaña */}
            <div style={{position: 'absolute', marginTop: '-50px', marginLeft: '50px', display: 'flex'}}>
              <button onClick={handleAtualizapup} className="button-pest" style={{backgroundColor: colors.MARRON_OSCURO}}>
                CONSULTA
              </button>
              <button onClick={handleElimina} className="button-pest" style={{backgroundColor: colors.LIGHT_GREEN}}>
                ELIMINAR
              </button>
            </div>
            {/* Sección de filtros */}
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px'}}>
              <div style={{marginRight: '20px', color: '#fff'}}>
                <label style={{marginRight: 10}}>Fecha VTO Desde:</label>
                <input type="date" value={fechaDesde} onChange={e => setFechaDesde(e.target.value)} />
              </div>
              <div style={{marginRight: '20px', color: '#fff'}}>
                <label style={{marginRight: 10}}>Fecha VTO Hasta:</label>
                <input type="date" value={fechaHasta} onChange={e => setFechaHasta(e.target.value)} />
              </div>
              <div style={{marginLeft: '20px'}}>
                <CustomButton onPress={fetchData} title="ACTUALIZAR" color={colors.GRAY} fontSize={18} buttonWidth={200} buttonStyle={{padding: 0, cursor: 'pointer', border: 'none'}} />
              </div>
            </div>

            <div style={{color: '#fff', display: 'grid', alignItems: 'center', backgroundColor: formBackgroundColor, padding: 20, borderRadius: 20, maxHeight: '70vh', overflow: 'auto'}}>
              <table {...getTableProps()} style={{maxWidth: '100%', marginTop: '2%'}}>
                <thead>
                  {headerGroups.map(headerGroup => {
                    const {key, ...restHeaderGroupProps} = headerGroup.getHeaderGroupProps();
                    return (
                      <tr key={key} {...restHeaderGroupProps}>
                        {headerGroup.headers.map(column => {
                          const {key, ...restColumn} = column.getHeaderProps();
                          return (
                            <th key={key} {...restColumn}>
                              {column.render('Header')}
                              <div style={{width: '100%'}}>{column.canFilter ? column.render('Filter') : null}</div>
                            </th>
                          );
                        })}
                      </tr>
                    );
                  })}
                </thead>
                <tbody {...getTableBodyProps}>
                  {page.map(row => {
                    prepareRow(row);
                    const {key, ...restRowProps} = row.getRowProps();
                    return (
                      <tr key={key} {...restRowProps}>
                        {row.cells.map(cell => {
                          const {key, ...restCellProps} = cell.getCellProps();
                          return (
                            <td key={key} {...restCellProps} style={cell.column.style}>
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="pagination">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {'<<'}
                </button>
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                  {'<'}
                </button>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                  {'>'}
                </button>
                <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>
                  {'>>'}
                </button>
                <span>
                  Página{' '}
                  <strong>
                    {pageIndex + 1} de {pageOptions.length}
                  </strong>
                </span>
                <select
                  value={pageSize}
                  onChange={e => {
                    setPageSize(Number(e.target.value));
                  }}>
                  {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      Mostrar {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div style={{marginBottom: '10px', alignItems: 'center', display: 'flex', justifyContent: 'flex-start'}}>
            <CustomButton onPress={handleSubmit} title={title === 'CONSULTA VOUCHERS' ? 'EXPORTAR XLS' : 'ELIMINAR SELECCIONADOS'} color={colors.MARRON_OSCURO} fontSize={18} buttonWidth={250} buttonStyle={{padding: 0, cursor: 'pointer', border: 'none'}} />
          </div>
        </div>
        {/* Popups de error y confirmación */}
        {popupOpenError && (
          <div style={{zIndex: 999, borderRadius: 30, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{width: '80%', backgroundColor: buttonColor, padding: '20px', borderRadius: 30, maxWidth: '300px', height: 250}}>
              <p style={{fontFamily: 'Como-Bold', marginTop: '20%', color: 'white', fontSize: '1.5em', textAlign: 'center'}}>{mensaje}</p>
              <div style={{display: 'flex', justifyContent: 'center', marginTop: '20%'}}>
                <button className="button-mensaje" style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em'}} onClick={handlePopupCloseError}>
                  OK
                </button>
              </div>
            </div>
          </div>
        )}

        {popupOpenYesorNo && (
          <div style={{zIndex: 999, borderRadius: 30, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{backgroundColor: buttonColor, padding: '20px', borderRadius: 30, width: '300px', height: '250px'}}>
              <p style={{fontFamily: 'Como-Bold', marginTop: '20%', color: 'white', fontSize: '1.5em', textAlign: 'center'}}>{mensaje}</p>
              <div style={{display: 'flex', justifyContent: 'center', marginTop: '20%'}}>
                <button
                  className="button-mensaje"
                  style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em'}}
                  onClick={() => {
                    handlePopupYesorNo();
                    handlePopupCloseError();
                  }}>
                  SI
                </button>
                <button className="button-mensaje" style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em', marginLeft: '10px'}} onClick={handlePopupCloseError}>
                  NO
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportVouchersUser;
