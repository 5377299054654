import React, {useState, useMemo, useCallback} from 'react';
import {useTable, useFilters, useGlobalFilter, usePagination} from 'react-table';
import * as XLSX from 'xlsx';
import Loader from 'react-js-loader';
import Header from './Header';
import {colors} from '../utils/index';
import './Main.css';
import CustomButton from '../utils/CustomButton';
import moment from 'moment';

const ReportUsersMoves = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [movementsData, setMovementsData] = useState([]);
  const [popupOpenError, setPopupOpenError] = useState(false);
  const [mensaje, setMensaje] = useState('');
  const [title, setTitle] = useState('CONSULTA MOVIMIENTOS');
  const titleExcel = 'TODOS';
  // eslint-disable-next-line
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [MovimientoIdsToDelete, setMovimientoIdsToDelete] = useState([]);
  const [selectAllHeader, setSelectAllHeader] = useState(false);

  // Filtrar los datos por `pointType` y mapear a las etiquetas
  const filteredData = useMemo(() => {
	  const pointTypeLabels = {
      3: 'ENTRADA',
      10: 'ENTRADA FREE',
      11: 'ENTRADA SOCIOS',
      12: 'COMPRA CREDITS',
      15: 'BENEFICIOS',
      20: 'CANJE BEBIDAS',
      30: 'ENTRADA ANTICIPADA',
      99: 'ENTRADA EFECTIVO',
    };
    return movementsData.filter(row => Object.keys(pointTypeLabels).includes(row.pointType)).map(row => ({...row, pointType: pointTypeLabels[row.pointType]}));
  }, [movementsData]);

  // Add filter capability to each column
  const defaultColumn = useMemo(
    () => ({
      Filter: ({column}) => {
        const {filterValue, setFilter} = column;
        return <input value={filterValue || ''} onChange={e => setFilter(e.target.value)} placeholder={`Filtrar por ${column.Header}`} />;
      },
    }),
    [],
  );

  const handleSelectRecord = useCallback(
    id => {
      if (MovimientoIdsToDelete.includes(id)) {
        setMovimientoIdsToDelete(MovimientoIdsToDelete.filter(recordId => recordId !== id));
      } else {
        setMovimientoIdsToDelete([...MovimientoIdsToDelete, id]);
      }
    },
    [MovimientoIdsToDelete],
  );

  const handleSelectAll = useCallback(() => {
    const newValue = !selectAllHeader;
    setSelectAllHeader(newValue);
    const allIds = filteredData.map(row => row.id);
    setMovimientoIdsToDelete(newValue ? allIds : []);
  }, [selectAllHeader, filteredData]);

  const handleDelete = async () => {
    try {
      setIsLoading(true);
		const body = MovimientoIdsToDelete.map(id => {
       const [dni, ...useDateParts] = id.split('*');
       const useDate = useDateParts.join('*');
       return {dni, useDate};
     });

      const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/deleteUsersMoves', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();

      if (data.error) {
        setMensaje('Error Eliminación, Inténtelo de nuevo.');
        setPopupOpenError(true);
      } else {
        setMovementsData(prevData => prevData.filter(record => !MovimientoIdsToDelete.includes(record.id)));
        setMensaje('Eliminación exitosa');
        setPopupOpenError(true);
      }
      setIsLoading(false);
      setMovimientoIdsToDelete([]);
    } catch (error) {
      console.error('Error al cargar los UsersMoves:', error);
      setIsLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div style={{marginTop: '-20px'}}>
            {title !== 'CONSULTA MOVIMIENTOS' && <input type="checkbox" checked={MovimientoIdsToDelete.length === filteredData.length} onChange={handleSelectAll} />}
            {titleExcel}
          </div>
        ),
        disableFilters: true,
        Cell: ({row}) => title !== 'CONSULTA MOVIMIENTOS' && <input type="checkbox" checked={MovimientoIdsToDelete.includes(row.original.id)} onChange={() => handleSelectRecord(row.original.id)} />,
        accessor: 'checkbox',
      },
      {Header: 'APELLIDO', accessor: 'apellido'},
      {Header: 'NOMBRE', accessor: 'nombre'},
      {Header: 'DNI', accessor: 'dni'},
      {Header: 'FechaIngreso', accessor: 'fechaIngreso'},
      {Header: 'HoraIngreso', accessor: 'horaIngreso'},
      {Header: 'FechaUso', accessor: 'fechaUso'},
      {Header: 'HoraUso', accessor: 'horaUso'},
      {Header: 'Tipo de Movimiento', accessor: 'pointType'},
      {Header: 'Valor', accessor: 'quantity', style: {textAlign: 'right'}},
      {Header: 'Descripción', accessor: 'nombreIn'},
    ],
    [MovimientoIdsToDelete, filteredData, title, titleExcel, handleSelectAll, handleSelectRecord],
  );

  // Generate unique keys and set up table data
  const tableData = useMemo(() => {
    const dataWithKeys = filteredData.map(item => ({
      ...item,
      id: `${item.id}`, // Generar un id único basado en dni y useDate
    }));

    return {
      columns,
      data: dataWithKeys,
      defaultColumn,
    };
  }, [filteredData, columns, defaultColumn]);

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {pageIndex, pageSize},
  } = useTable(tableData, useFilters, useGlobalFilter, usePagination);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const body = {
        fechaDesde: moment(startDate).format('YYYY/MM/DD'),
        fechaHasta: moment(endDate).format('YYYY/MM/DD'),
      };

      const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/consultUsersMoves', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      setIsLoading(false);
      if (data.error) {
        setMensaje(data.error === 'No se encontraron UsersMoves' ? 'No hay UsersMoves' : 'Error en carga de UsersMoves');
        setPopupOpenError(true);
	  } else {

        setMovementsData(data.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error al cargar los UsersMoves:', error);
      setMensaje('Error al cargar los UsersMoves');
      setPopupOpenError(true);
    }
  }, [startDate, endDate]);

  const handlePopupCloseError = () => {
    setPopupOpenError(false);
    setIsLoading(false);
  };

  const handleExport = () => {
    if (filteredData && filteredData.length > 0) {
      const exportData = filteredData.map(row => ({
        FechaIngreso: row.fechaIngreso,
        Nombre: row.nombre,
        Apellido: row.apellido,
        DNI: row.dni,
        'Tipo de Movimiento': row.pointType,
        Valor: row.quantity,
        Descripción: row.nombreIn,
      }));

      const worksheet = XLSX.utils.json_to_sheet(exportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'UsersMoves');
      XLSX.writeFile(workbook, 'UsersMoves.xlsx');
    } else {
      console.log('No hay datos para exportar');
    }
  };

  const back = colors.backGroundImage;

  return (
    <div style={{backgroundImage: `url(${back})`, backgroundSize: '100vw 100vh', height: '100vh', width: '100%'}}>
      <Header pageTitle={title} showBackButton={true} />
      {isLoading && (
        <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000}}>
          <Loader type="spinner-circle" bgColor={colors.WHITE} color={colors.BACKG_Color} size={100} />
        </div>
      )}
      <div style={{marginTop: '20px'}}>
        <div style={{marginTop: 40, zIndex: 100, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{bottom: 0, padding: 10, backgroundSize: 'cover', width: '90vw', flex: 1, marginTop: '10px', flexDirection: 'row', zIndex: 990, maxWidth: '90vw', backgroundColor: title === 'ELIMINA UsersMoves' ? colors.LIGHT_GREEN : colors.MARRON_OSCURO, borderRadius: '50px'}}>
            <div style={{position: 'absolute', marginTop: '-50px', marginLeft: '50px', display: 'flex'}}>
              <button onClick={() => setTitle('CONSULTA UsersMoves')} className="button-pest" style={{backgroundColor: colors.MARRON_OSCURO}}>
                CONSULTA
              </button>
              <button onClick={() => setTitle('ELIMINA UsersMoves')} className="button-pest" style={{backgroundColor: colors.LIGHT_GREEN}}>
                ELIMINAR
              </button>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px'}}>
              <div style={{marginRight: '20px', color: '#fff'}}>
                <label style={{marginRight: 10}}>Fecha Desde:</label>
                <input type="date" value={startDate} onChange={e => setStartDate(e.target.value)} />
              </div>
              <div style={{marginRight: '20px', color: '#fff'}}>
                <label style={{marginRight: 10}}>Fecha Hasta:</label>
                <input type="date" value={endDate} onChange={e => setEndDate(e.target.value)} />
              </div>
              <div style={{marginLeft: '20px'}}>
                <CustomButton onPress={fetchData} title="ACTUALIZAR" color={colors.GRAY} fontSize={18} buttonWidth={200} buttonStyle={{padding: 0, cursor: 'pointer', border: 'none', backgroundColor: 'grey', color: 'white'}} />
              </div>
            </div>

            <div style={{marginTop: '20px'}}>
              <div style={{color: '#fff', display: 'grid', alignItems: 'center', backgroundColor: title === 'ELIMINA UsersMoves' ? colors.LIGHT_GREEN : colors.MARRON_OSCURO, padding: 20, borderRadius: 20, maxHeight: '70vh', overflow: 'auto'}}>
                <table {...getTableProps()} style={{maxWidth: '100%', marginTop: '2%'}}>
                  <thead>
                    {headerGroups.map(headerGroup => {
                      const {key, ...restHeaderGroupProps} = headerGroup.getHeaderGroupProps();
                      return (
                        <tr key={key} {...restHeaderGroupProps}>
                          {headerGroup.headers.map(column => {
                            const {key, ...restColumnProps} = column.getHeaderProps();
                            return (
                              <th key={key} {...restColumnProps}>
                                {column.render('Header')}
                                {column.canFilter ? column.render('Filter') : null}
                              </th>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </thead>
                  <tbody>
                    {page.map(row => {
                      prepareRow(row);
                      const {key, ...restRowProps} = row.getRowProps();
                      return (
                        <tr key={key} {...restRowProps}>
                          {row.cells.map(cell => {
                            const {key, ...restCellProps} = cell.getCellProps();
                            return (
                              <td key={key} {...restCellProps}>
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="pagination">
                  <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                  </button>
                  <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                  </button>
                  <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                  </button>
                  <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>
                    {'>>'}
                  </button>
                  <span>
                    Página{' '}
                    <strong>
                      {pageIndex + 1} de {pageOptions.length}
                    </strong>
                  </span>
                  <select
                    value={pageSize}
                    onChange={e => {
                      setPageSize(Number(e.target.value));
                    }}>
                    {[10, 20, 30, 40, 50].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Mostrar {pageSize}
                      </option>
                    ))}
                  </select>
                </div>

                <div style={{marginTop: '35px', alignItems: 'center', display: 'flex', justifyContent: 'flex-start', marginBottom: '10px'}}></div>
              </div>
            </div>
          </div>
          <div style={{textAlign: 'center'}}>
            <CustomButton onPress={title === 'CONSULTA UsersMoves' ? handleExport : handleDelete} title={title === 'CONSULTA UsersMoves' ? 'EXPORTAR A EXCEL' : 'ELIMINAR SELECCIONADOS'} color={colors.MARRON_OSCURO} fontSize={18} buttonWidth={280} buttonStyle={{padding: 0, cursor: 'pointer', border: 'none'}} />
          </div>
        </div>
        {popupOpenError && (
          <div style={{zIndex: 999, borderRadius: 30, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{width: '80%', backgroundColor: colors.RED, padding: '20px', borderRadius: 40, maxWidth: '300px', height: 250}}>
              <p style={{fontFamily: 'Como-Bold', marginTop: '20%', color: 'white', fontSize: '1.5em', textAlign: 'center'}}>{mensaje}</p>
              <div style={{display: 'flex', justifyContent: 'center', marginTop: '20%'}}>
                <button className="button-mensaje" style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em'}} onClick={handlePopupCloseError}>
                  OK
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportUsersMoves;
