import React, {useState, useEffect, useMemo, useCallback} from 'react';
import {useTable, useFilters, useGlobalFilter, usePagination} from 'react-table';
// import * as XLSX from 'xlsx';
import Loader from 'react-js-loader';
import Header from './Header';
import {colors} from '../utils/index';
import './Main.css';
// eslint-disable-next-line
import trashcan from '../../assets/imgs/TRASH-01.png';
import CustomButton from '../utils/CustomButton';

const ReportReferidos = () => {
  const [isLoading, setIsLoading] = useState(false);
  const mincel = window.innerWidth < 900;
  const [popupOpenError, setPopupOpenError] = useState(false);
  const [popupOpenYesorNo, setPopupOpenYesorNo] = useState(false);
  const [mensaje, setMensaje] = useState('');
  const [selectAllHeader, setSelectAllHeader] = useState(false);
  const [buttonColor, setButtonColor] = useState(colors.RED);
  const [title, setTitle] = useState('REFERIDOS a MEMBER');
  const [responseData, setResponseData] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [dnisToDelete, setDnisToDelete] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [formBackgroundColor, setFormBackgroundColor] = useState(colors.MARRON_OSCURO);

  const defaultColumn = useMemo(
    () => ({
      Filter: ({column}) => {
        const {filterValue, setFilter} = column;
        return (
          <input
            value={filterValue || ''}
            onChange={e => setFilter(e.target.value)}
            placeholder={`Filtrar por ${column.Header}`}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                const filterText = e.target.value;
                setFilter(filterText);
              }
            }}
          />
        );
      },
      filter: (rows, dni, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[dni];
          return rowValue.toLowerCase().startsWith(filterValue.toLowerCase());
        });
      },
    }),
    [],
  );

  useEffect(() => {
    loadAllUsers();
  }, []);

  const loadAllUsers = async () => {
    try {
      setIsLoading(true);
      const body = {};
      const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/consultReferidos', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      setIsLoading(false);
      if (data.error) {
        if (data.error === 'No se encontraron Referidos') {
          setMensaje('No hay Referidos');
          setTitle('REFERIDOS a MEMBER');
          setPopupOpenError(true);
        } else if (data.error !== 'No se encontraron Referidos') {
          setMensaje('Error en carga de Referidos');
          setTitle('REFERIDOS a MEMBER');
          setPopupOpenError(true);
        }
      } else {
        setResponseData(data.data);
      }
    } catch (error) {
      console.error('Error al cargar los dnis:', error);
    }
  };

  const handleSelectReferido = useCallback(
    dniId => {
      if (dnisToDelete.includes(dniId)) {
        setDnisToDelete(dnisToDelete.filter(dni => dni !== dniId));
      } else {
        setDnisToDelete([...dnisToDelete, dniId]);
      }
    },
    [dnisToDelete],
  );

  const handleSelectAll = useCallback(() => {
    const newValue = !selectAllHeader;
    setSelectAllHeader(newValue);
    setSelectAll(newValue);
    const allReferidoIds = responseData.map(referido => referido.dni);
    setDnisToDelete(newValue ? allReferidoIds : []);
  }, [selectAllHeader, responseData]);

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div style={{marginTop: '-20px'}}>
            <input type="checkbox" checked={selectAll} onChange={() => handleSelectAll()} /> TODOS
          </div>
        ),
        disableFilters: true,
        Cell: ({row}) => <input type="checkbox" checked={selectAllHeader || selectAll || dnisToDelete.includes(row.original.dni)} onChange={() => handleSelectReferido(row.original.dni)} />,
        accessor: 'checkbox',
      },
      {Header: 'DNI', accessor: 'dni', style: {textAlign: 'left'}},
      {Header: 'NOMBRE', accessor: 'first_name', style: {textAlign: 'left'}},
      {Header: 'APELLIDO', accessor: 'last_name', style: {textAlign: 'left'}},
      {Header: 'REFERIDO', accessor: 'user', style: {textAlign: 'left'}},
      {
        Header: 'FECHA NACIMIENTO',
        accessor: 'birthDate',
        style: {textAlign: 'center'},
      },
      {
        Header: 'SEXO',
        accessor: 'sexo',
        style: {textAlign: 'center'},
      },
      {
        Header: 'INSTAGRAM',
        accessor: 'ig',
        style: {textAlign: 'center'},
      },
    ],
    [dnisToDelete, handleSelectReferido, handleSelectAll, selectAll, selectAllHeader],
  );

  const tableData = useMemo(() => {
    return {
      columns,
      data: Array.isArray(responseData) ? responseData : [],
      defaultColumn,
    };
  }, [responseData, columns, defaultColumn]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: {pageIndex, pageSize},
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(tableData, useFilters, useGlobalFilter, usePagination);

  useEffect(() => {
    setFilteredRecords(page.map(row => row.original).filter(record => dnisToDelete.includes(record.dni)));
  }, [page, dnisToDelete]);

 const handleDeleteReferido = async () => {
   try {
     setIsLoading(true);
     const body = dnisToDelete.map(dni => ({dni}));

     const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/deleteReferidos', {
       method: 'DELETE',
       headers: {
         'Content-Type': 'application/json',
       },
       body: JSON.stringify(body),
     });
     const data = await response.json();

     if (data.error) {
       setButtonColor(colors.RED);
       setMensaje('Error en la eliminación, inténtelo de nuevo.');
       setPopupOpenError(true);
     } else {
       setResponseData(prevData => {
         const newData = prevData.filter(referido => !dnisToDelete.includes(referido.dni));
         // If the current page has no more records, go to the previous page
         if (newData.length === 0 && canPreviousPage) {
           previousPage();
         }
         return newData;
       });
       setMensaje('Eliminación exitosa');
       setButtonColor(colors.GREEN);
       setPopupOpenError(true);
     }
     setIsLoading(false);
     setDnisToDelete([]);
   } catch (error) {
     console.error('Error al eliminar los referidos:', error);
     setIsLoading(false);
   }
 };

  const handleActualizaReferido = async () => {
    try {
      setIsLoading(true);
      const body = dnisToDelete.map(dni => ({dni}));

      const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/updateReferidos', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();

      if (data.error) {
        setButtonColor(colors.RED);
        setMensaje('Error en la modificación, inténtelo de nuevo.');
        setPopupOpenError(true);
      } else {
        setResponseData(prevData => prevData.filter(referido => !dnisToDelete.includes(referido.dni)));
        setMensaje('Modificación Exitosa');
        setButtonColor(colors.GREEN);
        setPopupOpenError(true);
      }
      setIsLoading(false);
      setDnisToDelete([]);
    } catch (error) {
      console.error('Error al actualizar los referidos:', error);
    }
  };

  const handlePopupCloseError = () => {
    setPopupOpenError(false);
    setPopupOpenYesorNo(false);
  };

  const handlePopupYesorNo = async () => {
    if (title === 'ELIMINA REFERIDO') {
      handleDeleteReferido();
    } else {
      handleActualizaReferido();
    }
  };

  const handleSubmit = () => {
    if (filteredRecords.length === 0) {
      setMensaje('No se han seleccionado registros.');
      setButtonColor(colors.RED);
      setPopupOpenError(true);
      return;
    }

    if (title === 'REFERIDOS a MEMBER') {
      setMensaje(`MODIFICA ${filteredRecords.length} REFERIDOS?`);
    } else {
      setMensaje(`ELIMINA ${filteredRecords.length} REFERIDOS?`);
    }

    setButtonColor(colors.RED);
    setPopupOpenYesorNo(true);
  };

  const handleAtualizapup = async () => {
    setFormBackgroundColor(colors.MARRON_OSCURO);
    setTitle('REFERIDOS a MEMBER');
  };

  const handleElimina = async () => {
    setFormBackgroundColor(colors.LIGHT_GREEN);
    setTitle('ELIMINA REFERIDO');
  };
// const handleExport = () => {
//   if (filteredRecords.length > 0) {
//     exportToExcel(filteredRecords);
//   } else {
//     console.log('No hay datos para exportar');
//   }
// };

// const exportToExcel = filteredRows => {
//   const exportData = filteredRows.map(row => ({
//     DNI: row.dni.toUpperCase(),
//     REFERIDOS: row.user.toUpperCase(),
//     NOMBRE: row.first_name.toUpperCase(),
//     APELLIDO: row.last_name.toUpperCase(),
//     IG: row.ig.toUpperCase(),
//   }));

//   const worksheet = XLSX.utils.json_to_sheet(exportData);
//   const workbook = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');

//   XLSX.writeFile(workbook, 'report.xlsx');
// };

  const back = colors.backGroundImage;

  return (
    <div style={{backgroundImage: `url(${back})`, backgroundSize: '100vw 100vh', height: mincel ? '100%' : '100vh', width: '100%'}}>
      <Header pageTitle={title} showBackButton={true} />
      {isLoading && (
        <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000}}>
          <Loader type="spinner-circle" bgColor={colors.WHITE} color={colors.BACKG_Color} size={100} />
        </div>
      )}
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', marginTop: '30px'}}>
        <div style={{zIndex: 100, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{bottom: 0, padding: 10, backgroundSize: 'cover', width: mincel ? '100vw' : '95vw', flex: 1, marginTop: mincel ? 0 : '10px', flexDirection: 'row', zIndex: 990, maxWidth: '95vw', backgroundColor: formBackgroundColor, borderRadius: '50px'}}>
            <div style={{position: 'absolute', marginTop: '-50px', marginLeft: '50px', display: 'flex'}}>
              <button onClick={handleAtualizapup} className="button-pest" style={{backgroundColor: colors.MARRON_OSCURO}}>
                MODIFICAR
              </button>
              <button onClick={handleElimina} className="button-pest" style={{backgroundColor: colors.LIGHT_GREEN}}>
                ELIMINAR
              </button>
            </div>

            <div style={{color: '#fff', marginTop: '20px', display: 'grid', alignItems: 'center', backgroundColor: formBackgroundColor, padding: 20, borderRadius: 20}}>
              <table {...getTableProps()} style={{maxWidth: '100%', marginTop: '2%'}}>
                <thead>
                  {headerGroups.map(headerGroup => {
                    const {key, ...restHeaderGroupProps} = headerGroup.getHeaderGroupProps();
                    return (
                      <tr key={key} {...restHeaderGroupProps}>
                        {headerGroup.headers.map(column => {
                          const {key, ...restColumn} = column.getHeaderProps();
                          return (
                            <th key={key} {...restColumn}>
                              {column.render('Header')}
                              <div style={{width: '100%'}}>{column.canFilter ? column.render('Filter') : null}</div>
                            </th>
                          );
                        })}
                      </tr>
                    );
                  })}
                </thead>
                <tbody {...getTableBodyProps}>
                  {page.map(row => {
                    prepareRow(row);
                    const {key, ...restRowProps} = row.getRowProps();
                    return (
                      <tr key={key} {...restRowProps}>
                        {row.cells.map(cell => {
                          const {key, ...restCellProps} = cell.getCellProps();
                          return (
                            <td key={key} {...restCellProps} style={cell.column.style}>
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div style={{marginTop: '35px', alignItems: 'center', display: 'flex', justifyContent: 'flex-start', marginBottom: '10px'}}>
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {'<<'}
                </button>
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                  {'<'}
                </button>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                  {'>'}
                </button>
                <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>
                  {'>>'}
                </button>
                <span>
                  Page{' '}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{' '}
                </span>
                <span>
                  | Go to page:{' '}
                  <input
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={e => {
                      const page = e.target.value ? Number(e.target.value) - 1 : 0;
                      gotoPage(page);
                    }}
                    style={{width: '100px'}}
                  />
                </span>
                <select
                  value={pageSize}
                  onChange={e => {
                    setPageSize(Number(e.target.value));
                  }}>
                  {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div style={{textAlign: 'center'}}>
            <CustomButton onPress={handleSubmit} title={title === 'REFERIDOS a MEMBER' ? 'REFERIDOS a MEMBER' : 'ELIMINAR SELECCIONADOS'} color={colors.MARRON_OSCURO} fontSize={18} buttonWidth={250} buttonStyle={{padding: 0, cursor: 'pointer', border: 'none'}} />
          </div>
        </div>
        {popupOpenError && (
          <div style={{zIndex: 999, borderRadius: 30, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{width: '80%', backgroundColor: buttonColor, padding: '20px', borderRadius: 40, maxWidth: '300px', height: 250}}>
              <p style={{fontFamily: 'Como-Bold', marginTop: '20%', color: 'white', fontSize: '1.5em', textAlign: 'center'}}>{mensaje}</p>
              <div style={{display: 'flex', justifyContent: 'center', marginTop: '20%'}}>
                <button className="button-mensaje" style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em'}} onClick={handlePopupCloseError}>
                  OK
                </button>
              </div>
            </div>
          </div>
        )}

        {popupOpenYesorNo && (
          <div style={{zIndex: 999, borderRadius: 30, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{backgroundColor: buttonColor, padding: '20px', borderRadius: 40, width: '300px', height: '250px'}}>
              <p style={{fontFamily: 'Como-Bold', marginTop: '20%', color: 'white', fontSize: '1.5em', textAlign: 'center'}}>{mensaje}</p>
              <div style={{display: 'flex', justifyContent: 'center', marginTop: '20%'}}>
                <button
                  className="button-mensaje"
                  style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em'}}
                  onClick={() => {
                    handlePopupYesorNo();
                    handlePopupCloseError();
                  }}>
                  SI
                </button>
                <button className="button-mensaje" style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em', marginLeft: '10px'}} onClick={handlePopupCloseError}>
                  NO
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportReferidos;
