import React, {useState} from 'react';
import {colors} from './index.js';

const CustomButton = ({onPress, title, color, fontSize, buttonWidth}) => {
  const [isPressed, setIsPressed] = useState(false);
  const height = window.innerHeight;
  const heightiPhone6s = height <= 667;
  const defaultFontSize = heightiPhone6s ? (fontSize ? fontSize - 3 : 14) : fontSize || 18;
  const buttonColor = color || colors.LGREEN; // Utiliza un color predeterminado si no se proporciona uno
  const shadowColor = 'rgba(0, 0, 0, 0.5)';

  const handleMouseDown = () => setIsPressed(true);
  const handleMouseUp = () => setIsPressed(false);

  return (
    <button
      onClick={onPress}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      style={{
        margin: '10px',
        width: buttonWidth || '250px',
        height: heightiPhone6s ? '45px' : '45px',
        borderRadius: '50px',
        borderWidth: '0',
        alignSelf: 'center',
        justifyContent: 'center',
        backgroundColor: buttonColor,
        boxShadow: `0 4px 8px ${shadowColor}`,
        padding: '10px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        opacity: isPressed ? 0.5 : 1, // Cambia la opacidad cuando se presiona
      }}>
      <span
        style={{
          color: colors.WHITE,
          fontFamily: 'Roboto-Regular',
          fontSize: `${defaultFontSize}px`,
          flexWrap: 'wrap',
          textAlign: 'center',
        }}>
        {title}
      </span>
    </button>
  );
};

export default CustomButton;
