import React, {useState, useEffect, useMemo, useCallback} from 'react';
import {useTable, useFilters, useGlobalFilter, usePagination} from 'react-table';
import * as XLSX from 'xlsx';
import Loader from 'react-js-loader';
import Header from './Header';
import {colors} from '../utils/index';
import './Main.css';
import moment from 'moment';
import CustomButton from '../utils/CustomButton';
const CrudUsers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const mincel = window.innerWidth < 900;
  const [popupOpenError, setPopupOpenError] = useState(false);
  const [popupOpenYesorNo, setPopupOpenYesorNo] = useState(false);
  const [mensaje, setMensaje] = useState('');
  const [selectAllHeader, setSelectAllHeader] = useState(false);
  const [buttonColor, setButtonColor] = useState(colors.RED);
  const [title, setTitle] = useState('CONSULTA USUARIOS');
  const [titleExcel, setTitleExcel] = useState('');
  const [responseData, setResponseData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [dnisToDelete, setDnisToDelete] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [formBackgroundColor, setFormBackgroundColor] = useState(colors.MARRON_OSCURO);

  const handleResetPasswordChange = event => {
      setResetPassword(event.target.checked);
    };

    const handleResetFotoChange = event => {
      setResetPhoto(event.target.checked);
    };

  const [selectedRow, setSelectedRow] = useState({
    firstName: '',
    lastName: '',
    dni: '',
    address: '',
    phone: '',
    loginType: '12',
    email: '',
    email2: '',
    zona: '1',
    sexo: '1',
    birthDate: '',
    pr: '',
    avatar: '',
    password: '',
    salt: '',
    ig: '',
  });
  const [resetPassword, setResetPassword] = useState(false);
  const [resetPhoto, setResetPhoto] = useState(false);

 const defaultColumn = useMemo(
   () => ({
     Filter: ({column}) => {
       const {filterValue, setFilter} = column;
       return <input value={filterValue || ''} onChange={e => setFilter(e.target.value || undefined)} placeholder={`Filtrar por ${column.Header}`} />;
     },
     filter: (rows, columnIds, filterValue) => {
       return rows.filter(row => {
         const rowValue = row.values[columnIds[0]];
    	 const normalizedFilterValue = filterValue.toLowerCase();
         // Traduce las representaciones a los valores internos para 'sexo'
         if (columnIds[0] === 'sexo') {
           if (filterValue.toLowerCase() === 'f') return rowValue === '1';
           if (filterValue.toLowerCase() === 'm') return rowValue === '2';
           if (filterValue.toLowerCase() === 'o') return rowValue === '3';
           return true; // No aplica filtro si no coincide
         }
		if (columnIds[0] === 'loginType') {
          if ('member'.startsWith(normalizedFilterValue) && rowValue === '10') return true;
          if ('evolution'.startsWith(normalizedFilterValue) && rowValue === '12') return true;
          if ('boleteria'.startsWith(normalizedFilterValue) && rowValue === '102') return true;
          if ('guardia'.startsWith(normalizedFilterValue) && rowValue === '103') return true;
          if ('barman'.startsWith(normalizedFilterValue) && rowValue === '104') return true;
          if ('marca'.startsWith(normalizedFilterValue) && rowValue === '105') return true;
          return false; // Si no coincide, debe excluirse
        }

         // Aplicar filtrado por defecto
         return String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase());
       });
     },
   }),
   [],
 );


  useEffect(() => {
    const loadAllUsers = async () => {
      try {
        setIsLoading(true);

        const body = {};
        const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/consultUsersClub', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        });
        const data = await response.json();
        setIsLoading(false);
        if (data.error) {
          if (data.error === 'No se encontraron User') {
            setMensaje('No hay User');
            setTitle('REGISTRA REFERIDOS');
            setPopupOpenError(true);
          } else if (data.error !== 'No se encontraron User') {
            setMensaje('Error en carga de User');
            setTitle('REGISTRA REFERIDOS');
            setPopupOpenError(true);
          }
		} else {

          setResponseData(data.data || []); // Ensure responseData is an array
          setFilteredRecords(data.data || []);
        }
      } catch (error) {
        console.error('Error al cargar los dnis:', error);
      }
    };

    loadAllUsers();
  }, []);

  const handleSelectReferido = useCallback(
    dniId => {
      if (dnisToDelete.includes(dniId)) {
        setDnisToDelete(dnisToDelete.filter(dni => dni !== dniId));
      } else {
        setDnisToDelete([...dnisToDelete, dniId]);
      }
    },
    [dnisToDelete],
  );

  const handleSelectAll = useCallback(() => {
    const newValue = !selectAllHeader;
    setSelectAllHeader(newValue);
    setSelectAll(newValue);
    const allReferidoIds = responseData.map(referido => referido.dni);
    setDnisToDelete(newValue ? allReferidoIds : []);
  }, [selectAllHeader, responseData]);

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div style={{marginTop: '-20px'}}>
            <input type="checkbox" checked={selectAll} onChange={() => handleSelectAll()} />
            {titleExcel}
          </div>
        ),
        disableFilters: true,
        Cell: ({row}) => <input type="checkbox" checked={selectAllHeader || selectAll || dnisToDelete.includes(row.original.dni)} onChange={() => handleSelectReferido(row.original.dni)} />,
        accessor: 'checkbox',
      },
      {Header: 'APELLIDO', accessor: 'lastName', style: {textAlign: 'left'}},
      {Header: 'NOMBRE', accessor: 'firstName', style: {textAlign: 'left'}},
      {
        Header: 'TIPO',
        accessor: 'loginType',
        style: {textAlign: 'left'},
        Cell: ({value}) => (value === '10' ? 'MEMEBER' : value === '12' ? 'EVOLUTION' : value === '102' ? 'BOLETERIA' : value === '103' ? 'GUARDIA' : value === '104' ? 'BARMAN' : 'MARCA'),

      },
      {Header: 'DNI', accessor: 'dni', style: {textAlign: 'left'}},

      {Header: 'REFERIDO', accessor: 'pr', style: {textAlign: 'left'}},
      {
        Header: 'FECHA NAC.',
        accessor: 'birthDate',
        style: {textAlign: 'center'},
      },
      {
        Header: 'SEXO',
        accessor: 'sexo',
        style: {textAlign: 'center'},
        Cell: ({value}) => (value === '1' ? 'F' : value === '2' ? 'M' : 'O'), // Convert 1, 2, 3 to F, M, O
      },
      {
        Header: 'INSTAGRAM',
        accessor: 'ig',
        style: {textAlign: 'center'},
      },
    ],
    [dnisToDelete, handleSelectReferido, handleSelectAll, selectAll, selectAllHeader, titleExcel],
  );

  const tableData = useMemo(() => {
    return {
      columns,
      data: Array.isArray(responseData) ? responseData : [],
      defaultColumn,
    };
  }, [responseData, columns, defaultColumn]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page, // Use the current page data
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {pageIndex, pageSize},
  } = useTable(tableData, useFilters, useGlobalFilter, usePagination);

  useEffect(() => {
    if (title === 'ELIMINA USUARIOS') {
      setFilteredRecords(rows.map(row => row.original).filter(record => dnisToDelete.includes(record.dni)));
    } else {
      setFilteredRecords(rows.map(row => row.original));
    }
  }, [rows, dnisToDelete, title, setPageSize]);

  const handleExport = () => {
    if (filteredRecords !== null && filteredRecords !== 'SIN MOVIMIENTOS') {
      const filteredData = filteredRecords.length > 0 ? filteredRecords : rows.map(row => row.original);
      if (filteredData.length > 0) {
        exportToExcel(filteredData);
      } else {
        console.log('No hay datos para exportar');
      }
    }
  };

  const exportToExcel = filteredRows => {
    const exportData = filteredRows.map(row => ({
      TIPO: row.loginType,
      DNI: row.dni,
      NOMBRE: row.firstName.toUpperCase(),
      APELLIDO: row.lastName.toUpperCase(),
      REFERIDOS: row.pr.toUpperCase(),
      BIRTHDATE: row.birthDate.toUpperCase(),
      SEXO: row.sexo === '1' ? 'F' : row.sexo === '2' ? 'M' : 'O', // Convert 1, 2, 3 to F, M, O for export
      IG: row.ig,
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
    XLSX.writeFile(workbook, 'report.xlsx');
  };

  if (localStorage.dni === '') {
    localStorage.clear();
    document.location.href = '/Login';
  }

  const handleDeleteReferido = async () => {
    try {
      setIsLoading(true);
      var body = [];
      for (const record of filteredRecords) {
        var bodyItems = {};
        bodyItems['dni'] = record.dni;
        body.push(bodyItems);
      }

      const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/deleteUsersClub', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (Array.isArray(data) && data.length > 0) {
        const messageObject = data[0];

        if (messageObject.status === 'error') {
          setButtonColor(colors.RED);
          setMensaje('Error en la eliminación, inténtelo de nuevo.');
          setPopupOpenError(true);
        } else {
          setResponseData(prevData => {
            const newData = prevData.filter(referido => !dnisToDelete.includes(referido.dni));
            return newData;
          });
          setFilteredRecords(prevData => prevData.filter(referido => !dnisToDelete.includes(referido.dni)));
			setMensaje('Eliminación exitosa');
			handleClear()
          setButtonColor(colors.GREEN);
          setPopupOpenError(true);
        }
      }
      setIsLoading(false);
      setDnisToDelete([]);
    } catch (error) {
      console.error('Error al cargar los dni:', error);
      setIsLoading(false);
    }
  };

  const handlePopupCloseError = () => {
    setPopupOpenError(false);
    setPopupOpenYesorNo(false);
  };

  const handlePopupYesorNo = async () => {
    if (title === 'ELIMINA USUARIOS') {
      handleDeleteReferido();
    } else {
      handleExport();
    }
  };

  const handleSubmit = async () => {
	  try {

      const isValid = await validateData();
      if (!isValid) {
        return; // No ejecutar el resto de la lógica si la validación falla
      }

      const existingUser = responseData.find(user => user.dni === selectedRow.dni);

      if (existingUser) {
        // Update existing user
        await handleUpdateUser();
      } else {
        // Register new user
        await handleRegister();
      }
    } catch (error) {
      console.error('Error al procesar el formulario:', error.message);
    }
  };

  const handleRegister = async () => {
    try {
      const isValid = await validateData();
      if (!isValid) {
        return; // No ejecutar el resto de la lógica si la validación falla
      }

      setIsLoading(true);
      const body = {
        first_name: selectedRow.firstName,
        last_name: selectedRow.lastName,
        dni: selectedRow.dni,
        address: selectedRow.address,
        phone: selectedRow.phone,
        loginType: selectedRow.loginType,
        email: selectedRow.email,
        email2: selectedRow.email2,
        zona: selectedRow.zona,
        sexo: selectedRow.sexo,
        birthDate: moment(selectedRow.birthDate).format('YYYY/MM/DD'),
        pr: 'Catch',
        avatar: '1',
		active: '1',
		tokentext:'1',
        password: selectedRow.password,
        salt: selectedRow.salt,
        ig: selectedRow.ig,
        dataTableName: 'USERSCLUB',
      };
      const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/registerUsersClub', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();

      setIsLoading(false);
      if (data.error) {
        setMensaje(data.error);
        setButtonColor(colors.RED);
        setPopupOpenError(true);
      } else {
        setResponseData(prevData => [...prevData, selectedRow]);
        setFilteredRecords(prevData => [...prevData, selectedRow]);
        setMensaje('Usuario Registrado Exitosamente');
        setButtonColor(colors.GREEN);
        setPopupOpenError(true);
        handleClear();
      }
    } catch (error) {
      console.error('Error al registrar usuario:', error.message);
      setIsLoading(false);
    }
  };

  const handleAtualizapup = async () => {
    setFormBackgroundColor(colors.MARRON_OSCURO);
    setTitle('CONSULTA USUARIOS');
    setTitleExcel('');
    setSelectedRow({
      firstName: '',
      lastName: '',
      dni: '',
      address: '',
      phone: '',
      loginType: '12',
      email: '',
      email2: '',
      zona: '1',
      sexo: '1',
      birthDate: '',
      pr: '',
      avatar: '',
      password: '',
      salt: '',
      ig: '',
    });
  };

  const handleElimina = async () => {
    setFormBackgroundColor(colors.LIGHT_GREEN);
    setTitle('ELIMINA USUARIOS');
    setTitleExcel('TODOS');
    setSelectedRow({
      firstName: '',
      lastName: '',
      dni: '',
      address: '',
      phone: '',
      loginType: '12',
      email: '',
      email2: '',
      zona: '1',
      sexo: '1',
      birthDate: '',
      pr: '',
      avatar: '',
      password: '',
      salt: '',
      ig: '',
    });
  };

  const handleClear = () => {
    setSelectedRow({
      firstName: '',
      lastName: '',
      dni: '',
      address: '',
      phone: '',
      loginType: '12',
      email: '',
      email2: '',
      zona: '1',
      sexo: '1',
      birthDate: '',
      pr: '',
      avatar: '',
      password: '',
      salt: '',
      ig: '',
    });
  };

  const handleRowClick = row => {
    setSelectedRow({
      firstName: row.firstName || '',
      lastName: row.lastName || '',
      dni: row.dni || '',
      address: row.address || '',
      phone: row.phone || '',
      loginType: row.loginType || '12',
      email: row.email || '',
      email2: row.email || '',
      zona: row.zona || '1',
      sexo: row.sexo || '1',
      birthDate: row.birthDate || '',
      pr: row.pr || '',
      avatar: row.avatar || '',
      password: row.password || '',
      salt: row.salt || '',
      ig: row.ig || '',
    });
  };

  const handleUpdateUser = async () => {
    try {
      const isValid = await validateData();
      if (!isValid) {
        return; // No ejecutar el resto de la lógica si la validación falla
      }
      setIsLoading(true);

      const body = {
        first_name: selectedRow.firstName,
        last_name: selectedRow.lastName,
        dni: selectedRow.dni,
        address: selectedRow.address,
        phone: selectedRow.phone,
        loginType: selectedRow.loginType,
        email: selectedRow.email,
        zona: selectedRow.zona,
        sexo: selectedRow.sexo,
        birthDate: moment(selectedRow.birthDate).format('YYYY/MM/DD'),
        pr: selectedRow.pr,
        avatar: selectedRow.avatar,
        password: selectedRow.password,
        salt: selectedRow.salt,
        changePassword: resetPassword.toString(),
        changeAvatar: resetPhoto.toString(),
        ig: selectedRow.ig,
      };

      const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/updateUsersClub', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      setIsLoading(false);
      setResetPassword(false);
      setResetPhoto(false);

      if (!response.ok) {
        const errorData = await response.json();
        setMensaje(errorData.error);
        setButtonColor(colors.RED);
        setPopupOpenError(true);
      } else {
        setResponseData(prevData => prevData.map(user => (user.dni === selectedRow.dni ? selectedRow : user)));
        setFilteredRecords(prevData => prevData.map(user => (user.dni === selectedRow.dni ? selectedRow : user)));
        setMensaje('Usuario Modificado Correctamente');
        setButtonColor(colors.GREEN);
        setPopupOpenError(true);
      }
    } catch (error) {
      console.error('Error al modificar usuario:', error.message);
      setIsLoading(false);
    }
  };

  const validateData = async () => {
    if ((selectedRow.firstName === '' || selectedRow.lastName === '' || selectedRow.email === '' || selectedRow.email2 === '' || selectedRow.dni === '' || selectedRow.phone === '' || selectedRow.address === '')  ){
      setMensaje('Complete los datos');
      setPopupOpenError(true);
      return false;
    }
    const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

    if (!emailRegex.test(selectedRow.email) || !emailRegex.test(selectedRow.email2)) {
      setMensaje('Email inválido');
      setPopupOpenError(true);
      return false;
    }

    if (selectedRow.email !== selectedRow.email2) {
      setMensaje('Los correos electrónicos no coinciden');
      setPopupOpenError(true);
      return false;
    }

    return true;
  };
const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

const handleFirstNameChange = e => {
  setSelectedRow({
    ...selectedRow,
    firstName: capitalizeFirstLetter(e.target.value),
  });
};
const handleLastNameChange = e => {
  setSelectedRow({
    ...selectedRow,
    lastName: capitalizeFirstLetter(e.target.value),
  });
};
  const back = colors.backGroundImage;

  return (
    <div style={{backgroundImage: `url(${back})`, backgroundSize: 'cover', height: mincel ? '100%' : '100%', width: '100%'}}>
      <Header pageTitle={title} showBackButton={true} />
      {isLoading && (
        <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000}}>
          <Loader type="spinner-circle" bgColor={colors.WHITE} color={colors.BACKG_Color} size={100} />
        </div>
      )}
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', marginTop: '30px'}}>
        <div style={{zIndex: 100, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{bottom: 0, padding: 10, backgroundSize: 'cover', width: mincel ? '100vw' : '90vw', flex: 1, marginTop: mincel ? 0 : '10px', flexDirection: 'row', zIndex: 990, maxWidth: '90vw', backgroundColor: formBackgroundColor, borderRadius: '50px'}}>
            <div style={{position: 'absolute', marginTop: '-50px', marginLeft: '50px', display: 'flex'}}>
              <button onClick={handleAtualizapup} className="button-pest" style={{backgroundColor: colors.MARRON_OSCURO}}>
                AGREGAR
              </button>
              <button onClick={handleElimina} className="button-pest" style={{backgroundColor: colors.LIGHT_GREEN}}>
                ELIMINAR
              </button>
              <button onClick={handleClear} className="button-pest" style={{backgroundColor: colors.RED}}>
                LIMPIAR
              </button>
            </div>
            {selectedRow && title !== 'ELIMINA USUARIOS' && (
              <div style={{marginTop: '20px', padding: '20px', backgroundColor: colors.MINICARD, borderRadius: '20px', boxShadow: '0 0 10px rgba(0,0,0,0.2)', width: '90%', margin: '0 auto', bottom: 30}}>
                <div style={{marginTop: '-20px', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column', width: '50%'}}>
                  <label className="textTerm">DNI</label>
                  <p className="textFormError">{selectedRow.dni === '' ? 'Campo requerido' : ''}</p>
                  <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="number" required id="dni" placeholder="DNI" value={selectedRow.dni || ''} onChange={e => setSelectedRow({...selectedRow, dni: e.target.value})} />
                </div>
                <div style={{marginTop: '5px', display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px'}}>
                  <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                    <label className="textTerm">NOMBRE</label>
                    <p className="textFormError">{selectedRow.firstName === '' ? 'Campo requerido' : ''}</p>
                    <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="text" required id="firstName" placeholder="Nombre" value={selectedRow.firstName || ''} onChange={handleFirstNameChange} />
                  </div>
                  <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column', width: mincel ? '90vw' : '30vw'}}>
                    <label className="textTerm">APELLIDO</label>
                    <p className="textFormError">{selectedRow.lastName === '' ? 'Campo requerido' : ''}</p>
                    <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="text" required id="lastName" placeholder="Apellido" value={selectedRow.lastName || ''} onChange={handleLastNameChange} />
                  </div>
                </div>
                <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px', flexDirection: mincel ? 'column' : 'row'}}>
                  <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                    <p className="textTerm">MAIL</p>
                    <p className="textFormError">{selectedRow.email === '' ? 'Campo requerido' : ''}</p>
                    <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="email" required id="email" placeholder="E-mail" value={selectedRow.email || ''} onChange={e => setSelectedRow({...selectedRow, email: e.target.value})} />
                  </div>
                  <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                    <p className="textTerm">CONFIRMACIÓN DE MAIL</p>
                    <p className="textFormError">{selectedRow.email2 === '' ? 'Campo requerido' : ''}</p>
                    <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="email" required id="email2" placeholder="ej.: ejemplo@gmail.com" className="no-outline" value={selectedRow.email2 || ''} onChange={e => setSelectedRow({...selectedRow, email2: e.target.value})} />
                  </div>
                </div>
                <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px'}}>
                  <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                    <p className="textTerm">CELULAR CON CODIGO DE ÁREA</p>
                    <p className="textFormError">{selectedRow.phone === '' ? 'Campo requerido' : ''}</p>
                    <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="number" required id="phone" placeholder="ej.: 011xxxxxxx" className="no-outline" value={selectedRow.phone || ''} onChange={e => setSelectedRow({...selectedRow, phone: e.target.value})} />
                  </div>
                  <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                    <p className="textTerm">INSTAGRAM</p>
                    <p className="textFormError">{selectedRow.ig === '' ? 'Campo requerido' : ''}</p>
                    <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="text" required id="ig" placeholder="ej.: xxxxx" className="no-outline" value={selectedRow.ig || ''} onChange={e => setSelectedRow({...selectedRow, ig: e.target.value})} />
                  </div>
                </div>
                <div style={{marginTop: 5}}>
                  <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                    <p className="textTerm">DIRECCIÓN</p>
                    <p className="textFormError">{selectedRow.address === '' ? 'Campo requerido' : ''}</p>
                    <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="text" required id="address" placeholder="ej.: 0xxxxxxx" className="no-outline" value={selectedRow.address || ''} onChange={e => setSelectedRow({...selectedRow, address: e.target.value})} />
                  </div>
                </div>
                <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px'}}>
                  <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
                    <p className="textTerm">ZONA</p>
                    <div style={{marginLeft: '20px'}}>
                      <input className="radio_common" type="radio" id="zona" name="zona" value="1" onChange={e => setSelectedRow({...selectedRow, zona: e.target.value})} checked={selectedRow.zona === '1'} />
                      <label className="textTerm" htmlFor="norte">
                        Norte
                      </label>
                    </div>
                    <div>
                      <input className="radio_common" type="radio" id="zona" name="zona" value="2" onChange={e => setSelectedRow({...selectedRow, zona: e.target.value})} checked={selectedRow.zona === '2'} />
                      <label className="textTerm" htmlFor="sur">
                        Sur
                      </label>
                    </div>
                    <div>
                      <input className="radio_common" type="radio" id="zona" name="zona" value="3" onChange={e => setSelectedRow({...selectedRow, zona: e.target.value})} checked={selectedRow.zona === '3'} />
                      <label className="textTerm" htmlFor="centro">
                        Centro
                      </label>
                    </div>
                    <div>
                      <input className="radio_common" type="radio" id="zona" name="zona" value="4" onChange={e => setSelectedRow({...selectedRow, zona: e.target.value})} checked={selectedRow.zona === '4'} />
                      <label className="textTerm" htmlFor="otro">
                        Otro
                      </label>
                    </div>
                  </div>

                  <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
                    <p className="textTerm">SEXO</p>
                    <div style={{marginLeft: '20px'}}>
                      <input className="radio_common" type="radio" id="sexoF" name="sexo" value="1" onChange={e => setSelectedRow({...selectedRow, sexo: e.target.value})} checked={selectedRow.sexo === '1'} />
                      <label className="textTerm" htmlFor="sexoF">
                        F
                      </label>
                    </div>
                    <div>
                      <input className="radio_common" type="radio" id="sexoM" name="sexo" value="2" onChange={e => setSelectedRow({...selectedRow, sexo: e.target.value})} checked={selectedRow.sexo === '2'} />
                      <label className="textTerm" htmlFor="sexoM">
                        M
                      </label>
                    </div>
                    <div>
                      <input className="radio_common" type="radio" id="sexoO" name="sexo" value="3" onChange={e => setSelectedRow({...selectedRow, sexo: e.target.value})} checked={selectedRow.sexo === '3'} />
                      <label className="textTerm" htmlFor="sexoO">
                        Otro
                      </label>
                    </div>
                  </div>
                </div>
                <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px'}}>
                  <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
                    <p style={{marginTop: '10px'}} className="textTerm">
                      Fecha de Nacimiento
                    </p>
                    <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="date" required id="birthDate" placeholder="dd-mm-yyyy" value={selectedRow.birthDate || ''} onChange={e => setSelectedRow({...selectedRow, birthDate: e.target.value})} />
                  </div>
                </div>
                <div style={{marginTop: 5}}>
                  <div style={{padding: 5, alignItems: mincel ? 'flex-start' : 'flex-start', display: 'flex', flexDirection: mincel ? 'column' : 'row'}}>
                    <p className="textTerm">ROLL</p>
                    <div style={{marginLeft: mincel ? '' : '20px'}}>
                      <input className="radio_common" type="radio" id="loginType" name="loginType" value="10" onChange={e => setSelectedRow({...selectedRow, loginType: e.target.value})} checked={selectedRow.loginType === '10'} />
                      <label className="textTerm" htmlFor="norte">
                        Socio Member
                      </label>
                    </div>
                    <div style={{marginLeft: mincel ? '' : '20px'}}>
                      <input className="radio_common" type="radio" id="loginType" name="loginType" value="12" onChange={e => setSelectedRow({...selectedRow, loginType: e.target.value})} checked={selectedRow.loginType === '12'} />
                      <label className="textTerm" htmlFor="sur">
                        Socio Evolution
                      </label>
                    </div>
                    <div style={{marginLeft: mincel ? '' : '20px'}}>
                      <input className="radio_common" type="radio" id="loginType" name="loginType" value="102" onChange={e => setSelectedRow({...selectedRow, loginType: e.target.value})} checked={selectedRow.loginType === '102'} />
                      <label className="textTerm" htmlFor="centro">
                        Boleteria
                      </label>
                    </div>
                    <div style={{marginLeft: mincel ? '' : '20px'}}>
                      <input className="radio_common" type="radio" id="loginType" name="loginType" value="103" onChange={e => setSelectedRow({...selectedRow, loginType: e.target.value})} checked={selectedRow.loginType === '103'} />
                      <label className="textTerm" htmlFor="otro">
                        Guardia
                      </label>
                    </div>
                    <div style={{marginLeft: mincel ? '' : '20px'}}>
                      <input className="radio_common" type="radio" id="loginType" name="loginType" value="104" onChange={e => setSelectedRow({...selectedRow, loginType: e.target.value})} checked={selectedRow.loginType === '104'} />
                      <label className="textTerm" htmlFor="otro">
                        Barman
                      </label>
                    </div>
                    <div style={{marginLeft: mincel ? '' : '20px'}}>
                      <input className="radio_common" type="radio" id="loginType" name="loginType" value="105" onChange={e => setSelectedRow({...selectedRow, loginType: e.target.value})} checked={selectedRow.loginType === '105'} />
                      <label className="textTerm" htmlFor="otro">
                        Marca
                      </label>
                    </div>
                  </div>
                  {title === 'CONSULTA USUARIOS' && (
                    <div style={{marginTop: '10px', padding: 1, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
                      <label>
                        <input id="password" type="checkbox" checked={resetPassword} onChange={handleResetPasswordChange} /> Reset Password
                      </label>
                      <br />
                      <label>
                        <input id="photo" type="checkbox" checked={resetPhoto} onChange={handleResetFotoChange} /> Reset Foto
                      </label>
                    </div>
                  )}
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '10px'}}>{title === 'ELIMINA USUARIOS' ? <></> : <CustomButton onPress={handleSubmit} title={responseData.some(user => user.dni === selectedRow.dni) ? 'Actualizar Usuario' : 'Registrar Usuario'} color={colors.MARRON_OSCURO} fontSize={18} buttonWidth={200} />}</div>
              </div>
            )}
            <div style={{marginTop: '20px'}}>
              <div style={{color: '#fff', display: 'grid', alignItems: 'center', backgroundColor: formBackgroundColor, padding: 20, borderRadius: 20, maxHeight: '50vh', overflow: 'auto'}}>
                <table {...getTableProps()} style={{maxWidth: '100%', marginTop: -20}}>
                  <thead>
                    {headerGroups.map(headerGroup => {
                      const {key, ...restHeaderGroupProps} = headerGroup.getHeaderGroupProps();
                      return (
                        <tr key={key} {...restHeaderGroupProps}>
                          {headerGroup.headers.map(column => {
                            const {key, ...restColumn} = column.getHeaderProps();
                            return (
                              <th key={key} {...restColumn}>
                                {column.render('Header')}
                                <div style={{width: '100%'}}>{column.canFilter ? column.render('Filter') : null}</div>
                              </th>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </thead>
                  <tbody {...getTableBodyProps}>
                    {page.map(row => {
                      prepareRow(row);
                      const {key, ...restRowProps} = row.getRowProps();
                      return (
                        <tr key={key} {...restRowProps} onClick={() => handleRowClick(row.original)}>
                          {row.cells.map(cell => {
                            const {key, ...restCellProps} = cell.getCellProps();
                            return (
                              <td key={key} {...restCellProps} style={cell.column.style}>
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="pagination">
                  <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                  </button>
                  <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                  </button>
                  <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                  </button>
                  <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>
                    {'>>'}
                  </button>
                  <span>
                    Página{' '}
                    <strong>
                      {pageIndex + 1} de {pageOptions.length}
                    </strong>
                  </span>
                  <select
                    style={{marginLeft: 20}}
                    value={pageSize}
                    onChange={e => {
                      setPageSize(Number(e.target.value));
                    }}>
                    {[10, 20, 30, 40, 50].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Mostrar {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{marginTop: '1px', alignItems: 'flex-start', display: 'flex', justifyContent: 'flex-start'}}>
                  <div style={{textAlign: 'center'}}>
                    <CustomButton
                      onPress={() => {
                        if (title === 'CONSULTA USUARIOS') {
                          handleExport();
                        } else if (title === 'ELIMINA USUARIOS') {
                          setPopupOpenYesorNo(true);
                          setMensaje('¿ELIMINAR USUARIOS?');
                        }
                      }}
                      title={title === 'CONSULTA USUARIOS' || title === 'REGISTRA USUARIOS' ? 'EXPORTAR XLS' : 'ELIMINAR'}
                      color={colors.MARRON_OSCURO} // Ajusta el color según sea necesario
                      fontSize={18} // Ajusta el tamaño de la fuente si es necesario
                      buttonWidth={200} // Ajusta el ancho del botón si es necesario
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {popupOpenError && (
          <div style={{zIndex: 999, borderRadius: 30, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{width: '80%', backgroundColor: buttonColor, padding: '20px', borderRadius: 30, maxWidth: '300px', height: 250}}>
              <p style={{fontFamily: 'Como-Bold', marginTop: '20%', color: 'white', fontSize: '1.5em', textAlign: 'center'}}>{mensaje}</p>
              <div style={{display: 'flex', justifyContent: 'center', marginTop: '20%'}}>
                <button className="button-mensaje" style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em'}} onClick={handlePopupCloseError}>
                  OK
                </button>
              </div>
            </div>
          </div>
        )}
        {popupOpenYesorNo && (
          <div style={{zIndex: 999, borderRadius: 30, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{backgroundColor: buttonColor, padding: '20px', borderRadius: 40, width: '300px', height: '250px'}}>
              <p style={{fontFamily: 'Como-Bold', marginTop: '20%', color: 'white', fontSize: '1.5em', textAlign: 'center'}}>{mensaje}</p>
              <div style={{display: 'flex', justifyContent: 'center', marginTop: '20%'}}>
                <button
                  className="button-mensaje"
                  style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em'}}
                  onClick={() => {
                    handlePopupYesorNo();
                    handlePopupCloseError();
                  }}>
                  SI
                </button>
                <button className="button-mensaje" style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em', marginLeft: '10px'}} onClick={handlePopupCloseError}>
                  NO
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CrudUsers;
